<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h3 style="text-align: center;">编辑项目</h3>
      <form>
        <div class="form-group">
          <input type="text" id="project-name" v-model="projectName" autocomplete="off">
        </div>
        <div class="form-group">
          <label for="project-type">类型&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <select id="project-type" v-model="projectType">
            <option value="type1">类型1</option>
            <option value="type2">类型2</option>
            <option value="type3">类型3</option>
          </select>
        </div>
        <div class="form-group">
          <label for="project-folder">文件夹</label>  
          <select id="project-folder" v-model="projectFolder" style="width: 230px; margin-right: 10px">
            <option v-for="folder in folders" :value="folder" :key="folder.index"
            >
            {{ folder.name }}
          </option>
          </select>
          <!-- <select  ref="mySelect" style="width: 230px; margin-right: 10px">
            <option value="1" >1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select> -->
          <button type="button" @click="addFolder">新建</button>
        </div>
      </form>
      <div class="menu-buttons">
        <button type="button" @click="editProjectAcl" :disabled="saveOrNot">保存</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations} from 'vuex'
import axios from 'axios'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "AddProject",
    props: ['myProjects','selectedProjAcl'],
    data() {
        return {
            projectName: "",
            projectType: "type1",
            projectFolder: '',
            username:'',
            saveOrNot:true,
            //以下是编辑项目的数据
            selectProjGroup:''
        };
    },
    // props:['selectProjName'],
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        // for(var i=0; i<this.myProjects.length;i++) {
        //   if(!myfolderobj[this.myProjects[i].groupname]) {
        //     myfolderobj[this.myProjects[i].groupname]=i+1
        //     const folder={id: Id, name: this.myProjects[i].groupname}
        //     myfolder.push(folder)
        //     Id++
        //   }
        // }
        for(var i=0; i<this.myProjects.length;i++) {
          if(!myfolderobj[this.myProjects[i].prjgroup_altname]) {
            myfolderobj[this.myProjects[i].prjgroup_altname]=i+1
            const folder={id: Id, name: this.myProjects[i].prjgroup_altname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },

      // saveOrNot: function(){
      //   if(this.projectName) {
      //     return false
      //   }else
      //   return true
      // }
      saveOk: function(){
        if(this.projectName&&this.projectFolder) {
          return true
        }else
        return false
      }

      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      }
    },
    prop: ["menuVisible"],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },
      editProjectAcl() {
        const newProjectAcl=this.selectedProjAcl
        // if(this.selectedProjAcl.permit_level=='charger'){

        // }
        newProjectAcl.project_altname=this.projectName
        newProjectAcl.prjgroup_altname=this.projectFolder.name
        this.$emit("editProjectAcl",newProjectAcl);
        this.$emit("cancelEditPro");
        // this.switchMenuMask();
      },
      resetForm() {
          this.projectName = "";
          this.projectType = "type1";
          this.projectFolder = null;
          this.$emit("cancelEditPro");
          // this.switchMenuMask();
      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

    },
    mounted(){


      
      //编辑项目菜单生成后，根据选择的项目自动填写该项目的name和groupname属性

    


      this.projectName=this.selectedProjAcl.project_altname
      this.selectProjGroup=this.$store.state.selectProjGroup
      

      this.getUsername();
      const token = localStorage.getItem('token')
      // axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
      //   headers: {
      //     Authorization: token
      //   },
      // })
      //   .then((response)=>{
      //     if (Array.isArray(response.data)){
      //       // this.myProjectlist=response.data.filter(item => item.charger==this.username&&item.idfp>3)
      //       //得到数据后再获取用户名
      //       this.projectFolder = this.folders.find(folder => folder.name === this.selectProjGroup)
      //     }else
      //     alert('账号未认证，无法获取数据！')
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })

    },

    created(){
      this.projectFolder=this.folders.find(folder=>folder.name==this.$store.state.selectProjGroup)
    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 450px;
  height: 270px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 405px;
    height: 30px;
    padding: 0;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

  
</style>