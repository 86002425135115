<template>
  <TinymceVue v-if="!reloading" v-model="content" :id="id" :init="finalInit" :disabled="disabled" 
    style="height:calc(100vh -200px);"
  />
</template>

<script>
  const tinymce = import(/* webpackChunkName: "async-tinymce" */ 'tinymce/tinymce')
  const TinymceVue = () => import(/* webpackChunkName: "async-tinymce" */ '@tinymce/tinymce-vue')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/icons/default')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/themes/silver')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/emoticons/js/emojis')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/image')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/media')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/table')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/lists')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/wordcount')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/colorpicker')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/textcolor')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/preview')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/code')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/link')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/advlist')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/codesample')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/hr')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/fullscreen')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/textpattern')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/searchreplace')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/autolink')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/directionality')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/visualblocks')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/visualchars')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/template')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/charmap')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/nonbreaking')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/insertdatetime')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/imagetools')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/autoresize')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/save')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/autosave')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/paste')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/quickbars')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/pagebreak')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/anchor')
  import(/* webpackChunkName: "async-tinymce" */ 'tinymce/plugins/emoticons')

  const plugins = [
    'code',
    'preview',
    'fullscreen',
    'paste',
    'searchreplace',
    'save',
    'autosave',
    'link',
    'autolink',
    'image',
    'media',
    'table',
    'codesample',
    'lists',
    'advlist',
    'hr',
    'charmap',
    'emoticons',
    'anchor',
    'directionality',
    'pagebreak',
    'quickbars',
    'nonbreaking',
    'visualblocks',
    'visualchars',
    'wordcount',
  ].join(' ')

  const toolbar = [
    'fullscreen',
    'emoticons',
    'link',
    'undo',
    'redo',
    '|',
    'forecolor',
    'backcolor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    
    'charmap',
    '|',
    'alignleft',
    'aligncenter',
    'alignright',
    'alignjustify',
    '|',
    'outdent',
    'indent',
    '|',
    'numlist',
    'bullist',
    '|',
    'image',
    // 'media',
    // 'charmap',
    // 'anchor',
    // 'pagebreak',
    // 'codesample',
    '|',
    // 'ltr',
    // 'rtl',
    'preview',
    'code',
    '|',
    'formatselect',
    'fontselect',
    'fontsizeselect',
  
  ].join(' ')

  export default {
    name: 'TinymceEditor',
    components: { TinymceVue },
    props: {
      id: String,
      value: {
        type: String,
        default: ''
      },
      init: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      plugins: {
        type: [String, Array],
        default: () => plugins
      },
      toolbar: {
        type: [String, Array],
        default: () => toolbar
      },
      darkTheme: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const basePath = '/'
      return {
        reloading: false,
        content: this.value,
        defaultInit: {
          // height: 600,
          
          // 以下是调整p的行间距
          // content_style: "div {margin: 0px; border:0px ; padding: 0px}",
          content_style: 'p { margin: 0; padding: 0; line-height: 1.4; font-size: 14px;} ',

          plugins: this.plugins + ' link mybullist',
          toolbar: this.toolbar + ' | link mybullist',
          font_formats: '宋体=宋体;黑体=黑体;楷体=楷体;仿宋=仿宋;Arial=Arial;Tahoma=Tahoma;Comic Sans MS=Comic Sans MS',
          branding: false,
          skin_url: `${basePath}tinymce/skins/ui/oxide`,
          content_css: `${basePath}tinymce/skins/content/default/content.min.css`,
          // content_css: `${basePath}tinymce/skins/content/default/content.min.css,font-family: 宋体; font-size: 12px; line-height: 1.5;`, // 设置字体、字号和行高
          language_url: `${basePath}tinymce/langs/zh_CN.js`,
          language: 'zh_CN',
          plugins: this.plugins,
          toolbar: this.toolbar,
          draggable_modal: true,
          paste_data_images: true,
          images_file_types: 'jpeg,jpg,png,gif,bmp,webp',
          toolbar_mode: 'sliding',
          quickbars_insert_toolbar: '',
          fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 36px',
          images_upload_handler: (blobInfo, success, failure) => {
            // 方法1：base64图片， 无需后端服务
            blobInfo.blob().size / 1024 > 2048 ? failure('大小不能超过 2048KB') : success('data:image/jpeg;base64,' + blobInfo.base64())
          },



          /* images_upload_handler: (blobInfo, success, failure) => {
            // 方法2：后台接口上传， 保存图片地址到服务器
            const formData = new FormData()
            formData.set('upload_file', blobInfo.blob())
            this.$axios
              .post('/api/upload', formData)
              .then(res => {
                success(res.data.data.src)
              })
              .catch(error => {
                failure(error)
              })
          }, */
          file_picker_types: 'media',
          file_picker_callback: () => {}
        },
        darkSkin: {
          skin_url: `${basePath}tinymce/skins/ui/oxide-dark`,
          content_css: `${basePath}tinymce/skins/content/dark/content.min.css`
        }
      }
    },

    created(){

    },
    computed: {
      finalInit() {
        return {
          ...this.defaultInit,
          ...(this.darkTheme ? this.darkSkin : {}),
          ...this.init
        }
      }
    },
    watch: {
      value(newValue) {
        // this.content = newValue
        this.content = newValue || '';
      },
      content(newValue) {
        this.$emit('input', newValue)
      }
    },
    methods: {
      

      reload() {
        // FIX:修复置于弹层组件,首次无法输入的问题
        const modal = this.getVmParentByName(this, 'Modal')
        if (modal) {
          this.reloading = true
          this.$nextTick(() => (this.reloading = false))
        }
      },
      getVmParentByName(vm, name) {
        const parent = vm.$parent
        if (parent && parent.$options) {
          if (parent.$options.name === name) {
            return parent
          } else {
            const res = this.getVmParentByName(parent, name)
            if (res) {
              return res
            }
          }
        }
        return null
      }
    }
  }
</script>

<style>
::-webkit-scrollbar {  
  width: 5px; /* 设置滚动条的宽度 */  
}
</style>
