import { render, staticRenderFns } from "./EditTimeRuler.vue?vue&type=template&id=48fac6f3&scoped=true"
import script from "./EditTimeRuler.vue?vue&type=script&lang=js"
export * from "./EditTimeRuler.vue?vue&type=script&lang=js"
import style0 from "./EditTimeRuler.vue?vue&type=style&index=0&id=48fac6f3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fac6f3",
  null
  
)

export default component.exports