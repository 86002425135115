<template>
  <div class="chat-container" @contextmenu="disableContextMenu">
    <div class="chat-header" style="border-bottom: 1px gray solid;">
      <div style="text-align: left; padding-left:10px; height: 30px;line-height: 30px;font-size: 16px;font-weight:500;" v-if="optionView==1">
        <label for="">
          {{ chatto }}
        </label>
      </div>
      <div style="text-align: left; padding-left:10px; height: 30px;line-height: 30px;font-size: 16px;font-weight:500;" 
        v-if="optionView==2">
        <label for="" @click="downLoadSelectedFile()" class="selectFileName">
          {{ chatto }}
        </label>  
        <!-- <button style="width: 20px; background: none; border: none;padding: 0; margin:0 10px 0 0; line-height: 30px;"  
          @click="downLoadSelectedFile()" v-show="selectedFileMsg.fileidff">
          <i class="el-icon-bottom" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button>
        <button style="width: 20px; background: none; border: none;padding: 0; margin:0 10px 0 0; line-height: 30px;"  
          @click="downLoadSelectedFile()" v-show="selectedFileMsg.fileidfsf">
          <i class="el-icon-link" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button> -->
        <!-- <button style="width: 20px; background: none; border: none;padding: 0; float:right;margin:0 10px 0 0; line-height: 30px;">
          <i class="el-icon-view" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button>
        <button style="width: 20px; background: none; border: none;padding: 0; float:right;margin:0 10px 0 0; line-height: 30px;"  
          @click="approvelFile(file)">
          <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button> -->
      </div>
      <div style="text-align: left; padding-left:10px; height: 30px;line-height: 30px;font-size: 16px;font-weight:500;" 
        v-if="optionView==3">
        <label for="">
          {{ chatto }}
        </label>
        <!-- <button style="width: 20px; background: none; border: none;padding: 0; float:right;margin:0 10px 0 0; line-height: 30px;"  
          @click="approvelFile(file)">
          <i class="el-icon-bottom" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button>
        <button style="width: 20px; background: none; border: none;padding: 0; float:right;margin:0 10px 0 0; line-height: 30px;">
          <i class="el-icon-view" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button>
        <button style="width: 20px; background: none; border: none;padding: 0; float:right;margin:0 10px 0 0; line-height: 30px;"  
          @click="approvelFile(file)">
          <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
        </button> -->
      </div>
    </div>
    <ApprFileMenu1 v-if="ifShowApprFileMenu" :approvingFile="approvingFile" @closeFileAprovelMenu="closeFileAprovelMenu"
      :fileProcessMsgs="fileProcessMsgs" :selectedFile="selectedFile" @handleFileProcessMsg="handleFileProcessMsg"
      @aprovelFileFromMsg="aprovelFileFromMsg">
    </ApprFileMenu1>
    <PubFileToUsers v-if="ifShowPubFileMenu" :approvingFile="approvingFile" @closePubFileToUsers="closePubFileToUsers">
    </PubFileToUsers>
    <div v-if="optionView==1||optionView==2">
      <div class="chat-records" style="height: calc(100vh - 340px); border-bottom: 1px solid gray; 
        overflow:auto;" ref="chatRecords">
        <ul v-for="message in messages" :key="message.id" class="message" style="text-align: left; margin: 5px 20px; padding: 0;"
          v-show="!message.message==''">
          <div class="msg-time">
            {{ formatTimestamp(message.timestamp) }}
          </div>
          <li class="msg-container"  @contextmenu.prevent="handleMsgContextMenu(message,$event)" v-if="optionView==1" >
            <div v-if="friendOrAssistant">
              <div>
                <label :class="{'right': message.sender === username, 'left': message.sender !== username}" 
                  style="width: 35px; height: 35px;">
                   <img  src="@/assets/icon/小秘书头像3.png" alt="Your Image" v-if="message.sender!==username"
                     :style="{ width: '35px', height: '35px', borderRadius: '5%',backGround: 'rgb(255, 255, 255)'}"> 
                   <img  :src="avatarUrl_user" alt="Your Image" v-if="message.sender==username&&avatarUrl_user!==undefined"
                     :style="{ width: '35px', height: '35px', borderRadius: '5%',backGround: 'rgb(255, 255, 255)'}"> 
                   <div v-if="avatarUrl_user==undefined&&message.sender==username" class="friendmsg-altavatar">
                    {{ showAltNickname(userinfo.nickname) }}
                    <!-- {{ userinfo.nickname }} -->
                   </div>
                </label>
                <div style="line-height: 1.5; white-space: pre-wrap; border-radius: 5px;padding:0 0 0 5px;" 
                  :class="{'right':message.sender===username,'left':message.sender!==username}">
                  <span style="margin-bottom: 5px;font-size: 14px;" @click="inputMessage(message)">{{ message.message }}</span>
                  <div v-if="message.sender=='file|approve'"  @click="approvelFile(message)">
                    <span style="margin-bottom: 5px;font-size: 14px;color:blue;"
                      class="msgfilename">文件名：{{ message.file_name }}</span>
                    <!-- <button class="msg-button" @click.stop="downloadPubfile($event,message.pubfiledata[0])"><i class="el-icon-download"></i></button><br> -->
                    <button class="msg-button" @click.stop="downloadPubfile($event,message)"  v-if="!ifFileDeleted(message)" >
                      <i class="el-icon-download"></i>
                    </button>
                    <br>
                    <span v-if="ifFileApproved(message)" style="color:gray;">文件已审批</span>
                    <span v-if="ifFileDeleted(message)" style="color:gray;">文件已删除</span>
                    <span v-if="ifFilePublished(message)" style="color:gray;">文件已发布</span>
                  </div>
                  <div v-if="message.sender=='task|approve'"  @click="approveltask(message.taskidft)">
                    <span style="margin-bottom: 5px;font-size: 14px;color:blue;"
                      class="msgfilename">任务名：{{ computedMsgTaskName(message) }}</span><br>
                    <span v-if="ifTaskApproved(message)" style="color:gray;">任务已审批</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!friendOrAssistant">
              <label :class="{'right': message.sender === username, 'left': message.sender !== username}" 
                style="width: 35px; height: 35px;">
                <!-- <img :src="message.sender === username ? avatarUrl_user : avatarUrl_friend" alt="av" 
                 class="useravatar">   -->
                <img :src="avatarUrl_user" alt="av" class="useravatar" 
                  v-if="avatarUrl_user!==undefined&&message.sender==username">  
                <img :src="avatarUrl_friend" alt="av" class="useravatar" 
                  v-if="avatarUrl_friend!==undefined&&message.receiver==username">
                <div v-if="avatarUrl_user==undefined&&message.sender==username" class="friendmsg-altavatar">
                 {{ showAltNickname(message.sender_nickname) }}
                </div>
                <div v-if="avatarUrl_friend==undefined&&message.receiver==username" class="friendmsg-altavatar">
                 {{ showAltNickname(message.sender_nickname) }}
                </div>
              </label>
              <p :class="{'right': message.sender === username, 'left': message.sender !== username}" 
                style="line-height: 1.5; white-space: pre-wrap;" v-if="message.type==='text'" ref="msgcontainer">
                <!-- {{message.message}} -->
                <span v-html="formatMessage(message.message)"></span>
              </p>
              <!-- <img :src="message.imgUrl" v-if="isImgFile(message.file_name)" @click="showImage(message)" 
                :class="{'right': message.sender === username, 'left': message.sender !== username}"> -->
              <img :src="message.thumbimg" v-if="isImgFile(message.file_name)" @click="showImage(message)" 
                :class="{'right': message.sender === username, 'left': message.sender !== username}">
              <div class="msgfile-container" :class="{'right': message.sender === username, 'left': message.sender !== username}"
                v-if="message.type==='file'" @click="downloadMsgFile(message.file_name,'downloadmsgfile')">
                <div class="filename-container">
                  <div>
                    {{ showFileName(message.file_name) }}
                  </div>
                  <div style="font-size: 12px; color: rgb(150, 150, 150)">
                    {{ showFileSize(message.file_size) }}
                  </div>
                </div>
                <img style="width: 50px; height: 50px; margin: 15px 15px;" src="../../icon/message/文件.png">
              </div>
            </div>
          </li>
          <li class="msg-container"  v-if="optionView==2"   v-show="!message.message==''">
            <label :class="{'right': message.sender === username, 'left': message.sender !== username}" 
              style="width: 35px; background-color: rgb(255, 255, 255);">
              <img :src="showAvatar(message)" alt="av" class="useravatar" v-if="showAvatar(message)!==undefined"
               :style="message.sender!==username ? {paddingTop: '5px'} : {paddingTop: '0px'}">
              <div v-if="showAvatar(message)==undefined" class="friendmsg-altavatar">
               {{ showAltNickname(message.nickname) }}
               <!-- {{ message.nickname }} -->
              </div>
            </label>
            <div class="nickname-container">
              <div style="margin-bottom: 3px;color:gray;" class="taskmsg-sender" 
                :class="{'right': message.sender === username}" 
                v-if="!(message.sender === username)">
                {{ message.nickname }}
              </div>
              <div @contextmenu.prevent="handleMsgContextMenu(message,$event)">
                <p :class="{'right': message.sender === username, 'left': message.sender !== username}" 
                  style="line-height: 1.5; white-space: pre-wrap;" v-if="message.type==='text'" ref="msgcontainer">
                  <span v-html="formatMessage(message.message)" style="text-align: left;"></span>
                </p>
                <!-- <img :src="message.imgUrl" v-if="isImgFile(message.file_name)" @click="showImage(message)" 
                  :class="{'right': message.sender === username, 'left': message.sender !== username}"> -->
                <img :src="message.imgUrl" v-if="message.type=='img'" @click="showImage(message)" 
                  :class="{'right': message.sender === username, 'left': message.sender !== username}">
                <div class="msgfile-container" :class="{'right': message.sender === username, 'left': message.sender !== username}"
                  v-if="message.type==='file'" @click="downloadMsgFile(message.file_name,'downloadmsgfile',message)">
                  <div class="filename-container" style="text-align:left;">
                    <div >
                      {{ showFileName(message.file_name) }}
                    </div>
                    <div style="font-size: 12px; color: rgb(150, 150, 150)">
                      {{ showFileSize(message.file_size) }}
                    </div>
                  </div>
                  <img style="width: 50px; height: 50px; margin: 15px 15px;" src="../../icon/message/文件.png">
                </div>
              </div>
            </div>
  
          </li>
          <EditMsgMenu v-if="showEditMsgMenu" :parentNode="parentNode" @msgEditMenuitemClick="msgEditMenuitemClick">
          </EditMsgMenu>
          
        </ul>
        <PubTaskMenu v-if="showPubTaskMenu" :selectedMessage="selectedMessage" @closePubTaskMenu="closePubTaskMenu"></PubTaskMenu>
        <div v-if="showModal" @click="hideImage" class="modal"  @wheel="handleMouseWheel" >
          <img :src="clickedImgUrl" class="modal-image" :style="{ transform: `scale(${scale})` }"  v-draggable />
        </div>
      </div>
      <div class="input-container" style="height: 200px;">
        <div class="toolbar" style="height: 25px;">
          <button @click="toggleEmojiPicker" style="float: left;">😀</button>
          <button @click="toggleFilePicker" style="float: left;">📁</button>
          <!-- <div>  
            <VEmojiPicker @emojiClick="handleEmojiClick"
              v-show="showEmojiPicker"
              :style="{ width: '300px', height: '300px' }"
              labelSearch="Search"
              lang="pt-BR"  @select="selectEmoji">
            
            </VEmojiPicker>  
          </div>  -->
          <input type="file" ref="fileInput" style="display: none;" @change="handleFileChange">
        </div>
        <div @paste="handlePaste">
          <textarea v-model="newText"  @dragover="handleDragOver" @dragleave="handleDragLeave"  @drop="handleDrop"  @keydown="handleKeyDown"
            style="width: 99%; height: 150px; border: none; outline: none; white-space: pre-wrap; background-color: rgb(255, 255, 255);">
          </textarea>
        </div>
        <div style="height: 25px;">
          <button @click="sendMessage" style="float: right; width: 100px;">发送(S)</button>
        </div>
      </div>

    </div>
    <div v-if="optionView==3">
      <TaskMessage :messages="myTaskMsgs" :selectedProjectAcls="selectedProjectAcls" @sendMessage="sendTaskMsg" :username="username"
      @sendTaskMessageOfFile="sendTaskMessageOfFile">

      </TaskMessage>
    </div>
  </div>
</template>

<script>
import EditMsgMenu from '../menu/message/EditMsgMenu.vue'
import axios from 'axios'
import { mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue';
import draggableDirective from '../../plugin/draggable.js'
import TaskMessage from '../task/TaskMessage.vue'
import { eventBus } from '../../main.js'
import PubTaskMenu from '../menu/message/PubTaskMenu.vue'
import ApprFileMenu1 from '../menu/message/ApprFileMenu1.vue'
import PubFileToUsers from '../menu/file/PubFileToUsers.vue'
// import { VEmojiPicker, emojisDefault, categoriesDefault } from 'v-emoji-picker' 

export default {
  directives: {
    draggable: draggableDirective
  },
  components: {
    EditMsgMenu,
    PubTaskMenu,
    TaskMessage,
    ApprFileMenu1,
    PubFileToUsers,
    // VEmojiPicker
  },
  name: 'MsgRecord',
  props: ['id','chatto','messages','username','avatarUrl_user','avatarUrl_friend','myAllFileMsgs','optionView','selectedFileMsg',
          'selectedProjectAcls','ifShowMsgsRecord','myTaskMsgs','friendUsername','friendOrAssistant','userinfo','selectedTaskMsg'],
  data(){
    return {
      showEmojiPicker:false,
      newText:'',
      showModal:false,
      showSendFile:false,
      scale: 1,
      clickedImgUrl:'',
      parentNode:null,
      event:null,
      showEditMsgMenu:false,
      selectedMessage:null,
      showPubTaskMenu:false,
      ifShowApprFileMenu:false,
      approvingFile:null,
      fileProcessMsgs:[],
      selectedFile:null,
      loginTime:'',
      ifShowPubFileMenu:false,
    }
  },
  
  computed: {

    // showAltNickname(){
    //   return(nickname)=>{
    //     const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    //     const altNikname = matchResult ? matchResult[0] : ''
    //     return altNikname
    //   }
    // },

    showAltNickname(){
      return(nickname)=>{
        if (typeof nickname !== 'undefined') {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
      }
    },

    formatMessage() {
      return (message) => {
        const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+))/g;
        return message.replace(urlRegex, (match) => {
          if (match.startsWith('www')) {
            return `<a href="http://${match}" target="_blank" style="color: rgb(87,107,149); text-decoration: none;text-decoration: underline;">${match}</a>`;
          } else {
            return `<a href="${match}" target="_blank" style="color: rgb(87,107,149); text-decoration: none;text-decoration: underline;">${match}</a>`;
          }
        });
      };
    },

    computedMsgTaskName(){
      return (message)=>{
        const task=message.taskdata[0]
        return task.name
      }
    },
    showFileName(){
      return(fileName)=> {
        const fileName1=fileName.substring(fileName.indexOf("-") + 1);
        const maxLength = 20;
        let truncatedFileName = fileName1;
        if (fileName1.length > maxLength) {
          const extensionIndex = fileName1.lastIndexOf(".");
          const fileNameWithoutExtension = fileName1.substring(0, extensionIndex);
          const truncatedFileNameWithoutExtension = fileNameWithoutExtension.substring(0, 20) + "..." + fileNameWithoutExtension.slice(-1);
          truncatedFileName = truncatedFileNameWithoutExtension + fileName1.substring(extensionIndex);
          return truncatedFileName
        } else {
          return fileName1
        }
        
      }
    },
    showFileSize(){
      return(fileSize)=> {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        if (fileSize < megabyte) {
          return `${(fileSize / kilobyte).toFixed(2)} KB`;
        } else {
          return `${(fileSize / megabyte).toFixed(2)} MB`;
        }
      }
    },

    ifFileApproved(){
      return(message)=>{
        const file=message.pubfiledata[0]
        if(file){
          const username=localStorage.getItem('username')
          var checkeds=file.checked?file.checked.split(';'):[]
          checkeds.push(file.approved)
          if(checkeds.includes(username)){
            return true
          }else {
            return false
          }
        }else {
          return false
        }
      }
    },

    ifFilePublished(){
      return (message)=>{
        const file=message.pubfiledata[0]
        if(file&&file.filestatus=='已发布'){
          return true
        } else {
          return false
        }
      }
    },

    ifFileDeleted(){
      return(message)=>{
        const file=message.pubfiledata[0]
        if(!file){
          return true
        }else {
          return false
        }
      }
    },

    ifTaskApproved(){
      return(message)=>{
        const task=message.taskdata[0]
        // const username=localStorage.getItem('username')
        // var checkeds=file.checked.split(';')
        // checkeds.push(file.approved)
        if(task.done){
          return true
        }else {
          return false
        }
      }
    },


    formatTimestamp() {
      return (timestamp) => {
        const date = new Date(timestamp);
        const now = new Date();
        const diff = (now - date) / (1000 * 60 * 60 * 24); // 计算时间差，单位为天
    
        const formatTime = (date) => {
          let hours = date.getHours();
          let minutes = date.getMinutes();
          // 修正分钟为两位数
          minutes = minutes < 10 ? `0${minutes}` : minutes;
          return `${hours}:${minutes}`;
        };
    
        if (diff < 1) {
          // 如果是今天，则只显示时间
          return formatTime(date);
        } else if (diff < 2) {
          // 如果是昨天，则显示成‘昨天 08:25’
          return `昨天 ${formatTime(date)}`;
        } else if (diff < 7) {
          // 如果是本周，但又不是今天和昨天，则显示成‘周三 08:25’
          const weekdays = ['日', '一', '二', '三', '四', '五', '六'];
          return `周${weekdays[date.getDay()]} ${formatTime(date)}`;
        } else {
          // 如果不是本周，则显示成‘2023年5月20日 08:25’
          return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' + formatTime(date);
        }
      }
    },

    isImgFile() {
      return(filename)=>{
        if(filename=='') {
          return false
        } else {
          const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
          return imageExtensions.test(filename)
        }
      }
    },

    // isImgFile() {
    //   return(type)=>{
    //     if(type=='img') {
    //       return true
    //     } else {
    //       return false
    //     }
    //   }
    // },

    //以下是与文件聊天记录相关的计算属性
    showAvatar(){
      return(message)=>{
        
        if(this.selectedProjectAcls.length>0){
          const projectacl = this.selectedProjectAcls.find(item => item.username === message.sender);
          return projectacl.avatarUrl
        } else {
          return ''
        }
        
      }
    },
    


  },

  created() {


  },
  mounted(){

    // console.log('this.messages',this.messages)
    
    
  },

  watch:{
    messages: function(){
      if(this.messages?.length>0){
        this.$nextTick(() => {
        this.$refs.chatRecords.scrollTop = this.$refs.chatRecords.scrollHeight; // 滚动条滚动到底部
      });

      }

    },
  },


  
  methods: {


     // 将v-emoji-picker中选择的表情添加到评论内容中
     selectEmoji(emoji) {
      this.newText += emoji.data;
      this.showEmojiPicker=false
    },



    inputMessage(message){
    },
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),
    ...mapActions({
      getFileinfoByIdff:'getFileinfoByIdff',
      updatePubfileByIdff:'updatePubfileByIdff',
      // downloadPubfileFromServer:'downloadPubfileFromServer',
    }),


    downLoadSelectedFile(){
      if(this.selectedFileMsg.fileidff){
        const urlObject = new URL(this.selectedFileMsg.receiverUrl);
        const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
        this.downloadPubfileFromServer(fileName,'downloadpubfile')
      }else {
        if (this.selectedFileMsg.receiverUrl) {
        // 验证 URL 是否完整
        let url = this.selectedFileMsg.receiverUrl
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url; // 或 'https://'（取决于你的需求）
        }
        window.open(url, '_blank');
      } else {
        console.error('No URL selected');
      }
      }

    },


    downloadPubfileFromServer(url,downloadUrl) {
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
          'Cache-Control': 'no-cache', // 添加这一行来禁用缓存
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          link.setAttribute('download', url) // 设置下载文件的文件名
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源

          // 文件下载完成后执行以下操作,刷新页面并清除缓存。
          // window.location.reload(true); // 刷新页面并清除缓存
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    async getPubfileByIdff(idff) {
      const token = localStorage.getItem('token');
      const data = { idff: idff };
    
      try {
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/getpubfilebyidff',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
    
        if (Array.isArray(response.data)) {
          console.log('renheyigemeinv', response.data);
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    downloadPubfile(e,message){
      console.log('e',message)
      e.stopPropagation()
      const pubfile = message.pubfiledata[0]
      const urlObject = new URL(pubfile.url);
      const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      this.downloadPubfileFromServer(fileName,'downloadpubfile')
      // this.getPubfileByIdff(message.fileidff)
      //   .then((result)=>{
      //     const pubfile = result[0]
      //     const urlObject = new URL(pubfile.url);
      //     const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      //     this.downloadPubfileFromServer(fileName,'downloadpubfile')
      //   })

    },
    toggleEmojiPicker(){
      this.showEmojiPicker=true
    },

    toggleFilePicker(){
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      // 处理文件选择
      const files = event.target.files;
      console.log('files',files)
      if (files.length > 0) {
        if(!this.friendOrAssistant&&this.optionView==1){
          this.$emit('handleDropOfFriend',files)
        }else if(this.friendOrAssistant&&this.optionView==1){
          this.$emit('handleDropOfAssistant',files)
        }else if(this.optionView==2){
          this.$emit('handleFileDrop',files)
        } else {
          return
        }
      }
    },

    handleKeyDown(event){
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行行为
        this.sendMessage(); // 发送消息
      }
    },

    //根据文件idff获取相应的文件程序信息记录
    async getChatmsgsByReceiverAndAboutwhat(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        // this.fileChatMsgs=result.data
        // return this.fileChatMsgs
        if (Array.isArray(response.data)) {
          // this.fileChatMsgs=result.data
          return response.data;
        } else {
          // alert('账号未认证，无法获取数据sdf！');
          consloe.log('无相应聊天数据！')
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    closePubFileToUsers(){
      this.ifShowPubFileMenu = false
      this.switchMenuMask();
    },

    approvelFile(msg){
      
      const fileidff=msg.fileidff
      const file=msg.pubfiledata[0]
      const username=localStorage.getItem('username')
      if(!file){
        alert('文件已删除！')
      } else if(file.filestatus=='已发布'){
        alert('文件已发布！')
      } else if(file.filestatus=='已审批'&&file.publisher==username){
        this.approvingFile=file
        console.log('buaiqian',this.approvingFile)
        this.ifShowPubFileMenu = true
      } else {

        Promise.all([this.getFileinfoByIdff(fileidff), this.getChatmsgsByReceiverAndAboutwhat(fileidff,'fileprocess')])
        .then(([fileInfo, chatMsgs]) => {
          this.approvingFile = fileInfo[0];
          this.selectedFile=fileInfo[0]
          const username=localStorage.getItem('username')
          var approvers=this.approvingFile.checked.split(';')
          approvers.push(this.approvingFile.approved)
          if(approvers.includes(username)){
            alert('您已审批过该文件！')
          }else{
            this.fileProcessMsgs = chatMsgs;
            this.ifShowApprFileMenu = true;
          }

        });

      }
    },


    closeFileAprovelMenu(){
      this.ifShowApprFileMenu=false
    },

    handleFileProcessMsg(fileMsg,pubFile){
      this.ifShowApprFileMenu=!this.ifShowApprFileMenu
      this.switchMenuMask()
    },

    aprovelFileFromMsg(newAssitantMsg,fileMsg){
      this.$emit('aprovelFileFromMsg',newAssitantMsg,fileMsg)
      this.ifShowApprFileMenu=false
    },

    sendMessage(){
      if(this.chatto==undefined||this.chatto==''){
        alert('请选择对话对象！')
      } else {
        this.$emit('sendMessage',this.newText)
        this.newText=''
        this.$nextTick(() => {
          this.$refs.chatRecords.scrollTop = this.$refs.chatRecords.scrollHeight; // 滚动条滚动到底部
        });
      }
    },

    sendTaskMsg(newText){
      this.$emit('sendTaskMsg',newText)
    },

    //这个函数是用于在任务聊天框中发送文件
    sendTaskMessageOfFile(newMsg){
      // newMsg.receiver=this.selectedTaskMsg.receiver
      // this.addChatmsg(newMsg)
      this.$emit('sendTaskMessageOfFile',newMsg)
    },

    //禁止默认右键菜单
    disableContextMenu(event) {
      event.preventDefault();
    },

    //显示和隐藏图片函数
    showImage(message) {
      this.clickedImgUrl=message.imgUrl
      this.showModal = true;

    },
    hideImage(event) {
      if (event.target.classList.contains('modal-image')) {
        return;
      } else {
        this.showModal = false;
        this.scale=1
      }
    },

    //滑动中轮以扩大或缩小图片显示
    handleMouseWheel(event) {
      event.preventDefault(); // 阻止默认滚动行为
  
      // 根据鼠标滚轮的delta值，计算缩放比例
      const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
      this.scale += delta * 0.1; // 根据需要调整缩放速度
    },

    //处理拖入和拖放函数
    handleDragOver(event){
      event.preventDefault()
      event.target.style.background = 'rgba(235, 235, 235, 0.7)'; // 拖拽进入时的背景
    },

    handleDragLeave(event){
      event.preventDefault()
      event.target.style.background = 'transparent';
    },

    handleDrop(event){
      event.preventDefault()
      const files = event.dataTransfer.files;

      if(!this.friendOrAssistant&&this.optionView==1){
        this.$emit('handleDropOfFriend',files)
      }else if(this.friendOrAssistant&&this.optionView==1){
        this.$emit('handleDropOfAssistant',files)
      }else if(this.optionView==2){
        const files = event.dataTransfer.files;
        this.$emit('handleFileDrop',files)

      } else {
        return
      }
      
    },

    // handleFileDrop(event){
    //   event.preventDefault()
    //   const files = event.dataTransfer.files;
    //   this.$emit('handleFileDrop',files)
    // },

    //下载聊天记录中文件的处理函数
    downloadMsgFile(url,downloadUrl,message){
      console.log('message',message)
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
          // 'Access-Control-Allow-Origin': '*',
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          link.setAttribute('download', url) // 设置下载文件的文件名
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    //聊天记录的右键菜单
    handleMsgContextMenu(message,event){
      event.preventDefault()
      event.stopPropagation()
      this.selectedMessage=message
      this.showEditMsgMenu=true
      this.switchMenuMask()
      const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
      const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
      this.parentNode ={clientY:top,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showEditMsgMenu=false
      })
      this.selectedMessage=message
    },


    //处理往输入框黏贴文字或者图片的处理函数
    handlePaste(event){
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;

      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          // 处理黏贴的图片
          console.log('黏贴的是图片');
        } else {
          // 处理黏贴的文本
          console.log('黏贴的是文本');
        }
      }
    },

    //处理聊天记录的处理函数
    msgEditMenuitemClick(item){
      
      switch (item.id){
        case 1: {
          if(this.selectedMessage.type=='text'){
            this.copyText(this.selectedMessage.message)
          }

          this.switchMenuMask()

          break
        }
        case 2: {
          console.log('2')
          break
        }
        case 3: {
          this.showPubTaskMenu=true

          
          break
        }
        case 4: {
          break
        }
        case 5: {
          break
        }
        case 6: {
          this.deleteMsg(this.selectedMessage)

          this.switchMenuMask()
          break
        }                
      }
      
      this.showEditMsgMenu=false
    },


    //复制选中对象中的文字
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log('Text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },

    //关闭通过聊天记录发布任务的函数
    closePubTaskMenu(){
      this.showPubTaskMenu=false
      this.switchMenuMask()
    },

    deleteMsg(message){
      this.$emit('deleteMessage',message)
      // const saverUrl=this.username==message.savebysender?'updatemsgsendersaverbyid':'updatemsgreceiversaverbyid'
      const saverUrl=this.username==message.sender?'updatemsgsendersaverbyid':'updatemsgreceiversaverbyid'
      if(message.savebysender&&message.savebyreceiver){
        this.deleteMySqlMsgbyId(message.id,saverUrl)
      }else {
        this.deleteBothSqlMsgbyId(message.id)
        if(message.file_name) {
          this.deleteSqlMsgFilebyName(message.file_name)
        }
      }
    },

    deleteMySqlMsgbyId(id,saverUrl){
      const token = localStorage.getItem('token')
      axios({
        method:'put',
        url: 'https://api1.itaskid.com' + `/my/${saverUrl}`,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          console.log(response.data)
          console.log('删除我的聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },

    deleteBothSqlMsgbyId(id){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api1.itaskid.com' + `/my/deletemsgbyid`,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          console.log(response.data)
          console.log('删除双方的聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },    

    deleteSqlMsgFilebyName(fileName){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletemsgfilebyname',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {file_name:fileName}
        })
        .then((response)=>{
          console.log(response.data)
          console.log('删除聊天记录文件成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录文件失败！')
        })
    },




  },
}
</script>

<style lang="less" scoped>
.name {
  display: block;
}

.last-message {
  display: block;
}




li {
    list-style: none;
  }

li:hover {
  background-color: rgb(200, 200, 200);
  cursor: pointer;
}

.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 50px;
  background: none;
}

.info {
  display: inline;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


  .message {     //ok
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    flex: 1; /* 占据剩余空间 */
  }

  .right {
  // text-align: right;
  float: right;
  // margin-left:10px;
  margin: 0 0 0 10px;
  background-color: #eaf6ff;

  }
  
  .left {
    // text-align: left;
    float: left;
    // padding-top:8px;
    margin: 0 10px 0 0;
    background-color: #f2f2f2;
    white-space: pre-wrap;

  }

  .msg-time {    //ok
    display: flex;
    justify-content: center;
    height: 20px;
    line-height: 20px;
    background-color: rgb(218, 218, 218);
    color: rgb(250,250,250);
    width: fit-content;
    margin: 0 auto;
    white-space: pre-wrap;
    // pointer-events: none;
  }
  .msg-container {
    background-color: none;
    position:relative;

    .useravatar {
      width: 35px;
      height: 35px;
      border-radius: 5%; 
    }

    .taskmsg-sender:hover {
      background-color: none;
    }

    img {
      height: 150px;
      border-radius: 3px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      color: rgb(35,35,35);
      border-radius: 5px;
      max-width: 40%;
      padding:5px;
    }

    div.filename-container {
      width: 190px;
      word-wrap: break-word;
      display: flex;
      margin: 0;
      flex-direction: column;

    }
    
    p.left {
      background-color: rgb(235,235,235);
      padding-left:5px;
    }

    p.right {
      background-color: rgb(149, 236, 105);
      padding-left:5px;
    }

    .msgfile-container {
      display: flex;
      height: 80px;
      width: 280px;
      font-size: 14px;
      border-radius: 5px;
      padding-left:10px;
    }

    a:hover {
      text-decoration: underline;
    }

  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: auto;
  }

  .modal-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  a {
    color: rgb(87, 107, 149); 
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline !important;
    color:red !important;
  }

  .nickname-container:hover {
    background: rgb(255, 255, 255);
  }

  .msg-button {
    width: 25px; 
    height: 25px;
    // border-radius: 50%;
    background: none; 
    border: none;
    padding: 0; 
    float:right;
    margin:0 0 0 6px; 
    line-height: 30px;
    // border: 0.5px solid blue;
    i {
      width: 15px;
      height: 15px;
      margin: 0; 
      padding: 0; 
      color:blue;
    }

  }

  .msgfilename:hover {
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
    text-decoration: underline; /* 添加下划线效果 */
  }

  .friendmsg-altavatar {
    width: 35px;
    height: 35px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 14px;
    line-height: 35px;
    text-align: center;
  }

  .selectFileName:hover {
    color:rgb(145, 170, 252);
  }
</style>