import { render, staticRenderFns } from "./EditShareFileMenu.vue?vue&type=template&id=3c8f7f88&scoped=true"
import script from "./EditShareFileMenu.vue?vue&type=script&lang=js"
export * from "./EditShareFileMenu.vue?vue&type=script&lang=js"
import style0 from "./EditShareFileMenu.vue?vue&type=style&index=0&id=3c8f7f88&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c8f7f88",
  null
  
)

export default component.exports