import { render, staticRenderFns } from "./MsgRecord.vue?vue&type=template&id=d5e5a164&scoped=true"
import script from "./MsgRecord.vue?vue&type=script&lang=js"
export * from "./MsgRecord.vue?vue&type=script&lang=js"
import style0 from "./MsgRecord.vue?vue&type=style&index=0&id=d5e5a164&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5e5a164",
  null
  
)

export default component.exports