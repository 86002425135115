
<template>
  <div style="text-align: left;">
    <div class="pubfileheader-container" style="float: left;">
      <div style="margin-top:10px;">
        <h4 style="display: inline-block;">&nbsp;&nbsp;我的文档</h4>
        <button style="height:30px; width: 50px;margin-left: 235px" @click="showAllMyOwnFiles">全部</button>
        <button style="height:30px; width: 60px;margin-left: 5px" @click="showMyOwnFilesOfNogroup">未分组</button>
        <button style="height:30px; width: 58px;margin-left: 5px" @click="showUploadMyFileMenu('all')">添加+</button>
        <!-- <button style="height:30px; width: 58px;margin-left: 5px" @click="deleteMyFileByButon()">删除-</button>
        <button style="height:30px; width: 58px;margin-left: 5px" @click="showUploadMyFileMenuByButton()">编辑</button> -->
        <!-- 弹出菜单的代码 -->
        <UploadMyFileMenu  v-if="isShowUploadMyFileMenu" @closeFilePubMenu="closeFilePubMenu" @uploadNewFile="uploadNewFile" :myProjectlist="myProjectlist"
          :allMyPubFileFolders="allMyPubFileFolders" :selectedParentFolder="selectedParentFolder">
        </UploadMyFileMenu>
        <EditMyFileMenu v-if="isShowEditMyFileMenu" :selectedFileIdfpf="selectedFileIdfpf" :selectedFile="selectedFile" @closeFileEditMenu="closeFileEditMenu"
          :myProjectlist="myProjectlist" :allMyPubFileFolders="allMyPubFileFolders" :selectedParentFolder="selectedParentFolder"
          @updateMyFileByIdfpf="updateMyFileByIdfpf">
        </EditMyFileMenu>
        <PubMyFileMenu v-if="isShowPubMyFileMenu" @closePubMyFileMenu="closePubMyFileMenu" :selectedFile="selectedFile"  :myProjectlist="myProjectlist"
         :allMyPubFileFolders="allMyPubFileFolders1" :selectedParentFolder="selectedParentFolder" @pubMyFile="pubMyFile">
        </PubMyFileMenu>
        <!-- 弹出菜单的代码 -->
      </div>
      <div>
        <table style="margin-left: 10px; margin-right: 10px;">
          <tr class="tableheader">
            <td style="width: 275px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;text-align:left;position: relative;">
              <i class="el-icon-search" style="position: absolute;left:8px;top:8px;"></i>
              <input type="text" placeholder="文件名称"  class="search-input" cospan="2" style="width:300px;" v-model="searchFilesText"
                @change="getSearchFiles()">
              <i class="el-icon-circle-close" style="position: absolute;left:280px;top:8px;" v-if="searchFilesText"
                @click="cancelSearchFiles()">
              </i>
            </td>
            <td style="width: 80px;padding-left:10px;">状态</td>
            <td colspan="3" style="width: 75px;text-align:center;">操作</td>
          </tr>
        </table>
      </div>
      <div style="height: calc(100vh - 200px;); overflow: auto;">
        <!-- <ApprFileMenu v-if="ifShowApprFileMenu" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closeFileAprovelMenu="closeFileAprovelMenu" @handleFileProcessMsg="handleFileProcessMsg" 
          @approveLocalPubfileByIdff="approveLocalPubfileByIdff">

        </ApprFileMenu>
        <PubFileToUsers v-if="ifShowPubFileToUsers" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closePubFileToUsers="closePubFileToUsers">

        </PubFileToUsers> -->
        <EditFileMenu v-if="ifShowEditFileMenu" :parentNode="parentNode" :selectedFile="selectedFile" 
          :TaskeditMenuitems="TaskeditMenuitems" @fileeditMenuitemClick="fileeditMenuitemClick">

        </EditFileMenu>
        <div  v-show="ifShowSearchFiles">
          <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
            <tr v-for="file1 in searchFiles" :key="file1.idff" >
              <td style="width:25px;">
                 <i class="el-icon-folder-opened" v-if="file1.isfolder&&(file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                 <i class="el-icon-folder" v-if="file1.isfolder&&(!file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                 <i class="el-icon-document"  v-if="!file1.isfolder"></i>
              </td>
              <td @click="gotoFileDetail(file1,index)"  style="width: 280px;" class="filenametitle"
                  :class="{ pubfileclicked: selectedPrivatefileIdff==file1.idfpf }">
                  <div style="display:inline-block;width:280px;">
                    {{ file1.filename }}
                  </div>
                </td>
              <td style="width: 80px;">{{ file1.filestatus }}</td>
              <td style="width:20px;" v-show="file1.isfolder">
                <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file1.isfolder" @click="showUploadMyFileMenu(file1.idfpf,file1)">
                  <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                  </i>
                </button>
              </td>
              <td v-show="!file1.isfolder">
                <button style="width: 20px; background: none; border: none;padding: 0;" @click.stop="downloadMyFile(file1)" >
                  <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                </button>
              </td>
              <td>
                <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadMyFile(file1)" >
                  <i class="el-icon-share" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                </button>
              </td>
              <td>
                <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                  @click.stop="pubMyFileFromButton(file1)">
                  <i class="el-icon-s-promotion" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                </button>
              </td>
            </tr>          
          </table>
        </div>
        <div v-show="!ifShowSearchFiles">
          <div v-for="(file1,index) in myPubFiles1()" :key="file1.idff" >
            <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
              <tr @contextmenu.prevent="showfileEditMenu($event,file1)">
                <td style="width:25px;">
                   <i class="el-icon-folder-opened" v-if="file1.isfolder&&(file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-folder" v-if="file1.isfolder&&(!file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-document"  v-if="!file1.isfolder"></i>
                </td>
                <td @click="gotoFileDetail(file1,index)"  style="width: 280px;" class="filenametitle"
                  :class="{ pubfileclicked: selectedPrivatefileIdff==file1.idfpf }">
                  <div style="display:inline-block;width:280px;">
                    {{ file1.filename }}
                  </div>
                </td>
                <td style="width: 80px;">{{ file1.filestatus }}</td>
                <td style="width:20px;" v-show="file1.isfolder">
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file1.isfolder" @click="showUploadMyFileMenu(file1.idfpf,file1)">
                    <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                    </i>
                  </button>
                </td>
                <td v-show="!file1.isfolder">
                  <button style="width: 20px; background: none; border: none;padding: 0;" @click.stop="downloadMyFile(file1)" >
                    <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
                <td>
                  <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                    @click.stop="downloadMyFile(file1)"  :class="{ 'disabled-button': file1.isfolder === 1 }">
                    <i class="el-icon-share" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
                <td>
                  <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                    @click.stop="pubMyFileFromButton(file1)"  :class="{ 'disabled-button': file1.isfolder === 1 }">
                    <i class="el-icon-s-promotion" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
              </tr>          
            </table>
            <div v-for="(file2,index) in myPubFilesOfLevelN(2,file1)" :key="file2.idff"  v-show="file1.file_expanded">
              <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable" v-if="file1.file_expanded">
                <tr  @contextmenu.prevent="showfileEditMenu($event,file2)" >
                  <td style="width:25px;position:relative;">
                    <label for="" style="position:absolute;left:6px;top:-5px;">
                      <i class="el-icon-folder-opened" v-if="file2.isfolder&&file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                    </label>
                  </td>
                  <td @click="gotoFileDetail(file2,index)"  style="width: 280px;" class="filenametitle"
                    :class="{ pubfileclicked: selectedPrivatefileIdff==file2.idfpf }">
                    <div style="padding-left:6px;">
                      {{ file2.filename }}
                    </div>
                  </td>
                  <td style="width: 80px;">{{ file2.filestatus }}</td>
                  <td style="width:20px;" v-show="file2.isfolder">
                    <button style="width: 20px; background: none; border: none;padding: 0;" 
                      v-if="file2.isfolder" @click="showUploadMyFileMenu(file2.idfpf,file2)">
                      <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                      </i>
                    </button>
                  </td>
                  <td v-show="!file2.isfolder">
                    <button style="width: 20px; background: none; border: none;padding: 0;" @click.stop="downloadMyFile(file2)" >
                      <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                  <td>
                    <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                     @click.stop="downloadMyFile(file1)"  :class="{ 'disabled-button': file2.isfolder === 1 }">
                      <i class="el-icon-share" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                  <td>
                    <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                      @click.stop="pubMyFileFromButton(file2)" :class="{ 'disabled-button': file2.isfolder === 1 }">
                      <i class="el-icon-s-promotion" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                </tr>          
              </table>
              <div v-for="(file3,index) in myPubFilesOfLevelN(3,file2)" :key="file3.idff"  v-show="file2.file_expanded">
                <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                  <tr  @contextmenu.prevent="showfileEditMenu($event,file3)" >
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:12px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file3.isfolder&&file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-document"  v-if="!file3.isfolder"></i>
                      </label>
                    </td>
                    <td @click="gotoFileDetail(file3,index)"  style="width: 280px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPrivatefileIdff==file3.idfpf }">
                      <div style="padding-left:12px;">
                        {{ file3.filename }}
                      </div>
                    </td>
                    <td style="width: 80px;">{{ file3.filestatus }}</td>
                    <td style="width:20px;" v-show="file3.isfolder">
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file3.isfolder"
                         @click="showUploadMyFileMenu(file3.idfpf,file3)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                    </td>
                    <td v-show="!file3.isfolder">
                      <button style="width: 20px;background: none; border: none;padding: 0;" @click.stop="downloadMyFile(file3)" >
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                       @click.stop="downloadMyFile(file1)" :class="{ 'disabled-button': file3.isfolder === 1 }">
                        <i class="el-icon-share" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                        @click.stop="pubMyFileFromButton(file3)"  :class="{ 'disabled-button': file3.isfolder === 1 }">
                        <i class="el-icon-s-promotion" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                  </tr>          
                </table>
                <div v-for="(file4,index) in myPubFilesOfLevelN(4,file3)" :key="file4.idff"  v-show="file3.file_expanded">
                  <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                    <tr @contextmenu.prevent="showfileEditMenu($event,file4)" >
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:12px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file4.isfolder&&file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-document"  v-if="!file4.isfolder"></i>
                      </label>  
                    </td>
                    <td @click="gotoFileDetail(file4,index)"  style="width: 280px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPrivatefileIdff==file4.idfpf }">
                      <div style="padding-left:12px;">
                        {{ file4.filename }}
                      </div>
                    </td>
                    <td style="width: 80px;">{{ file4.filestatus }}</td>
                    <!-- <td  class="filestatusstyle">
                      <label for="" v-if="!file4.isfolder">{{ file4.filestatus }}</label>
                    </td> -->
                    <td style="width:20px;" v-show="file4.isfolder">
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file4.isfolder"
                         @click="showUploadMyFileMenu(file4.idfpf,file4)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                    </td>
                    <td v-show="!file4.isfolder">
                      <button style="width: 20px;background: none; border: none;padding: 0;"
                        @click.stop="downloadMyFile(file4)" >
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                       @click.stop="downloadMyFile(file1)"  :class="{ 'disabled-button': file4.isfolder === 1 }">
                        <i class="el-icon-share" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" 
                        @click.stop="pubMyFileFromButton(file4)" :class="{ 'disabled-button': file4.isfolder === 1 }">
                        <i class="el-icon-s-promotion" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    </tr>          
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pubfiledetail-container">
      <div style="float: left; width: 100%;">
        <h4>&nbsp;&nbsp;文件详情</h4>
        <div style="margin-left:10px;border-bottom: 0.5px solid gray;">
          <label for="">名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称:</label>
          <label for="" style="margin-left: 10px;">{{ selectedFile && selectedFile.filename }}</label> <br>
          <label for="">类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型：</label>
          <label for="">{{ computedFileType(selectedFile) }}</label><br>
          <label for="" >文件大小：</label>
          <label for="">{{ selectedFile && selectedFile.size }}</label><br>
          <label for="">文件分类：</label>
          <label for="">{{ selectedFile && selectedFile.folder }}</label><br>
          <label for="" >项&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目：</label>
          <label for="">{{ selectedFile&&computedProject(selectedFile) }}</label><br>
          <label for="" >上传时间：</label>
          <label for="">{{ selectedFile && datetimeConvert(selectedFile.updatetime) }}</label><br>  
        </div>
      </div> 
      <div style="width: 100%;height:calc(100vh - 400px);border:none;">
        <div>
          <textarea class="filereminder" placeholder="文件备注。。。" @change="changeReminder" v-model="fileReminderText">
          </textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMyFileMenu from '../menu/file/UploadMyFileMenu.vue'
import ApprFileMenu from '../menu/file/ApprFileMenu.vue'
import PubFileToUsers from '../menu/file/PubFileToUsers.vue'
import EditMyFileMenu from '../menu/file/EditMyFileMenu.vue'
import PubMyFileMenu from '../menu/file/PubMyFileMenu.vue'
import EditFileMenu from '../menu/file/EditFileMenu.vue'
import { eventBus } from '../../main.js'
import { mapState , mapMutations,mapActions} from 'vuex'
import  axios from 'axios'
import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'FilePrivate',
  components: {
    UploadMyFileMenu,
    EditMyFileMenu,
    ApprFileMenu,
    PubFileToUsers,
    EditFileMenu,
    PubMyFileMenu
  },
  props: ['myProjectlist1'],
  data() {
    return {
      isShowPubMyFileMenu:false,
      // myPubFiles1:[],
      selectedFileIdfpf:'',
      fileNameEditable:false,
      TaskeditMenuitems:[
      { id:1,label: '下载' },
        { id:2,label: '编辑' }, 
        {id:3,label:'分享'},
        { id:4,label: '发布' },
        { id:5,label: '删除' }
      ],
      parentNode:null,
      ifShowEditFileMenu:false,
      altNikname:'',
      searchFiles:[],
      searchFilesText:'',
      userinfo:null,
      viewOption:'all',
      publisher: '',
      fileDetails: '',
      pubfiles: [],
      myPubFiles:[],
      allMyPubFiles:[],
      allMyPubFiles1:[],
      allMyPubFileFolders:[],
      allMyPubFileFolders1:[],
      filename: '',
      filetype: '',
      size: '',
      filedownloadtimes:0,
      filesummary:'',
      filepublisher:'',
      filepubtime: '',
      fileProject: '',
      fileFolder: '',
      checkingNum:1,
      pubfile:{idff:'', project:'',fold:'',filename:'',publisher:'王洪文的',approver:'',pubtime:'', size: 0, downloadtimes:0,type:'',
               summary:'',downloadtimes:0, },
      isShowUploadMyFileMenu: false,
      isShowEditMyFileMenu:false,

      //与选中的文件相关的数据
      selectedFile:null,
      selectedParentFolder:null,
      selectedProjectIdfp:'',

      ifShowApprFileMenu:false,
      ifShowPubFileToUsers:false,
      approvingFile:null,
      pubProjectParters:[],
      myProjectlist:[],

      //有关文件审批流程的信息记录
      fileProcessMsgs:[],
      selectedPubfileIndex:[-1,-1,-1,-1,-1,-1],
      selectedPrivatefileIdff:'',


      //有关文件聊天记录的数据
      fileChatMsgs:[],
      selectedProjectAcls:[],
      showIcon:true,

      //测试一组文件夹数组
      testArray:[
        {idff:1,name:'enen1',parent:'rootfile'},
        {idff:2,name:'enen2',parent:9},
        {idff:3,name:'enen3',parent:1},
        {idff:4,name:'enen4',parent:3},
        {idff:5,name:'enen5',parent:'rootfile'},
        {idff:6,name:'enen6',parent:4},
        {idff:7,name:'enen7',parent:1},
        {idff:8,name:'enen8',parent:6},
        {idff:9,name:'enen9',parent:'rootfile'},
      ],
      ifShowSearchFiles:false,
      fileReminderText:'',

    }
  },
  computed:{
    ...mapState({
      mySqlProjects: 'mySqlProjects',
    }),

    computedProject(){
      
      return(file)=>{
        
        if(file){
          const foundProject = this.myProjectlist1.find(item => item.idfp === file.project);
          if (foundProject) {
            return foundProject.name;
          }
        }else {
          return ''
        }
      }
    },

    computedFileType(){
      return(file)=>{
        if(file){
          if(file.isfolder){
            return '文件夹'
          } else {
            return file.type
          }
        }else {
          return ''
        }
      }
    },


    myPubFiles1(){
      return()=>{
        if(this.myPubFiles){
          var myPubFiles1=this.myPubFiles?.filter(file=>file.file_level==1)
          myPubFiles1=myPubFiles1.sort((a, b)=>new Date(b.updatetime) - new Date(a.updatetime))
          return myPubFiles1
        }else {
          return []
        }
      }
    },

    myPubFilesOfLevelN(){
      return (n,file)=>{
        var filesData=this.myPubFiles.filter(item=>item.file_level==n&&item.file_parent==file.idfpf)
        filesData=filesData.sort((a, b)=>new Date(b.updatetime) - new Date(a.updatetime))
        return filesData
      }
    },







    datetimeConvert(){


      return (datetime) => {
        // 创建Date对象，假设输入的是UTC时间
        const date = new Date(datetime);
    
        
        // 接下来，使用转换后的时间进行格式化
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 已经转换为中国标准时间，不需要再加8
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        // 构建并返回格式化的字符串
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedTime;
      }
    },

    showCheckingNum(){
      return()=>{
        var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
        var files=this.allMyPubFiles.filter(file=>(file.filestatus=='待审批'||file.filestatus=='待审核')&&file.isfolder==0)
        const username=localStorage.getItem('username')
        var myApprovingFiles=files.filter((file)=>{
          var checkers=file.checker.split(';')
          var checkeder=file.checked.split(';')
          var approver=file.approver
          var approved=file.approved
          if(checkers.includes(username)&&!checkeder.includes(username)){
            return file
          } else if(approver==username&&approved==''){
            const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
            if(allCheckersInCheckeder){
              return file
            }
          } else {
          }
        })

        const num=myApprovingFiles.length

        // var checkingNum=0
        // var checkinger=[]
        // var checkeder=[]
        
        // const username=localStorage.getItem('username')
        // const myPubFiles=this.allMyPubFiles.filter(file=>file.isfolder==0)
        // myPubFiles.forEach((file)=>{
        //   const approver=file.approver
        //   const approveder=file.approved
        //   if(!(file.checker=='')){
        //     checkinger=file.checker.split(';')
        //   }
        //   // if(!(file.approver=='')){
        //   //   checkinger.push(file.approver)
        //   // }
        //   if(!(file.checked=='')){
        //     checkeder=file.checked.split(';')
        //   }
        //   // if(!(file.approved=='')){
        //   //   checkeder.push(file.approved)
        //   // }
        //   if(checkinger.includes(username)&&!(checkeder.includes(username))) {
        //     checkingNum++
        //   } else if(approver==username){
        //     const allCheckersInCheckeder=checkinger.every(checker=>checkeder.includes(checker))
        //     if(allCheckersInCheckeder){
        //       checkingNum++
        //     }
        //   } else {
            
        //   }
        // })
        if(num>99){
          return '99+'
        } else if(num>0) {
          return num
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateMySqlProjects:'updateMySqlProjects',
    }),

    ...mapActions({
      downloadFileFromServer:'downloadFileFromServer',
      getFileinfoByIdff:'getFileinfoByIdff',
      addFileUserAclSql: 'addFileUserAclSql',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      showPic:'showPic',
      getPubfilesByProject:'getPubfilesByProject',
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      addChatmsg:'addChatmsg',
      // getChatmsgsByReceiverAndAboutwhat:'getChatmsgsByReceiverAndAboutwhat',
    }), 

    pubMyFileFromButton(file){
      this.selectedFile=file
      this.selectedParentFolder=file.folder
      this.selectedFile.idff=uuidv4()
      this.isShowPubMyFileMenu=true
      this.switchMenuMask()
      // this.ifShowEditFileMenu=false
    },

    fileeditMenuitemClick(item){
      switch (item.id){
        case 1: {
          if(this.selectedFile.isfolder==1){
            return
          }
          this.downloadMyFile(this.selectedFile)
          this.ifShowEditFileMenu=false

          break
        }
        case 2: {
          this.selectedFileIdfpf=this.selectedFile.idfpf
          this.selectedParentFolder=this.selectedFile.folder
          this.ifShowEditFileMenu=false
          this.isShowEditMyFileMenu=true

          break
        }
        case 3: {

          break
        }
        case 4: {
          if(this.selectedFile.isfolder==1){
            alert('不能发布文件夹！')
          }else {
            this.selectedParentFolder=this.selectedFile.folder
            this.selectedFile.idff=uuidv4()
            this.isShowPubMyFileMenu=true
            this.ifShowEditFileMenu=false
          }

          break
        }
        case 5: {
          this.deleteMyFile(this.selectedFile)
          this.ifShowEditFileMenu=false

          break
        }

        
      
      }
    },

    showfileEditMenu(e,file){

      e.preventDefault()
      e.stopPropagation()
      this.ifShowEditFileMenu=true
      this.selectedFile=file
      this.switchMenuMask()
      const top=(window.innerHeight-e.clientY)<230?(e.clientY-230):e.clientY+10
      const left=(window.innerWidth-e.clientX)<112?(e.clientX-112):e.clientX+10
      this.parentNode ={clientY:top,clientX:left}
      eventBus.$on('closeContexMenu', () => {
      this.ifShowEditFileMenu=false
      })


    },

      //修改服务器中的文件信息
    updateMyFileByIdfpf(newPubfile) {
      this.selectedFile=newPubfile
      const foundItem = this.myPubFiles.find(item => item.idfpf === newPubfile.idfpf);
      if (foundItem) {
        const index = this.myPubFiles.indexOf(foundItem);
        this.myPubFiles[index] = newPubfile;
      }
      
      const token = localStorage.getItem('token')
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/updateprivatefilesbyidfpf',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newPubfile
      })
        .then((response) => {
          
          console.log('修改文件成功!')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },


    
    async getAllMyUploadFiles(username) {
      try {
        const token = localStorage.getItem('token');
        const data = { username: username };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/getallmyuploadfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
  
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getMyOwnfilesByProject(project, username) {
      try {
        const token = localStorage.getItem('token');
        const data = { project: project, creater: username };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/privatefilesbyproject',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
  
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    deleteMyFile(file){
      this.deleteMyFileOfSql(file)
      if(file.isfolder==1){
        this.delChildFile(this.myPubFiles, file)
      }
      this.myPubFiles=this.myPubFiles.filter(item=>item.idfpf!==file.idfpf)
    },

    deleteMyFileByButon(){
      if(!this.selectedFile){
        alert('请选择要删除文件！')
      }else {
        this.deleteMyFile(this.selectedFile)
      }
    },

    deleteMyFileOfSql(file){
      if(!file){
        alert('请选择文件！')
      }else {
        const token = localStorage.getItem('token')
      axios({
        method: 'delete',
        url: 'https://api2.itaskid.com' + '/my/deleteprivatefilebyidfpf',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: { idfpf: file.idfpf }
      })
        .then((response) => {
          this.deleteMyFileOfSqlx(file.url)
  
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除我的文件失败！')
        })

      }

    },

    //这个是删除文件的路径
    deleteMyFileOfSqlx(url) {
        const token = localStorage.getItem('token');
        axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/deletemyfilebyurl',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: { url:url }
        })
        .then(response => {
          console.log('文件删除成功！');
        })
        .catch(error => {
          console.error('文件删除失败:', error);
        });
    },

    delChildFile(files, parentFile) {
      let hasChildFiles = false;
      files.forEach((file) => {
        if (file.file_parent === parentFile.idfpf) {
          this.deleteMyFileOfSql(file);
          // files.splice(index, 1); // 从数组中删除已处理的文件
          const leftChildFiles = files.filter((item) => item.file_parent === file.idfpf);
          if (leftChildFiles.length > 0) {
            hasChildFiles = true;
            this.delChildFile(files, file);
          }
        }
      });
      if (!hasChildFiles) {
        return;
      }
    },

    cancelSearchFiles(){
      this.searchFilesText=''
      this.ifShowSearchFiles=false
    },
    getSearchFiles(){
      if(this.searchFilesText!==''){
        this.searchFiles=this.myPubFiles.filter(file=>file.filename.includes(this.searchFilesText)&&file.isfolder==0)
        this.ifShowSearchFiles=true
      }else {
        this.ifShowSearchFiles=false
      }
    },




    approveLocalPubfileByIdff(newFileMsg,newPubFile){
      this.allMyPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.myPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.fileProcessMsgs.unshift(newFileMsg)
      this.ifShowApprFileMenu=false
    },


    changeFileExpanded(file){
      file.file_expanded=file.file_expanded?0:1
    },


    getFoldersOfFile(file,folderArray){
      const filefolders=folderArray.filter(item=>item.idff==file.file_parent)
      if(filefolders.length>0){

        if(!this.myPubFiles.includes(filefolders[0])){
          this.myPubFiles.push(filefolders[0])
        }
        
        this.getFoldersOfFile(filefolders[0],folderArray)
      }
    },

    startAnimation() {
      setInterval(() => {
        this.showIcon = !this.showIcon;
      }, 800); // 切换图标的显示状态，实现时隐时现的效果
    },

    downloadMyFile(file){
      const urlObject = new URL(file.url);
      const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      this.downloadPubfileFromServer(fileName,'downloadmyownfile',file.filename)
    },

    //把标准时间转换成普通的字符串时间
    convertDate(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
      
    },
    approvelFile(file){
      const username=localStorage.getItem('username')
      if((file.filestatus==='待审批'||file.filestatus==='待审核')){
        this.approvingFile=file
        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })

        this.ifShowApprFileMenu=true
        this.switchMenuMask()
      }else if(file.filestatus==='已审批'&&file.publisher==username) {
        this.approvingFile=file
        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })
        this.ifShowPubFileToUsers=true
        this.switchMenuMask()
      } else {
        alert('文件由发起人发布！')
      }

    },

    handleFileProcessMsg(fileMsg){
      this.fileProcessMsgs.push(fileMsg)
    },

    closePubFileToUsers(){
      this.ifShowPubFileToUsers=!this.ifShowPubFileToUsers
    },

    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile(item, targetArray) {
      
      var itemChildren = targetArray.filter(child => child.file_parent === item.idff);  //找到所有的子文件
      if (itemChildren.length > 0) {
        // 递归调用findChildrenArrayOfFile，传递剩余的部分数组
        itemChildren.forEach((child) => {
          this.findChildrenArrayOfFile(child, targetArray);
        });
        item.children = itemChildren;
      } else {
        item.children = [];
      }
    },



    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile1(targetArray) {
      var result = [];
      targetArray.forEach(item => {
        if (item.file_parent === 'rootfile') {
          this.findChildrenArrayOfFile(item, targetArray);
          result.push(item);
        }
      });
      return result;
      // if(targetArray.length>0){
      //   var n=targetArray.length
      //   for(var i=0; i<n;i++){
      //     this.findChildrenArrayOfFile[i]
      //   }

      //   const lefttargetArray=targetArray.filter(item=>item.parent=='rootfile')
      //   return lefttargetArray
      // }
    },

    gotoFileDetail(file,index){
      this.selectedPrivatefileIdff=file.idfpf
      this.fileReminderText=file.file_reminder
      // this.fileProcessMsgs=[]
      // this.fileChatMsgs=[]
      // this.selectedPubfileIndex=[-1,-1,-1,-1,-1,-1]
      // this.selectedPubfileIndex[file.file_level-1]=index
      // this.getChatmsgsByReceiverAndAboutwhat(file.idff,'fileprocess')
      //   .then((result)=>{
      //     this.fileProcessMsgs=result
      //   })
      // this.getChatmsgsByReceiverAndAboutwhat(file.idff,'filechat')
      //   .then((result)=>{
      //     this.fileChatMsgs=(result==undefined)?[]:result
      //   })
      
      // this.getProjectPartersFromTask(file.project)
      //   .then((result)=>{
      //     this.selectedProjectAcls=result
          
      //   })
      this.selectedFile=file
      // this.$router.push({
      //   name: 'filedetail',
      //   params: {
      //     idff:file.idff,
      //     name: file.filename,
      //     idfp:this.selectedProjectIdfp
      //   }
      // }).catch(err => {
      //   if (err.name !== 'NavigationDuplicated') {
      //     throw err
      //   }
      // })
    },

    async showPicOfMsg(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 

    async processFileMsgs(messages) {
      const processedMessages = await Promise.all(messages.map(async (msg) => {
        if (msg.type=='img') {
          const imgUrl = await this.showPicOfMsg(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
        return msg;
      }));
      return processedMessages;
    },
 

    async getProjectPartersFromTask(projectIdfp) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(projectIdfp);
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    //根据文件idff获取相应的文件程序信息记录
    async getChatmsgsByReceiverAndAboutwhat(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        // this.fileChatMsgs=result.data
        // return this.fileChatMsgs
        if (Array.isArray(response.data)) {
          // this.fileChatMsgs=result.data
          const processedData = await this.processFileMsgs(response.data);
          return processedData;
          // return response.data;
        } else {
          // alert('账号未认证，无法获取数据sdf！');
          consloe.log('无相应聊天数据！')
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    showUploadMyFileMenu(fileIdff,folder){
      if(fileIdff=='all'){
        this.selectedParentFolder=null
      } else {
        
        this.selectedParentFolder=folder
      }
      this.isShowUploadMyFileMenu=!this.isShowUploadMyFileMenu
      this.switchMenuMask()
    },
    showUploadMyFileMenuByButton(){
      if(this.selectedFile){
        this.selectedFileIdfpf=this.selectedFile.idfpf
        this.selectedParentFolder=this.selectedFile.folder
        this.isShowEditMyFileMenu=true
        this.switchMenuMask()
      }else {
        alert('请选择要编辑的文件/文件夹！')
      }
    },
    showPubFileOnly(){
      var publishedFiles=this.allMyPubFiles.filter(file=>file.isfolder==0&&file.filestatus=='已发布')
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)

      this.myPubFiles=publishedFiles
      
      publishedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },

    showAllMyOwnFiles(){
      this.myPubFiles=this.allMyPubFiles
    },
    showMyOwnFilesOfNogroup(){
      this.myPubFiles=this.allMyPubFiles.filter(item=>item.project=='')
    },
    showPubFileApproved(){
      var files=this.allMyPubFiles.filter(file=>file.isfolder==0)
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)


      const username=localStorage.getItem('username')
      var myApprovedFiles=files.filter((file)=>{
        var checkers=[]
        if(!(file.checked=='')){
          checkers=file.checked.split(';')
        }
        if(!(file.approved=='')){
          checkers.push(file.approved)
        }
        if(checkers.includes(username)){
          return file
        }
      })

      this.myPubFiles=myApprovedFiles

      myApprovedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })

    },



    
    // showCheckingNum(){
    //     var checkingNum=0
    //     var checkinger=[]
    //     var checkeder=[]
    //     const username=localStorage.getItem('username')
    //     this.allMyPubFiles.forEach((file)=>{
    //       if(!(file.checker=='')){
    //         checkinger=file.checker.split(';')
    //       }
    //       if(!(file.approver=='')){
    //         checkinger.push(file.approver)
    //       }
    //       if(!(file.checked=='')){
    //         checkeder=file.checked.split(';')
    //       }
    //       if(!(file.approved=='')){
    //         checkeder.push(file.approved)
    //       }
    //       if(checkinger.includes(username)&&!(checkeder.includes(username))) {
    //         checkingNum++
    //       }
    //     })
    //     this.checkingNum=checkingNum
    //     if(checkingNum>99){
    //       return '99+'
    //     } else if(checkingNum>0) {
    //       return checkingNum
    //     } else {
    //       return ''
    //   }
    // },
    showPubFileApproving(){
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
      var files=this.allMyPubFiles.filter(file=>(file.filestatus=='待审批'||file.filestatus=='待审核')&&file.isfolder==0)



      const username=localStorage.getItem('username')
      var myApprovingFiles=files.filter((file)=>{
        var checkers=file.checker.split(';')
        var checkeder=file.checked.split(';')
        var approver=file.approver
        var approved=file.approved
        if(checkers.includes(username)&&!checkeder.includes(username)){
          return file
        } else if(approver==username&&approved==''){
          const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
            if(allCheckersInCheckeder){
              return file
            }
        } else {

        }
      })

      this.myPubFiles=myApprovingFiles
      

      myApprovingFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },
    closeFilePubMenu(){
      this.isShowUploadMyFileMenu=!this.isShowUploadMyFileMenu
    },
    closeFileEditMenu(){
      this.isShowEditMyFileMenu=false
      // this.switchMenuMask()
    },

    closePubMyFileMenu(){
      this.isShowPubMyFileMenu=false
    },

    async addPubfile(newPubfile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/pubfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newPubfile
        });
    
        console.log('新增发布文件成功！');
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    pubMyFile(newPubFile,newMsg){

      this.selectedFile.filestatus='已发布'
      this.selectedFile.updatetime=this.datetimeConvert(new Date())
      this.updateMyFileByIdfpf(this.selectedFile)


      const fileToUpdate = this.myPubFiles.find(item => item.idfpf === this.selectedFile.idfpf);
      if (fileToUpdate) {
        fileToUpdate.filestatus = '已发布';
      }
      const username=localStorage.getItem('username')
      this.addPubfile(newPubFile)
        .then((result)=>{
          const pubfile=result
          const checkers=pubfile.checker ? pubfile.checker.split(';') : [];
          const approver=pubfile.approver

          const newAssitantMsg={
              sender:'file|approve',
              message:'老板，有以下文件需要您审批。',
              timestamp:new Date(),
              file_name: pubfile.filename,
              type:'text',
              aboutwhat:'assistantchat',
              fileidff:pubfile.idff
            }

          if(pubfile.filestatus=='已发布'){

          } else if(checkers.length>0){
   
            checkers.forEach((checker)=>{
              newAssitantMsg.receiver=checker
              this.addChatmsg(newAssitantMsg)
            })
          } else if(checkers.length==0&&approver!=''){
            newAssitantMsg.receiver=approver
            this.addChatmsg(newAssitantMsg)
          }
        })
      const fileMsg={
        receiver:newPubFile.idff,
        sender: `publisher|${username}`,
        timestamp: new Date(),
        file_name: newPubFile.filename,
        message:newMsg,
        type:'text',
        img_name:'',
        file_size:0,
        aboutwhat:'fileprocess'
      }


      this.addChatmsg(fileMsg)

      this.isShowPubMyFileMenu=false


      
    },





    closeFileAprovelMenu(){
      this.ifShowApprFileMenu=!this.ifShowApprFileMenu
      
    },
    uploadNewFile(newPubFile){
      this.uploadMyfile(newPubFile)
      this.myPubFiles.push(newPubFile)
    },


    changeReminder(){
      this.selectedFile.file_reminder=this.fileReminderText
      const updatetime=this.selectedFile.updatetime
      this.selectedFile.updatetime=this.datetimeConvert(updatetime)
      this.updateMyFileByIdfpf(this.selectedFile)
    },


    async uploadMyfile(newPubfile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/privatefiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newPubfile
        });
    
        console.log('上传文件成功！');
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    getPubfilesByUser(username){
      const token = localStorage.getItem('token')
      const data={publisher:username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/pubfilesbyuser',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.pubfiles=response.data
          }else
          alert('账号未认证，无法获取数据！')
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
      },

      //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
      getMyPubFiles(username,pubfiles){
        var myPubFiles=[]
        pubfiles.forEach((file)=>{
          var checkers=[]
          if(!(file.checker=='')){
            checkers=file.checker.split(';')
          }
          if(!(file.approver=='')){
            checkers.push(file.approver)
          }
          if(file.filestatus=='已发布'||checkers.includes(username)||file.publisher==username){
            myPubFiles.push(file)
          }
        })
        // this.myPubFiles=myPubFiles
        return myPubFiles
      },



    downloadPubfileFromServer(url,downloadUrl,newFileName) {
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          // link.setAttribute('download', url) // 设置下载文件的文件名
          link.setAttribute('download', newFileName)
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    //关于文件聊天记录的函数
    // sendFileMessage(fileMsg){
    //   const username=localStorage.getItem('username')
    //   const now=new Date()
    //   // 发送消息给socket.io服务器
    //   const newFileMessage={
    //     message: fileMsg,
    //     receiver: this.selectedFile.idff,
    //     sender: username,
    //     nickname:this.userinfo.nickname,
    //     file_name:this.selectedFile.filename,
    //     img_name:'',
    //     type: 'text',
    //     timestamp: now,
    //     file_size: 0,
    //     aboutwhat:'filechat'
    //   }
      
    //   this.addChatmsg(newFileMessage)
    //   this.socket.emit('message',newFileMessage,(response)=>{
    //     console.log('Server response:',response)
    //   })
    // },
    // async sendFileMsgOfFile(newMsg){
    //   newMsg.receiver=this.selectedPrivatefileIdff
    //   newMsg.nickname=this.altNikname
    //   // newMsg.receiver_nickname=receiver_nickname
    //   if(newMsg.type=='img'){
    //     const imgUrl=await this.showPicOfMsg(newMsg.file_name, 'showmsgimg')
    //     newMsg.imgUrl=imgUrl
    //   }
    //   this.addChatmsg(newMsg)
    //     .then((result)=>{
    //       // newMsg.id=result.id
    //       console.log('result',result)
    //       this.socket.emit('message', newMsg, (response) => {
    //         console.log('Server response:', response)
    //       })
    //     })
    // },
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        const idfp = params.idfp;
        this.selectedProjectIdfp=idfp
        const idff = params.idff

        const username=localStorage.getItem('username')
        if(idfp=='all'){
          this.getAllMyUploadFiles(username)
            .then((result)=>{
              this.pubfiles=result
              this.myPubFiles=result
            })

          // var pubfiles = [];
          // var promises = this.myProjectlist1.map(async (project) => {
          //   const result=await this.getAllMyUploadFiles(username)
          //   if(result.length>0){
          //     pubfiles=pubfiles.concat(result)
          //   }
          // });
          
          // Promise.all(promises)
          //   .then(() => {
          //     this.pubfiles = pubfiles;
          //     // this.myPubFiles=this.getMyPubFiles(username)
          //     this.myPubFiles=pubfiles

          //   })
          //   .catch((error) => {
          //     console.error('An error occurred while fetching pubfiles:', error);
          //   });
          
        }else {
          this.getMyOwnfilesByProject(idfp,username)
            .then((result)=>{
              this.pubfiles=result
              this.myPubFiles=result
              // this.getMyPubFiles(username)

            })
        }
      }
    },

    searchFilesText: function(newVal,oldVal){
      if(newVal.replace(/\s/g, '') === ''){
        this.cancelSearchFiles()
      }
    },

    // myPubFiles: {
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     if (this.myPubFiles) {
    //       var myPubFiles1 = this.myPubFiles.filter(file => file.file_level == 1);
    //       this.myPubFiles1 = myPubFiles1.sort((a, b) => new Date(b.updatetime) - new Date(a.updatetime));
    //     } else {
    //       this.myPubFiles1=[]
    //     }
    //   }
    // },


  },

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },

  created(){
    const username=localStorage.getItem('username')
    const key=`user|${username}`
    const userinfoStr=localStorage.getItem(key)
    this.userinfo=JSON.parse(userinfoStr)

    const nickname = this.userinfo.nickname;
    const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    this.altNikname = matchResult ? matchResult[0] : '';


  },

  mounted(){
    
    const username=localStorage.getItem('username')
    this.getAllMyUploadFiles(username)
      .then((result)=>{
        this.pubfiles=result
        this.allMyPubFiles=result
        this.myPubFiles=result
        this.allMyPubFileFolders=this.allMyPubFiles.filter(item=>(item.isfolder))
      })
    const usernogroup=`${username}nogroup`
    let projectAcllist=[]
    this.getMyProjectAclList(username)
      .then((result)=>{
        projectAcllist=result
        if(projectAcllist&&projectAcllist.length>0) {
          const promises=projectAcllist.map(async (projectacl)=>{
            const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
            projectacl.name=project[0].name
            projectacl.groupname=project[0].groupname
            projectacl.status=project[0].status
            projectacl.is_delete=project[0].is_delete
            projectacl.createdTime=project[0].createdTime
            projectacl.creater=project[0].creater
            projectacl.permit_type=project[0].permit_type
            projectacl.idfp=project[0].idfp
          })
          return Promise.all(promises)
        }
      })
      .then(()=>{
          this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
          var pubfiles = [];
          var promises = this.myProjectlist.map(async (project) => {
            const result=await this.getPubfilesByProject(project.idfp)
            if(result.length>0){
              pubfiles=pubfiles.concat(result)
            }
          });
          Promise.all(promises)
            .then(() => {
              // this.pubfiles = pubfiles;
              this.allMyPubFiles1=this.getMyPubFiles(username,pubfiles)
              const MyPubFileFolders=this.allMyPubFiles1.filter(file=>file.isfolder==1)

              this.allMyPubFileFolders1=MyPubFileFolders
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
        })
    
    // 创建socket.io客户端实例并连接服务器
    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
    });
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      this.fileChatMsgs.push(message)
    });
    
  },
}
</script>

<style lang="less" scoped>

  .filereminder {
    width:100%;
    height:calc(100vh - 290px);
    border:0.5px solid gray;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    margin-top:10px;
  }

  .filereminder:focus {
    outline: none;
    border:0.5px solid rgba(64, 158, 255,0.7);
  }
  


  h4 {
    height: 40px;
    margin: 0;
  }
  .tableheader {
    height: 30px;
    td {
      input {
        width: 95%;
      }
    }
    
  }

  .pubfileheader-container {
    display: inline-block;
    text-align: left;
    height: calc(95ch - 250px);
    width: 525px;
    border-right: 0.5px solid gray;
  }

  .pubfiledetail-container {
    display: inline-block;
    text-align: left;
    width:calc(100vw - 850px);
  }

  .filenametitle {
    word-wrap: break-word; 
  }

  .filecontenttable {
    border-collapse: collapse;
    td {
      border-bottom: 0.5px solid rgb(205, 205, 205);
    }
  }

  .item {
    padding:0;
    float:right;
    height:30px;
    margin-right:20px;
    border:1px solid gray;
    
    .el-button{
      padding:0;
      height:30px;
      background-color: rgb(239, 239, 239);
    }

    .el-button:hover {
      background-color: rgb(229, 229, 229);
    }
  }

  .badget {
    position:absolute;
    font-size: 12px;
    padding-top:1px;
    width:22px;
    height:14px;
    border-radius: 6px;
    left:40px;
    top:-8px;
    // background-color: rgb(245, 108, 108);
  }

  .filestatusstyle {
    width:50px;
  }

  .waitcheck:hover {
    color:rgb(82, 167, 254);
  }

  // .waitcheck:hover {
  //   color:red;
  // }

  .pubfileclicked{
    color: rgb(71, 114, 250);
  }

  @keyframes blinkAnimation {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
  }
  
  
  .blink {
    animation: blinkAnimation 1.5s infinite;
  }

  .search-input {
    border:none;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    height: 30px; 
    width: 200px; 
    padding:0px;
    text-indent: 25px;
  }

.search-input:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  

}

.search-input::placeholder {
  font-size: 16px; /* 或者你希望的任何尺寸 */
  // color: #999; /* 你也可以在这里自定义颜色 */
}

.disabled-button {
  opacity: 0.5; /* 设置按钮半透明 */
  cursor: not-allowed; /* 禁用鼠标指针 */
  color: gray;
}


</style>