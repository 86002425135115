import { render, staticRenderFns } from "./PassFriendApply.vue?vue&type=template&id=758805e5&scoped=true"
import script from "./PassFriendApply.vue?vue&type=script&lang=js"
export * from "./PassFriendApply.vue?vue&type=script&lang=js"
import style0 from "./PassFriendApply.vue?vue&type=style&index=0&id=758805e5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "758805e5",
  null
  
)

export default component.exports