import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
// import App from './RightMenu.vue'
// import App from './TestForSQL.vue'
// import App from './components/Register2.vue'
// import App from './TestOfGroup.vue'

// import App from './TestAgain.vue'
// import App from './TestDropFile.vue'
// import App from './AppForGraph.vue'
// import App from './AppTestNodeDetail.vue'
import router from './router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import echarts from 'echarts'

import * as echarts from 'echarts';
// 按需引入
// import * as echarts from 'echarts/lib/echarts';

Vue.prototype.$echarts = echarts








import store from './store/index.js'

Vue.use(Vuex)
Vue.use(ElementUI);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




export const eventBus = new Vue();


