<template>
  <div class="menu-container" v-draggable>
    <div class="menu" style="padding:0 20px;">
      <h5 style="text-align: left;line-height: 30px; font-weight: normal; font-size: 16px;
        margin:10px 0 10px 0;">
        发送给：
      </h5>
      <div style="text-align: left; display: flex; align-items: center;padding-bottom: 5px;">
        <img :src="friendAvatarUrl" :style="{ width: '40px', height: '40px', borderRadius: '5%' }"> 
        <label style="line-height: 40px; font-size: 16px;">{{ sendFriendName }}</label>
      </div>
      
      <div class="file-container">
        <table v-for="file in sendFiles" :key="file.index" style="border-top: solid rgb(205,205,205) 0.5px;width: 280px;">
          <tr>
            <td rowspan="2">
              <img>
            </td>
            <td style="text-align: left; ">
              {{ showFileName(file.name) }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left; font-size: 12px;color:rgb(158,158,158)">
              {{ `${(file.size/1000).toFixed(1)}K` }}
            </td>
          </tr>
        </table>
      </div>
      <div>
        <input placeholder="给朋友留言" style="margin:0;width: 100%; height: 25px; border-radius: 3px; border:none; 
          font-size: 14px;" v-model="sendFilesMsg">
      </div>
      <div style="text-align: center;margin-top:15px; margin-bottom: 20px;">
        <button style="margin-right: 40px;" class="menu-button" @click="sendFilesToFriend">发送</button>
        <button class="menu-button" @click="cancelSendFilesToFriend">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations} from 'vuex'
import axios from 'axios'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "SendFile",
    props: ['friendNickname','applyText','friendAvatarUrl','sendFriendName','sendFiles'],
    data() {
        return {
            projectName: "",
            projectType: "type1",
            username:'',
            saveOrNot:true,
            showSearchText:false,
            searchUser:null,
            newApplyText:'',
            sendFilesMsg:'',
        };
    },
    // props:['selectProjName'],
    computed: {
      showFileName(){
        return(fileName)=> {
          const maxLength = 16;
          let truncatedFileName = fileName;
          if (fileName.length > maxLength) {
            const extensionIndex = fileName.lastIndexOf(".");
            const fileNameWithoutExtension = fileName.substring(0, extensionIndex);
            const truncatedFileNameWithoutExtension = fileNameWithoutExtension.substring(0, 10) + "..." + fileNameWithoutExtension.slice(-1);
            truncatedFileName = truncatedFileNameWithoutExtension + fileName.substring(extensionIndex);
            return truncatedFileName
          } else {
            return fileName
          }
        }
      },      

    },
    watch :{
      searchText: function(){
        if(!this.searchText=='')
        this.showSearchText=true
      }
    },
    prop: ["menuVisible"],
    methods: {
      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),

      cancelAddFriend(){
        this.$emit('cancelAddFriend')
      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      gotoFriend(){
        const searchText=this.searchText
        this.getUserinfoBySearchText(searchText)
      },

      async getUserinfoBySearchText(text){   //今天到此位置
        try {
          const response = await axios.get('https://api2.itaskid.com' + '/my/friendships', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          // console.log('friendship', response.data);
          // this.friendships = response.data;
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
        }
        // console.log(response.data);
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }

      },

      sendFilesToFriend(){
        const fileMsg=this.sendFilesMsg
        this.$emit('sendFilesToFriend',fileMsg)
        this.sendFilesMsg=''
      },

      cancelSendFilesToFriend(){
        this.$emit('cancelSendFilesToFriend')
        this.sendFilesMsg=''
      },

      updateApplyText(event){
        this.newApplyText=event.target.value
      },

      updateFriendNickname(event){

      },

    },
    mounted(){
      this.getUsername();
      const token = localStorage.getItem('token')
      this.newApplyText=this.applyText
    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(240, 240, 240);
  width: 338px;
  max-height: 700px;
  height: auto;
  // border: 0.5px solid rgb(209,209,209);
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: move;
  .file-container {
    overflow-y: auto; 
    height: auto;
    max-height:300px;
    width: 300px;
  }
  form {
    height: auto;
  }

  label {
    line-height: 35px;
    font-size: small;
    margin-left: 16px;
  }
  
  
  input {
    width: 360px;
    height: 30px;
    margin-left: 16px;
    margin-bottom: 10px;
    border:none;
    outline: none;
    font-size: 16px;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-button {
    height: 35px;
    font-size: 14px;
    border:none;
    background-color: rgb(56, 205, 127);
    border-radius: 5%;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

  
</style>