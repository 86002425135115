<template>
  <div class="page flex-center">
    <h3 class="title">账号注册</h3>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="账户" prop="username">
        <el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="nickname">
        <el-input type="text" v-model="ruleForm.nickname" autocomplete="off" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入密码">
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请确认密码"></el-input>
      </el-form-item>
      <el-form-item label="e-mail" prop="email" v-model="ruleForm.email">
        <el-input type="text" v-model="ruleForm.email" autocomplete="off的" placeholder="请输入邮箱地址"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="telephone" v-model="ruleForm.telephone">
        <el-input type="text" v-model="ruleForm.telephone" autocomplete="off的" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="location" v-model="ruleForm.location">
        <el-input type="text" v-model="ruleForm.location" autocomplete="off的" placeholder="请输入您的地址"></el-input>
      </el-form-item>
      <el-form-item size="small">
        <el-button type="primary" @click="submitForm()">提交</el-button>
        <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState , mapMutations, mapActions} from 'vuex'
export default {
  name: 'Register',
  data() {
    var validateUsername = (rule, value, callback) => {
      const usernameRegex = /^[A-Za-z0-9_]+$/;
      if (value === '') {
        callback(new Error('请输入用户名！'));
      } else if (!usernameRegex.test(this.ruleForm.username)) {
        callback(new Error('仅可使用字母、数字及下划线！'));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
      if (value === '') {
        callback(new Error('请输入邮箱地址'));
      } else if (!emailRegex.test(this.ruleForm.email)) {
        callback(new Error('请输入正确的邮箱地址!'));
      } else {
        callback();
      }
    };
    var validateNickname = (rule, value, callback) => {
      const nicknameRegex = /^[\u4e00-\u9fff]{2,4}$/;
      if (value === '') {
        callback(new Error('请输入姓名'));
      } else if (!nicknameRegex.test(this.ruleForm.nickname)) {
        callback(new Error('请输入三个或四个汉字!'));
      } else {
        callback();
      }
    };
    var validateTelephone = (rule, value, callback) => {
      const telephoneRegex = /^1\d{10}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!telephoneRegex.test(this.ruleForm.telephone)) {
        callback(new Error('请输入11位手机号码!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: '',
        pass: '',
        checkPass: '',
        email: '',
        nickname:'',
        location:'',
        telephone:'',
      },
      rules: {
        username:[
          {validator: validateUsername, trigger: 'blur'}
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        nickname: [
          {validator: validateNickname, trigger: 'blur'}
        ],
        telephone: [
          {validator: validateTelephone, trigger: 'blur'}
        ],
      },
      };
    },
    methods: {
      ...mapActions({
        addProjectAclToSql:'addProjectAclToSql',
        addProjectToSql:'addProjectToSql'
      }),


      // initial(){
      //   axios.post({
      //     method:'post',
      //     url: 'https://api2.itaskid.com' + '/api/initial',
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: {idfp:`${this.username}nogroup`,name:'未分类',charger:this.username}
      //     }).then(responses => {
      //     // 所有请求都成功完成
      //     console.log('数据添加成功');
      //     })
      //     .catch(error => {
      //       // 处理错误
      //       console.error('数据添加失败', error);
      //     })
        
      //   axios.post({
      //     method:'post',
      //     url: 'https://api2.itaskid.com' + '/api/initial',
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: {idfp:`${this.username}week`,name:'最近七天',charger:this.username}
      //     }).then(responses => {
      //     // 所有请求都成功完成
      //       console.log('数据添加成功');
      //     })
      //     .catch(error => {
      //       // 处理错误
      //       console.error('数据添加失败', error);
      //     })
        
      //   axios.post({
      //     method:'post',
      //     url: 'https://api2.itaskid.com' + '/api/initial',
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: {idfp:`${this.username}today`,name:'今天',charger:this.username}
      //     }).then(responses => {
      //       // 所有请求都成功完成
      //       console.log('数据添加成功');
      //     })
      //     .catch(error => {
      //       // 处理错误
      //       console.error('数据添加失败', error);
      //     })
      // },

      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            axios({
              method:'post',
              url: 'https://api2.itaskid.com' + '/api/reguser',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: {
                username: this.ruleForm.username,
                password: this.ruleForm.pass,
                email: this.ruleForm.email,
                nickname:this.ruleForm.nickname,
                telephone:this.ruleForm.telephone,
                location:this.ruleForm.location
              }})
              .then((response)=>{
       
                if(!response.data.status){
                  alert('注册成功！')
                  
                  // const nogroup=`${this.ruleForm.username}nogroup`
                  // const newProjectAcl={
                  //   username: this.ruleForm.username,
                  //   project_idfp:nogroup,
                  //   permit_level: 'charger'
                  // }
                  // const newProject={
                  //   idfp: nogroup,
                  //   name:'未分类',
                  //   charger:this.ruleForm.username,
                  //   groupname:'收集箱',
                  //   status: 0,
                  //   is_delete: 0,
                  //   createdTime:'',
                  //   creater: this.ruleForm.username,
                  //   permit_type: 0,
                  // }
                  // this.addProjectAclToSql(newProjectAcl)
                  // this.addProjectToSql(newProject)

                  this.$router.push('/login')
                }else{
                  alert(response.data.message)
                }
              })
              .catch(error => {
                alert(error.response.data.message)
              })
          } else {
            // 如果表单验证不通过，则给出提示或者做出相应处理
            this.$message.error('请填写正确的表单内容')
          }
        })
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
}
</script>

<style lang="less" scoped>
  .flex-center {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
  }
  .toggle-password {
    font-size: 16px;
    cursor: pointer;
    color: #c0c4cc;
  }
  .toggle-password.is-active {
    color: #409eff;
}
</style>