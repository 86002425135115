<template>
  <div ref="menu" class="menu" :style="{top: top + 'px', left: left + 'px'}" >
    <ul style="position: relative;" v-for="(item,index) in TaskeditMenuitems" :key="index">
      <li  @click="menuItemClick(item)" @mouseover="handleMouseover(index)">
        <label>{{item.label}}</label>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState,mapMutations,mapActions} from 'vuex'
export default {
  name: 'EditFileMenu',

  props: {
    // projeditMenuitems: {
    //   type: Array,
    //   required: true
    // },
    // event: {
    //   type: Object,
    //   required: true
    // },
    parentNode: {
      type: Object,
      required: true
    },
    // showProjEditMenu: {
    //   type: Boolean,
    //   required: true
    // }
    selectedFile:{
      type: Object,
      required: true
    },
    usernogroup:{
      type:String
    },
    // shareUsers:{
    //   type: Object,
    //   required: true
    // },

    selectedTaskAcls: {
      type: Array
    },
    TaskeditMenuitems: {
      type: Array
    },
  },
  data() {
    return {
      // TaskeditMenuitems:[
      //   { id:1,label: '添加子任务' }, 
      //   {id:2,label:'指派给'},
      //   { id:3,label: '置顶' },
      //   { id:4,label: '移动到' },
      //   { id:5,label: '标签' }, 
      //   { id:6,label: '复制链接' }, 
      //   { id:7,label: '创建副本' },
      //   { id:8,label: '删除' }
      // ],
      isOpen: false,
      top: 0,
      left: 0,
      ifShowUsers:false,
      hoverOption:0,
    }
  },
  computed :{
    // showOptionMenu(){
    //   return(item,index)=>{
    //     if(item.permit_level=='charger'){
    //       return true
    //     } else if (index==0||index==4||index==5||index==6) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   }
    // }
    showAltNickname(){
      return(nickname)=>{
        if (typeof nickname !== 'undefined') {
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNickname = matchResult ? matchResult[0] : '';
          return altNickname;
        } else {
          return '';
        }
      }
    },

    // showUserList(){
    //   return()=>{
    //     if((this.selectedTask.permit_level=='charger')&&(this.hoverOption===2)){
    //       return true
    //     } else {
    //       false
    //     }
    //   }
    // }

  },
  mounted() {
    this.calculatePosition()
    // console.log('selectedTaskAclsd',this.selectedTaskAcls)

  },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),

    ...mapActions({
      getTaskAclsByTaskIdfp:'getTaskAclsByTaskIdfp',
    }),


    handleMouseover(index){
      this.hoverOption=index+1
    },
    closeUsers(){
      this.ifShowUsers=false
    },
    calculatePosition() {
      this.left=this.parentNode.clientX
      this.top=this.parentNode.clientY
    },
    menuItemClick(item) {
      this.$emit('fileeditMenuitemClick', item)
    },
    handleTaskSelected(task) {
      console.log('xx', task)
    },

    showTask(task){  
      task.name='yingyu'
    },


    appointTask(taskAcl){
      console.log(taskAcl)
    },

    updateTaskAcl(taskAcl){
      console.log('updateTaskAcl',taskAcl)
      this.$emit('updateTaskAcl',taskAcl)
    },
  },


}
</script>

<style lang="less" scoped>
.menu {
  width:75px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;

}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 6px 12px;
  line-height: 1.5;
  font-size: small;
  cursor: pointer;
}

li:hover {
  background-color: #f5f5f5;
}

.user-list {
  background: rgb(255, 255, 255);
  text-align: left;
  width: 180px;
  height: auto;
  position: absolute;
  left: 96px;
  top: 35px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  img {

    width: 20px;
    height:20px; 
    border-radius: 50%;
    margin-top:5px;
  }
  span {
    // height: 25px;
    // line-height: 25px;
    position: absolute;
    left: 25px;
    top: 0px;
    text-align: left;
    margin-left:5px;

  }
  select {
    // position: absolute;
    float: right;
    margin-top:13px;
    margin-right:5px;
    border:none;
  }
  li {
    position: relative; 
    height: 40px;
    line-height: 40px;
    padding:0;
    margin-left:10px;
  }
}

.task-altavatar {
    width: 25px;
    height: 25px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    display: inline;
  }

.search-button {
  border:none;
  background-color: rgb(255, 255, 255);
  height: 30px;
  float:right;
  border-radius: 10%;
  margin:5px 10px 0 0;
}
.search-button:hover {
  background-color: rgb(250, 250, 250);
}

.userlist-header:hover {
  background: none;
}
</style>
