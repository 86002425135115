<template>
  <div class="menu-container" v-draggable>
    <!-- <h4 style="text-align: left;margin:10px 0 10px 10px;">添加任务</h4> -->
    <div class="timepicker">
      <label for="">开始时间：{{ showDate(newStartDate) }}</label>
      <label for="" style="position:relative;height:30px;">
        <el-time-select style="width:100px;overflow: hidden; background: transparent; 
          z-index:99; position: absolute;top:-5px; left:5px; border:none;" size="small" 
          v-model="selectedtime" class="time-picker"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:45'
          }"
          placeholder="选择时间">
        </el-time-select>
      </label>
      <!-- <label for="datepicker1" style="margin-left:120px;">
        <el-date-picker 
          v-model="newStartDate" @class="time-picker"
          style="width: 0px; height: 0px; overflow: hidden; background: transparent; z-index:99;"
          type="datetime"
          size="small"
          id="datepicker1"
          placeholder="选择日期时间">
        </el-date-picker>
        结束时间：{{ showTime(newStartDate) }}
      </label> -->
      <label for="" style="margin-left:120px;">结束时间：</label>
      <label for="datepicker2" style="color:rgb(71, 114, 250);">
        <el-date-picker
          v-model="taskEndtime" @class="date-picker"
          style="width: 0px; height: 0px; overflow: hidden; background: transparent; z-index:99;"
          type="datetime"
          size="small"
          id="datepicker2"
          placeholder="选择日期时间">
        </el-date-picker>
        {{ showTime(taskEndtime)}} 
      </label>
    </div>

    <form>
      <div class="form-group">
        <input type="text" id="project-name" v-model="taskName" placeholder="    输入任务名。。。" autocomplete="off"
          style="width:475px;">
      </div>
      <div class="form-group" style="width:588px;">
        <label for="project-folder">项目组</label>
        <select id="project-folder" v-model="projectFolder" style="width: 177px; margin-right: 10px">
          <option v-for="folder in folders" :value="folder.name" :key="folder.id"
          >
            {{ folder.name }}
          </option>
        </select>
        <label for="project-folder">项&nbsp;&nbsp;&nbsp;目</label>
        <select id="project-folder" v-model="pubProjectIdfp" style="width: 177px; margin-right: 0px">
          <option v-for="project in selectedProjects" :value="project.idfp" :key="project.idfp" :label="project.name">
          </option>
        </select>
        <!-- <button type="button" @click="addFolder">新建</button> -->
      </div>
      <!-- <div class="form-group" style="width:588px;">
        <label for="project-folder">项&nbsp;&nbsp;&nbsp;目</label>
        <select id="project-folder" v-model="pubProjectIdfp" style="width: 300px; margin-right: 10px">
          <option v-for="project in selectedProjects" :value="project.idfp" :key="project.idfp" :label="project.name">
          </option>
        </select>
        <button type="button" @click="addFolder">新建</button>
      </div> -->
      <div class="form-group" style="height:150px;">
        <textarea name="" id="" style="width:475px;height:150px;" placeholder="任务描述"  v-model="newTaskSummary"></textarea>
      </div>
    </form>
    <div class="menu-buttons">
      <button type="button" @click="addNewTask" :disabled="saveOrNot">新建任务</button>
      <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        console.log(el)
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "AddProjectMenu",
    data() {
        return {
            taskName: "",
            projectFolder: null,
            pubProjectIdfp:'',
            myProjectlist: [],
            selectedProjects:[],
            username:'',
            saveOrNot:true,
            myProjects:[],
            newStarttime:'',
            selectedtime:'',
            startEndtime:'',
            taskStarttime:'',
            taskEndtime:'',
            newTaskSummary:'',
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectlist[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },
      saveOk: function(){
        if(this.taskName&&this.pubProjectIdfp) {
          return true
        }else
        return false
      },

      showDate() {
          return (dateTime) => {
            const today = new Date();
            const thisYear = today.getFullYear();
            const taskDate = new Date(dateTime);
            const taskYear = taskDate.getFullYear();
            const taskMonth = taskDate.getMonth() + 1;
            const taskDay = taskDate.getDate();
      
            const todayOfStr = this.convertDate(today);
            var taskDateOfStr = this.convertDate(dateTime);
            var lastDate;
      
            if (todayOfStr === taskDateOfStr) {
              lastDate = '今天';
            } else if (taskYear === thisYear) {
              lastDate = `${taskMonth}月${taskDay}日`;
            } else if (taskDateOfStr === '2999/12/31') {
              lastDate = '未定义';
            } else {
              lastDate = `${taskYear}-${taskMonth}-${taskDay}`;
            }
      
            return lastDate;
          }
      },

      showTime() {
        return (dateTime) => {
          const today = new Date();
          const thisYear = today.getFullYear();
          const taskDate = new Date(dateTime);
          const taskYear = taskDate.getFullYear();
          const taskMonth = taskDate.getMonth() + 1;
          const taskDay = taskDate.getDate();
          const taskHours = taskDate.getHours();
          const taskMinutes = taskDate.getMinutes();
          const taskSeconds = taskDate.getSeconds();
      
          const todayOfStr = this.convertDate(today);
          var taskDateOfStr = this.convertDate(dateTime);
          var lastDate;
      
          // 检查时间是否为00:00:00
          const isMidnight = taskHours === 0 && taskMinutes === 0 && taskSeconds === 0;
      
          if (todayOfStr === taskDateOfStr) {
            lastDate = '今天';
          } else if (taskYear === thisYear) {
            lastDate = `${taskMonth}月${taskDay}日`;
          } else if (taskDateOfStr === '2999/12/31') {
            lastDate = '未定义';
          } else {
            lastDate = `${taskYear}-${taskMonth}-${taskDay}`;
          }
      
          // 如果时间不是00:00:00，添加时间信息
          if (!isMidnight) {
            const formattedTime = `${taskHours.toString().padStart(2, '0')}:${taskMinutes.toString().padStart(2, '0')}:${taskSeconds.toString().padStart(2, '0')}`;
            lastDate += ` ${formattedTime}`;
          }
      
          return lastDate;
        }
      },

      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        this.selectedProjects=this.myProjectlist.filter(project=>project.groupname==this.projectFolder)
        console.log('this.selectedProjects',this.projectFolder,this.myProjectlist)
      },

      selectedtime: function(){
        let dateTime = this.taskStarttime + " " + this.selectedtime;
        this.newStarttime=dateTime
      },
    },
    props: ['menuVisible','taskNameFromParent','newStartDate','selectedDateTime','newTime'],
    methods: {
      ...mapMutations({
        switchMenuMask: "switchMenuMask",
        updateSelectProjName: "updateSelectProjName"
      }),
      ...mapActions({
        addProjectToSql: "addProjectToSql",
        addTaskToSql:'addTaskToSql',
        addTaskAclToSql:'addTaskAclToSql',
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },

      addNewTask(){
        const username=localStorage.getItem('username')
        const idft=uuidv4()
        const newTask={
          idft:idft,
          name:this.taskName,
          duration: null,
          starttime: this.newStarttime,
          endtime:this.taskEndtime,
          level:1,
          parent: 'roottask',
          project:this.pubProjectIdfp,
          haschild:0,
          done:0,
          expanded:1,
          permit_level:'charger',
          creater:username,
          sharedrange:'project',
          isshared:1,
          record: this.newTaskSummary
        }
        this.addTaskToSql(newTask)  
        const newTaskAcl={permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            // this.myTaskAcllist.push(res)
          })
        
        this.$emit('addLocalTask',newTask)
        
        
      },

      //把标准时间转换成普通的字符串时间
      convertDate(targetDate){
        const date = new Date(targetDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate
      },



      resetForm() {
          this.taskName = "";
          this.projectFolder = null;
          this.$emit("closeMenu");
          this.switchMenuMask();

      },


    },

    created(){
      
      
    },
    mounted(){
      this.selectedtime=this.newTime==''?'00:00':this.newTime
      console.log('this.selectedtime',this.selectedtime)
      // 正则表达式用于检测字符串是否符合 "YYYY/MM/DD HH:MM" 格式
      const dateTimePattern = /^(\d{4}\/\d{1,2}\/\d{1,2})\s(\d{2}:\d{2})$/;

      // 使用正则表达式匹配传入的日期字符串
      // const match = this.newStartDate.match(dateTimePattern);
      // console.log('mounted',this.newStartDate)
      // if (match) {
      //     // 如果匹配成功，表示字符串包含时间，提取时间部分
      //     this.selectedTime = match[2];
      //     console.log('this.selectedTime',this.selectedTime)

      // } else {
      //     // 如果没有匹配到时间，设置默认时间 "00:00"
      //     this.selectedTime = "00:00";
      //     console.log('this.selectedTime',this.selectedTime)
      // }
      
      this.taskEndtime=this.newStartDate
      this.taskStarttime=this.newStartDate
      this.taskName=this.taskNameFromParent
      this.username=localStorage.getItem('username')
      const usernogroup=`${this.username}nogroup`
      this.pubProjectIdfp=usernogroup
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
        headers: {
          Authorization: token
        },
      })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.myProjectlist=response.data.filter(item => 
            item.charger==this.username)
            this.selectedProjects=this.myProjectlist
          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error);
        })

    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.timepicker {
  margin:5px 0px 0px 20px;
  text-align: left;
}
.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 520px !important;
  height: 320px;
  border: 1px solid gray;
  z-index: 1;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 405px;
    height: 30px;
    padding: 0;
  }
  .form-group {
    width: 450px;
    margin: 10px 0;
    height: 30px;
    padding-left: 20px;
    text-align: left;

    input {
      border: 0.5px solid gray;
    }

    select {
      border: 0.5px solid gray;
    }

    input:focus {
      outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
      border:none;
    }

    select:focus {
      outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
      border:none;
    }
  }
  .menu-buttons {
    margin-right: 20px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 10px;
    width: 340px;
    height: 30px;
  }
}

textarea:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  border:none;
}

textarea {
  border: 0.5px solid gray;
  background-color: rgba(255, 255, 255, 0.9);
  
}




  
</style>