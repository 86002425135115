<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h4 style="text-align: center; margin:13px 0px;">提交任务</h4>
      <form>
        <div class="form-group">
          <table>
            <tr>
              <td style="width:85px;">任务名称：</td>
              <td>
                  {{ selectedTask.name }}
              </td>
            </tr>
          </table>
        </div>
      </form>
      <form>
        <div class="form-group">
          <table>
            <tr>
              <td style="width:85px;">
                <input type="file" @change="selectTaskFilesFromMenu($event)" multiple>
              </td>
            </tr>
          </table>
        </div>
      </form>
      <div class="form-group">
        <table>
          <tr>
            <td style="width:85px;text-align:left;">  
              完成说明:
            </td>
          </tr>
          <tr>
            <td>
              <textarea name="" id="" cols="30" rows="6" placeholder="请输入审批意见。。。"  class="borderstyle"
                style="width: 455px;margin: 0 5px;" v-model="taskSummitDescrip">
              </textarea>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="menu-buttons">
                <button type="button" @click="summitTaskFromMenu">提交</button>
                <button type="button" @click="closeSummitTaskMenu" style="margin-left: 10px">取消</button>
              </div>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "SummitTaskMenu",
    data() {
        return {

            summitingTask:null,
            selectedTaskFilesFromMenu:[],
            taskSummitDescrip:'任务已完成',
        };
    },
    computed: {
      // folders: function(){
      //   const myfolder=[]
      //   const myfolderobj={}
      //   var Id=1
      //   for(var i=0; i<this.myProjectlist.length;i++) {
      //     if(!myfolderobj[this.myProjectlist[i].groupname]) {
      //       myfolderobj[this.myProjectlist[i].groupname]=i+1
      //       const folder={id: Id, name: this.myProjectlist[i].groupname}
      //       myfolder.push(folder)
      //       Id++
      //     }
      //   }
      //   return myfolder
      // },



    },
    watch :{
      // saveOk: function(){
      //   this.saveOrNot=!this.saveOk
      // },
    },
    props: ['menuVisible','selectedTask','selectedProjectAcls'],
    methods: {

      selectTaskFilesFromMenu(e){
        this.selectedTaskFilesFromMenu = e.target.files;
      },


    async uploadTaskfile(file) {
      try {
        if (!file) {
          alert("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        const encodedFileName = encodeURIComponent(file.name);
        formData.append('file', file, encodedFileName);
        const response = await axios.post(
          'https://api2.itaskid.com' + '/my/uploadtaskfile',
          formData,
          {
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data'
            },
            data:formData
          }
        );
        // console.log(response.data.data.url)
        return response.data.data.url;
      } catch (error) {
        console.log(error);
      }
    },


    async addTaskFileData(newTaskFile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/taskfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newTaskFile
        });
    
        console.log('添加任务文件数据成功！',response.data);
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },


    async summitTaskFromMenu(){
      const files=this.selectedTaskFilesFromMenu;
      const username=localStorage.getItem('username');
      if (files && files.length > 0) {
        for (const file of files) { // 使用 `for...of` 循环
          const fileSizeInKB = file ? ((file.size / 1024).toFixed(2) + "KB") : '';
          const newTaskFile = {
            idftf: uuidv4(),
            filename: file.name,
            size: fileSizeInKB,
            task_idft: this.selectedTask.idft,
            uploader: username,
            updatetime: '2024/5/30',
            filestatus: '待确认',
          };
          try {
            const url = await this.uploadTaskfile(file); // 使用 await
    
            newTaskFile.url=url
            // this.$emit('addLocalTaskFileData',newTaskFile)
            this.addTaskFileData(newTaskFile)
            this.$emit('addLocalTaskFileData',newTaskFile)
            
            this.selectedTaskFilesFromMenu=[]
          } catch (error) {
            console.error('File upload failed', error);
          }
        }
      }


      const taskProcessMsgs={
        idftsm:uuidv4(),
        sender:username,
        task_idft:this.selectedTask.idft,
        message:this.taskSummitDescrip,
        timestamp:new Date().toLocaleString()
      }
            
      const myProjectAcl=this.selectedProjectAcls.find((item)=>{
        if(item.username==username){
          return item
        }
      })
      if(myProjectAcl.permit_level=='charger'){
        taskProcessMsgs.checkoption='checked'
        this.updateTaskstatusOnSql(this.selectedTask.idft,'checked')
      } else  {
        taskProcessMsgs.checkoption='checking'
        this.updateTaskstatusOnSql(this.selectedTask.idft,'checking')
      }

      this.addTaskSummitMsg(taskProcessMsgs)

      this.$emit('addLocalTaskSummitMsg',taskProcessMsgs)
       
      this.$emit('closeSummitTaskMenu')
    },


    updateTaskstatusOnSql(idft,statusStr) {
      const token = localStorage.getItem('token')
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/updatetaskstatusbyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {idft:idft,taskstatus:statusStr}
      })
        .then((response) => {
          console.log('修改任务状态成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },

    async addTaskSummitMsg(msg){
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/addtasksummitmsg',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: msg
        });
    
        console.log('添加任务提交信息成功！');
        // return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }

    },

    closeSummitTaskMenu(){
 
      this.selectedTaskFilesFromMenu=[]
      this.$emit('closeSummitTaskMenu')
    },



    },
    mounted(){
      this.summitingTask=this.selectedTask
      console.log('s',this.selectedTask)
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 515px;
  height: 310px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  // input {
  //   width: 405px;
  //   height: 30px;
  //   padding: 0;
  // }
  .form-group {
     width: 600px;
     margin: 10px 0;
     height: 30px;
     padding-left: 20px;

     input {
      height: 30px;
     }


  }
  .menu-buttons {
    margin-right: 5px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.borderstyle {
  border:0.5px solid rgb(207, 210, 216);
  border-radius: 3px;
}

.borderstyle:focus {
  outline: 0.5px solid rgb(111, 181, 254);
  
}
</style>