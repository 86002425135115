<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h3 style="text-align: center;">发布任务</h3>
      <form>
        <div class="form-group">
          <div class="taskinfo-container">
            <label>任务名称</label>
            <input type="text" id="task-name" v-model="pubTaskName" placeholder="任务名称" autocomplete="off" style="width: 300px;"
              class="outlinetype">
          </div>
          <div class="taskinfo-container">
            <div class="block">
              <label>开始时间</label>
              <el-date-picker
                v-model="starttime"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </div>
          <div class="taskinfo-container">
            <div class="block">
              <label>完成时间</label>
              <el-date-picker
                v-model="endtime"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </div>
          <!-- <div v-if="showFileName"  class="taskinfo-container">
            <label>任务文件</label>
            <input type="text" id="task-name" v-model="pubTaskFileName" autocomplete="off" style="width: 300px;">
          </div> -->
          <!-- <div class="taskinfo-container">
            <input type="file" @change="handlePubFileUpload($event)" ref="fileInput"/>
          </div> -->
          <div class="taskinfo-container">
            <label for="project-folder">项&nbsp;&nbsp;目&nbsp;&nbsp;组</label>
            <select id="project-folder" v-model="projectFolder" style="width: 230px; margin: 0 10px 0 0">
              <option v-for="folder in folders" :key="folder.id"
              >
                {{ folder.name }}
              </option>
            </select>
            <button type="button" @click="addFolder" style="width: 60px;">新建</button>
          </div>
          <div class="taskinfo-container">
            <label for="project">项&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目</label>
            <select id="project" v-model="pubProject" style="width: 230px; margin: 0 10px 0 2px; ">
              <option v-for="project in pubProjects" :key="project.id" :value="project.idfp">
                {{ project.name }}
              </option>
            </select>
            <button type="button" @click="addFolder" style="width: 60px;">新建</button>
          </div>
          <!-- <div class="taskinfo-container">
            <label for="project-folder">发&nbsp;&nbsp;布&nbsp;&nbsp;给</label>
            <select id="project-folder" v-model="projectFolder" style="width: 230px; margin: 0 10px 0 0; ">
              <option v-for="folder in folders"  :key="folder.id">
                {{ folder.name }}
              </option>
            </select>
          </div> -->
        </div>
      </form>
      <br>
      <div>
        <textarea name="" id="" cols="30" rows="10" placeholder="请输入任务简述。。。"  class="outlinetype"
          style="width: calc(100% - 15px);margin: 0 5px;border: none;" v-model="newPubTaskSummary">
        </textarea>
      </div>
      <div class="menu-buttons">
        <!-- <button type="button" @click="pubNewFile" :disabled="saveOrNot">发布</button> -->
        <button type="button" @click="pubNewTask" class="menu-button">发布</button>
        <button type="button" @click="resetForm" style="margin-left: 10px" class="menu-button">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState,mapMutations,mapActions} from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "PubTaskMenu",
    data() {
        return {
            pubTaskName: '',
            pubTaskFileName:'',
            projectName: "",
            projectType: "type1",
            projectFolder: null,
            pubProject:'',
            pubProjects:'',
            pubFileFolder:'',
            myProjectlist: [],
            projects:'',
            username:'',
            saveOrNot:true,
            newPubFile:null,
            newPubTaskSummary:'',
            uploadFile: {},
            showFileName:false,
            starttime:'',
            endtime:'',
        }
    },
    computed: {

      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectlist[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },
      pubProjects1:function(){
        if(this.projectFolder==null){
          return this.myProjectlist
        } else {
          const projects=this.myProjectlist.filter(project=>project.groupname==this.pubFileFolder)
          return projects
        }
     },
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        this.pubProjects=this.myProjectlist.filter(project=>project.groupname==this.projectFolder)
      }
    },
    props: ['selectedMessage'],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapActions({
        addTaskToSql:'addTaskToSql',
        addTaskAclToSql:'addTaskAclToSql',
      }),
      ...mapMutations({
          switchMenuMask: "switchMenuMask",
          updateSelectProjName: "updateSelectProjName"
      }),

      handleFileName(fileName){
        const fileName1=fileName.substring(fileName.indexOf("-") + 1);
        const maxLength = 20;
        let truncatedFileName = fileName1;
        if (fileName1.length > maxLength) {
          const extensionIndex = fileName1.lastIndexOf(".");
          const fileNameWithoutExtension = fileName1.substring(0, extensionIndex);
          const truncatedFileNameWithoutExtension = fileNameWithoutExtension.substring(0, 20) + "..." + fileNameWithoutExtension.slice(-1);
          truncatedFileName = truncatedFileNameWithoutExtension + fileName1.substring(extensionIndex);
          return truncatedFileName
        } else {
          return fileName1
        }
      },
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },

      handlePubFileUpload(e){
        this.showFileName=true
        let file = e.target.files[0];
        this.uploadFile= e.target.files[0]
        const username=localStorage.getItem('username')
        
        const idff=uuidv4()
        const fileSizeInKB = (file.size / 1024).toFixed(2) + " KB"
        this.pubTaskFileName = file.name
        this.newPubFile={idff:idff, project:this.pubProject,folder:this.projectFolder,name:this.pubTaskName,publisher:username,
          approver: '',pubtime:'', size: fileSizeInKB, downloadtimes:0,summary:this.newPubTaskSummary
        } 
        

        
      },
      // async pubNewTask(){
      //   const username=localStorage.getItem('username')
      //   const pubtime=this.convertDateFormat(new Date())
      //   const fileExtension = this.pubTaskName.split('.').pop()
      //   this.newPubFile.pubtime=pubtime
      //   this.newPubFile.name=this.pubTaskName      
      //   this.newPubFile.summary=this.newPubFileSummary
      //   this.newPubFile.type=fileExtension
      //   this.newPubFile.publisher=username
      //   this.newPubFile.downloadtimes=0
      //   this.newPubFile.subscriber={}
      //   this.newPubFile.project=this.pubProject
      //   this.newPubFile.folder=this.projectFolder
      //   const file=this.$refs.fileInput.files[0]
      //   const xx=await this.handleFileUpload1(file)
      //   this.newPubFile.url=xx
      //   this.$emit("pubNewTask",this.newPubFile)
      // },

      pubNewTask(){
        // const starttime=new Date()
        const idft=uuidv4()
        const newTask={
          idft:idft,
          name:this.pubTaskName,
          level:1,
          charger: this.username,
          parent:'roottask',
          starttime: this.starttime==''?'2999/12/31':this.starttime,
          endtime: this.endtime==''?'2999/12/31':this.endtime,
          pretask:'',
          haschild:null, 
          expanded:1,
          done:0, 
          project:this.pubProject,
          permit_level:'charger',
          creater: this.username,
          record:this.newPubTaskSummary


        }
        console.log('starttime',newTask.starttime,newTask.endtime)
        // this.addTaskSql(newTask)
        this.addTaskToSql(newTask)
        const newTaskAcl={permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            // this.myTaskAcllist.push(res)
          })
  
        this.$emit("closePubTaskMenu")
      },

      handleFileUpload(file) {
        if (!file) {
          console.log("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        formData.append('file', file);
    
        axios.post('https://api2.itaskid.com' + '/my/uploadpubfile', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          // console.log(response.data)
          return response.data.url
        })
        .catch(error => {
          console.log(error);
        });
      },

      async handleFileUpload1(file) {
        try {
          if (!file) {
            console.log("请选择要上传的文件");
            return;
          }
          const token = localStorage.getItem('token')
          const formData = new FormData();

          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadpubfile',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data.url;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },


      async saveProject() {
        try {
          // 以下是保存新增的项目
          const token = localStorage.getItem('token')
          if (!this.username) {
            // 如果用户未登录，跳转到登录页面
            this.$router.push('/login');
            return;
          }
          const projectinfo = {
            name: this.projectName,
            groupname: this.projectFolder.name,
            charger: this.username,
          };
          // 发送 POST 请求
          const response = await axios({
            method: 'post',
            url: 'https://api2.itaskid.com' + '/my/addproject',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: projectinfo
          });
      
          const newProj = response.data;
          this.$emit('updateLocalItems', newProj);
          this.updateSelectProjName(newProj)
          if (!response.data.status) {
            // 根据需求进行逻辑处理
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          alert(error.response.data.message);
        }
        
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },
      resetForm() {
          // this.projectName = "";
          // this.projectType = "type1";
          // this.projectFolder = null;
          this.$emit("closePubTaskMenu");
          // this.switchMenuMask();
      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      convertDateFormat(data){
        const date = new Date(data)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        let hour = date.getHours()
        const minute = date.getMinutes()
        // 将小时转换为12小时制
        // let period = "am"
        // if (hour >= 12) {
        //   // hour -= 12
        //   period = "pm"
        // }
        // const formattedDate = `${year}/${month}/${day} ${hour}:${minute}${period}`
        const formattedDate = `${year}/${month}/${day} ${hour}:${minute}`
        return formattedDate
      },

      //与服务器相关的处理函数

      addTaskSql(newTask){
        const token = localStorage.getItem('token')
        const idft=uuidv4()
        newTask.idft=(newTask.idft==undefined)?idft:newTask.idft
        newTask.starttime=this.convertDate(newTask.starttime)
        newTask.endtime=this.convertDate(newTask.endtime)
        axios({
          method:'post',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newTask
          })
          .then((response)=>{
            
            console.log('数据库新增的任务',response.data)
            console.log('添加任务成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
          // const idfc=uuidv4()
          // const text=this.newPubTaskSummary?this.newPubTaskSummary:'请输入文字、图片或拖入文件。。'
          // const firstContent={idfc:idfc,taskid: idft, content: text}
          // this.addTaskContentToSql(firstContent)
  
      },

      //把标准时间转换成普通的字符串时间
      convertDate(targetDate){
        const date = new Date(targetDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate
        
      },


      // addTaskContentToSql(newContent){
      //   const token = localStorage.getItem('token')
      //   axios({
      //     method:'post',
      //     url: 'https://api2.itaskid.com' + '/my/taskcontents',
      //     headers: {
      //       Authorization: token,
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: newContent
      //     })
      //     .then((response)=>{
            
      //       console.log('数据库新增的任务内容',response.data)
      //       console.log('添加任务内容成功')
      //     })
      //     .catch(error => {
      //       alert(error?.response?.data?.message || 'An error occurred.')
      //     })
      // },
    },
    mounted(){
      this.username=localStorage.getItem('username')
      const pubfileName=this.handleFileName(this.selectedMessage.file_name)
      this.pubTaskName=this.selectedMessage.message?this.selectedMessage.message:pubfileName
      this.getUsername();
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
        headers: {
          Authorization: token
        },
      })
        .then((response)=>{
          if (Array.isArray(response.data)){
            const usernogroup=`${this.username}nogroup`
            const usertoday=`${this.username}today`
            const userweek=`${this.username}week`
            // this.myProjectlist=response.data.filter(item => 
            // item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))
            this.myProjectlist=response.data.filter(item=>item.charger==this.username)
            console.log('this.myProjectlist',this.myProjectlist)
            this.pubProjects=this.myProjectlist

          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error);
        })
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 450px;
  height: auto;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #task-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 405px;
    height: 30px;
    padding: 0;
  }
  .form-group {
  width: 400px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  height: auto;
  }
  .menu-buttons {
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }

  .menu-button {
    height: 35px;
    font-size: 14px;
    border:none;
    background-color: rgb(56, 205, 127);
    border-radius: 5%;
  }
}

.taskinfo-container {
  margin-top:10px;
  width: 400px;
  text-align: left;
  label {
    margin-right: 20px;
    font-weight: 500;
  }
  .el-input__inner {
    height: 35px;
    line-height: 35px;
  }

}

i .el-input_icon .el-icon-time {
    margin-top: 0;
    height: 35px;
    line-height: 35px;
    text-align: center;
  }

.outlinetype:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7);
  border:none;
}
  
</style>