<template>
  <div class="menu-container" v-draggable >
      <div class="menu">
      <h3 style="text-align: center;">时间刻度</h3>
      <div style="margin-left:50px;">
        <div style="margin-top:10px;">
          <label for="" style="margin-right: 20px;">开始时间</label>
          <!-- <input type="text" style="margin-left:20px;" v-model="startDay"> -->
          <el-date-picker
            v-model="startDay"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </div>
        <div style="margin-top:10px;">
          <label for="" style="margin-right: 20px;">结束时间</label>
          <!-- <input type="text"  style="margin-left:20px;" v-model="endDay"> -->
          <el-date-picker
            v-model="endDay"
            type="date"
            placeholder="选择日期">
          </el-date-picker>

        </div>
        <div style="margin-top:10px;">
          <label for="">间隔时间</label>
          <input type="number"  style="margin-left:20px; width:50px" v-model.number="interval">
          <label for="">天</label>
        </div>
      </div>

      <div class="menu-buttons">
        <button type="button" @click="resetTimeRuler" :disabled="saveOrNot">保存</button>
        <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import {mapMutations} from 'vuex'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "EditTimeRuler",
    props: [],
    data() {
        return {
            
            startDay: '',
            endDay: '',
            interval:1,
            saveOrNot:true
        };
    },
    // props:['selectProjName'],
    computed: {
      saveOk: function(){
        if(this.startDay&&this.endDay) {
          return true
        }else
        return false
      }


      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      }

    },
    prop: [],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),
      resetTimeRuler() {
        this.$emit("closeEditProj",this.startDay,this.endDay,this.interval);
      },
      resetForm() {
        this.$emit("cancelEditPro");
      },

    },
    mounted(){



      

    },

    created(){
    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 450px;
  height: 270px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 200px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  input {
    width: 200px;
    height: 30px;
    padding: 0;
  }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-buttons {
    margin: 20px 50px 0 0;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.el-input__icon {
  // // margin-bottom:10px;
  // line-height: 4px;
  height:65%;
}


  
</style>