<template>
  <div ref="menu" class="menu" :style="{top: top + 'px', left: left + 'px'}">
    <ul>
      <li v-for="(item, index) in msgEditMenuitems" :key="index" @click="menuItemClick(item)">
        {{item.label}}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations} from 'vuex'
export default {
  name: 'EditMsgMenu',
  props: {
    parentNode: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      msgEditMenuitems:[
        { id:1,label: '复制' }, 
        // { id:2,label: '转发' },
        { id:3,label: '发布任务' },
        { id:4,label: '发布文件' }, 
        // { id:5,label: '跳转任务' }, 
        { id:6,label: '删除' },
      ],
      isOpen: false,
      top: 0,
      left: 0
    }
  },
  mounted() {
    this.calculatePosition()

  },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask'
    }),
    calculatePosition() {
      this.left=this.parentNode.clientX
      this.top=this.parentNode.clientY
    },
    menuItemClick(item) {
      this.$emit('msgEditMenuitemClick', item)
    },
    handleTaskSelected(task) {
      console.log('xx', task)
    },

    showTask(task){  
      task.name='yingyu'
    }
  },


}
</script>

<style scoped>
.menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(200, 200, 200, 0.1);
  z-index: 999;
  text-align: center;

}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 6px 12px;
  line-height: 1.5;
  font-size: small;
  cursor: pointer;
}

li:hover {
  background-color: #f5f5f5;
}
</style>
