
<template>
  <div style="text-align: left;">
    <div class="pubfileheader-container" style="float: left;">
      <div>
        <h4 style="display: inline-block;">&nbsp;&nbsp;我的文件列表</h4>
        <!-- <button>表</button>
        <button>树</button> -->
        <button style="float: right; width: 100px;margin-right: 5px" @click="showPubFileMenu">发布+</button>
        <!-- 弹出菜单的代码 -->
        <PubFileMenu  v-if="isShowPubFileMenu" @closeFilePubMenu="closeFilePubMenu" @pubNewFile="pubNewFile"></PubFileMenu>
        <!-- 弹出菜单的代码 -->
      </div>
      <table style="margin-left: 10px">
        <tr class="tableheader">
          <td style="width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">文件名称</td>
          <td style="min-width: 70px;">发布人</td>
          <td style="min-width: 70px;">审批人</td>
          <td style="min-width: 120px;">发布时间</td>
        </tr>
        <tr v-for="file in pubfiles" :key="file.idff">
          <td @click="gotoFileDetail(file)">
            {{ file.name }}
            <button style="width: 20px; background: none; border: none;padding: 0;" @click="test">
              <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
            </button>
          </td>
          <td>{{ file.publisher }}</td>
          <td>{{ file.approver }}</td>
          <td>{{ file.pubtime }}</td> 
        </tr>
      </table>
    </div>
    <div class="pubfiledetail-container">
      <div style="float: left; width: 100%;">
        <h4>&nbsp;&nbsp;文件详情</h4>
        <div style="border-bottom: 1px solid gray;margin-left:10px;">
          <label for="">名称:</label>
          <label for="" >{{ filename }}</label> <br>
          <label for="">类型：</label>
          <label for="">{{ filetype }}</label>
          <label for="" style="margin-left: 20px;">文件大小：</label>
          <label for="">{{ filesize }}</label>
          <label for="" style="margin-left: 20px;">下载次数：</label>
          <label for="">{{filedownloadtimes}}</label> <br>
          <label for="">文件分类：</label>
          <label for="">{{ fileFolder }}</label>
          <label for="" style="margin-left: 20px">项目：</label>
          <label for="">{{ fileProject }}</label>
        </div>
        <div style="margin-left: 10px;">
          <label for="" style="font-size: 14px;">{{ filepublisher }}&nbsp;&nbsp;{{ filepubtime }}</label>
          <p style="font-size: 14px; font-weight: 400; margin: 0 0 0 10px;">{{ filesummary }}</p>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import PubFileMenu from '../menu/file/PubFileMenu.vue'
import { mapState , mapMutations} from 'vuex'
export default {
  name: 'FileMyself',
  components: {
    PubFileMenu,
  },
  data() {
    return {
      publisher: '',
      fileDetails: '',
      pubfiles: [
        {idff:1, project:'西丽湖',fold:'占林',name:'报告雷克萨打开链接是的理论框架撒的法律框架',publisher:'王洪文的',approver:'孙大通的',
          pubtime:'2023/7/18 15:20', size: 1022, downloadtimes:100,type:'pdf',summary:'这只是一个初稿，请大家提提意见'},
        {idff:2,project:'西丽湖',fold:'占林',name:'报告雷克萨打开链接是的理论框架撒的法律框架',publisher:'王洪文',approver:'孙大通',
          pubtime:'2023/7/18 15:20', size: 1022, downloadtimes:100,type:'pdf',summary:'第二版，意见已修改'},
        {idff:3,project:'西丽湖',fold:'占林',name:'报告雷克萨打开链接是的理论框架撒的法律框架',publisher:'王洪文',approver:'孙大通',
          pubtime:'2023/7/18 15:20', size: 1022, downloadtimes:100,type:'pdf',summary:'终版，后续意见以变更形式解决'},
      ],
      filename: 'dssdfsdf',
      filetype: '',
      filesize: '',
      filedownloadtimes:0,
      filesummary:'',
      filepublisher:'',
      filepubtime: '',
      fileProject: '',
      fileFolder: '',
      pubfile:{project:'',fold:'',name:'',publisher:'王洪文的',approver:'',pubtime:'', size: 0, downloadtimes:0,type:'',
               summary:'',downloadtimes:0, },
      isShowPubFileMenu: false,
    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),    
    test(){
      console.log('s')
    },
    gotoFileDetail(file){
      console.log('test')
      this.filename=file.name
      this.filetype=file.type
      this.filesize=file.size
      this.filedownloadtimes=file.downloadtimes
      this.filesummary=file.summary
      this.filepublisher=file.publisher
      this.filepubtime=file.pubtime
      this.fileProject=file.project
      this.fileFolder=file.folder
    },
    showPubFileMenu(){
      this.isShowPubFileMenu=!this.isShowPubFileMenu
      this.switchMenuMask()
    },
    closeFilePubMenu(){
      this.isShowPubFileMenu=!this.isShowPubFileMenu
    },
    pubNewFile(newPubFile){
      console.log('sdf',newPubFile)
      this.pubfiles.push(newPubFile)
    },
    // publishFile() {
    //   const newFile = {
    //     id: Date.now(),
    //     publisher: this.publisher,
    //     publishTime: new Date().toLocaleString(),
    //     details: this.fileDetails
    //   };
    //   this.files.push(newFile);

    //   // 发布文件后通过推送通知被发布对象
    //   this.notifySubscribers(newFile);

    //   // 清空表单
    //   this.publisher = '';
    //   this.fileDetails = '';
    // },
    // downloadFile(file) {
    //   // 下载文件的逻辑
    //   console.log('Downloading file:', file);
    // },
    // notifySubscribers(file) {
    //   // 通过推送通知被发布对象
    //   // 可以添加逻辑来查找订阅了该团队的所有人或团队成员，然后向他们发送推送通知
    //   console.log('Notifying subscribers about the new file:', file);
    // }
  }
}
</script>

<style lang="less" scoped>
  h4 {
    height: 40px;
    margin: 0;
  }
  .tableheader {
    height: 30px;
    td {
      // border-right: 1px gray solid;
      input {
        width: 95%;
      }
    }
  }

  .pubfileheader-container {
    display: inline-block;
    text-align: left;
    height: 95ch;
    border-right: 1px solid gray;
  }

  .pubfiledetail-container {
    display: inline-block;
    text-align: left;
  }
</style>