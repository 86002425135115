<template>
  <el-container class="home-container" >
    <el-header style="position:relative;">
      <div>
        <!-- <div v-if="userinfo.avatar=='null'">dsdf</div> -->
        <div class="useravatar-container" @click="triggerFileUpload">
          <img :src="myAvatarUrl" alt="" v-if="myAvatarUrl!==undefined">
          <div v-if="myAvatarUrl==undefined" class="alt-avatar">
            {{ altNikname }}
          </div>
          <i class="el-icon-camera"></i>
          <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" accept="image/*">
        </div>
        <div style="width: 600px;margin: auto; display: inline;">
          <el-input placeholder="请输入内容" v-model="input3" class="input-with-select" style="width:600px;">
            <el-select v-model="select" slot="prepend"   style="width: 100px;">
              <el-option label="任务" value="1"></el-option>
              <el-option label="信息" value="2"></el-option>
              <el-option label="文件" value="3"></el-option>
              <el-option label="表单" value="5"></el-option>
              <el-option label="通讯" value="4"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>


      
    </el-header>
    <el-container style="background-color: rgba(35,44,73,0.5);">
      <el-aside style="width: 60px; background-color: rgb(90, 96, 119);" >
        <div style="height: 10px;"></div>
        <div class="box" v-for="(tag,index) in tags" :key="index" style="height: 60px;" @click="showBox(index)">
          <i :class="tag.class" :style="{ color: tag.color }"></i><br>
          <router-link :to="tag.to" :style="{ color: tag.color }">{{tag.name}}</router-link>
        </div>
      </el-aside>
      <el-main style="padding:8px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
  </template>
  
  <script>
  import axios from 'axios'
  import MyAside from './MyAside.vue'
  import MyHeader from './MyHeader.vue'

  
  export default {
    name: 'Home',
    data() {
      return {
        editable: true,
        tags: [
          {class:'el-icon-chat-dot-square',to:'/home/friendshipgroup',name: '信息',color: 'rgb(255,255,255)'},
          {class:'el-icon-circle-check',to:'/home/taskgroup',name: '任务',color: 'rgb(255,255,255)'},
          {class:'el-icon-date',to:'/home/calendar',name: '日历',color: 'rgb(255,255,255)'},
          {class:'el-icon-edit-outline',to:'/home/help',name: '计划',color: 'rgb(255,255,255)'},
          
          {class:'el-icon-suitcase',to:'/home/filegroup',name: '文件',color: 'rgb(255,255,255)'},
          // {class:'el-icon-suitcase',to:'/home/filegroup',name: '表单',color: 'rgb(255,255,255)'},
          {class:'el-icon-coin',to:'/home/plan', name: '资源',color: 'rgb(255,255,255)'},
          {class:'el-icon-user',to:'/home/connect',name: '通讯',color: 'rgb(255,255,255)'},
          // {class:'el-icon-setting',to:'/home/setting',name: '设置',color: 'rgb(255,255,255)'},
          // {class:'el-icon-setting',to:'/home/user',name: '用户',color: 'rgb(255,255,255)'},
        ],
        activeTagIndex: -1,
        input1: '',
        input2: '',
        input3: '',
        select: '',
        myAvatarUrl:'',
        userinfo:null,
        altNikname:'',
      }
    },
    components: {
      MyAside,
      MyHeader,
    },

    methods: {
      showBox(index){
        for (let i = 0; i < this.tags.length; i++) {
          if (i === index) {
            this.tags[i].color = 'rgb(71, 114, 250)';
            this.select=this.tags[i].name
          } else {
            this.tags[i].color = 'rgb(255,255,255)';
          }
        }
      },

      async showPic(imgname,url) {
        if(imgname==null||imgname=='null'||imgname==''){
          return undefined
          
        } else {
          const token = localStorage.getItem('token');
          const data = { url: imgname };
          try {
            const response = await axios({
              method: 'get',
              responseType: 'arraybuffer',
              url: 'https://api1.itaskid.com' + `/my/${url}`,
              headers: {
                Authorization: token,
              },
              params: data,
            });
            const contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });
            const blobUrl = window.URL.createObjectURL(blob);
            return blobUrl;
          } catch (error) {
            console.log(error);
          }
        }
      }, 

      //更换头像函数
      triggerFileUpload(){
        this.$refs.fileInput.click();
      },

      // 处理文件选择
      handleFileChange(event) {
        const file = event.target.files[0];
        if (!file) {
            return; // 没有文件被选中
        }

        // // 检查文件大小
        // const maxSize = 2 * 1024 * 1024; // 2M
        // if (file.size > maxSize) {
        //     alert("图片大小超过2M限制，请选择较小的文件。");
        //     return; // 终止操作
        // }

        // 如果文件类型和大小都合适，继续处理文件（例如上传）
        this.uploadMyAvatar(file)
          .then((result)=>{
            const avatar=result.filename
            this.updateMyAvatarOfSql(avatar)
            this.showPic(avatar,'showavatar')
              .then((resultxd)=>{
                this.myAvatarUrl=resultxd
              })

          })
      },

      async uploadMyAvatar(file){
        try {
          const token = localStorage.getItem('token')
          const formData = new FormData();
          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadavatar',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();

      },


        //修改服务器中的任务关系
      updateMyAvatarOfSql(avatar) {
        const token = localStorage.getItem('token')
        axios({
          method: 'put',
          url: 'https://api2.itaskid.com' + '/my/update/avatar',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {id:this.userinfo.id,avatar:avatar}
        })
          .then((response) => {
            console.log('修改用户头像成功!', response)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },
    },

    computed:{ 
    },


    created(){
      this.userAvatarUrl='../assets/小秘书头像3.svg'

      const username=localStorage.getItem('username')
      const key=`user|${username}`
      const userinfoStr=localStorage.getItem(key)
      this.userinfo=JSON.parse(userinfoStr)

      const nickname = this.userinfo.nickname;
      const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
      this.altNikname = matchResult ? matchResult[0] : '';
        this.showPic(this.userinfo.avatar,'showavatar')
          .then((result)=>{
            this.myAvatarUrl=result
          })
    },
    mounted(){
      var tags = this.$el.querySelectorAll('.box');

      tags.forEach(function(tag) {
        tag.addEventListener('click', function() {
          // 如果当前标签已经处于激活状态，则直接返回
          if (this.classList.contains('active')) {
            return;
          }
          // 移除其他标签的特定类名
          tags.forEach(function(otherTag) {
            otherTag.classList.remove('active');
          });
  
          // 为当前点击的标签添加特定类名
          this.classList.add('active');
        });
      });


     


    }
  }
  </script>
  
  <style lang="less" scoped>
    .el-header {
      background-color: rgba(35,44,73,0.5); 
      color: #333;
      text-align: center;
      line-height: 60px;
    }
    
    .home-container {
      background-color: rgba(35,44,73,0.5) !important; 
    }
    /* .el-container {
      height: calc(100% - 300px); 
    } */
    
    .el-aside {
      background-color: rgba(35,44,73,0.5);  
      /* background-color: rgba(145, 149, 164,0.7); */
      text-align: center;
      height: calc(100vh - 60px);
      width: 50px;
      color: rgb(255,255,255);
    }
    
    .el-main {
      /* position: relative; */
      /* background-color: #E9EEF3; */
      background-color: rgb(255,255,255);
      color: #333;
      text-align: center;
      line-height: 25px;
      border-top-left-radius: 12px;
    }
    
    body > .el-container {
      margin-bottom: 40px;
      background-color: rgba(35,44,73,0.5);
    }
    .el-button {
      width: 60px;
      height: 60px;
      margin:0.5px 2.5px;
      border: none !important;
    }
  
    .clicked {
      background-color: rgb(127, 127, 127) !important;
    }
    .el-button:hover {
      background-color: rgb(127, 127, 127) !important;
    }
  
    .el-button:focus {
      background-color: rgb(127, 127, 127) !important;
    }
  
    div .box {
      border: none;
      
    }
    .box:hover {
      background-color: rgba(38,48,70,0.5);
    }
    
    .box:active {
      color: #f00; /* 点击后的颜色 */
    }
  
    i {
      font-size: 18px;
    }
  
    p {
      font-size: 12px;
      font-weight: bold;
    }

  
  .no-contextmenu {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto; /* 只禁用右键事件 */
  }  
  
  a{
  text-decoration: none;
  }
  .router-link-active {
    text-decoration: none;
  }
  i {
    margin-top: 10px;
  }

  /* .el-select .el-input {
    width: 90px;
    height: 36px;
  } */

  .el-button {
    height: 36px;
  }

  .useravatar-container {
    position: relative;
    float:left;
    width:35px;
    height:35px;
    display: inline;
    margin: 8px 0px 0px -10px;
    // left:-8px;
    // top:8px;

    img {
      position: absolute;
      top:0px;
      left:0px;
      width: 35px;
      height:35px;
      margin-top:5px;
      border-radius: 50%;
    }

    .alt-avatar {
      position: absolute;
      top:0px;
      left:0px;
      width: 35px;
      height:35px;
      font-size: 13.5px;
      color:rgb(255, 255, 251);
      line-height: 35px;
      margin-top:5px;
      border-radius: 50%;
      background: rgb(144, 82, 245);
    }

    i {
      position: absolute;
      top: 36%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: none;
    }
  }

  .useravatar-container:hover i {
    display: block;
  }

  // .cameraicon-container {
  //   width:35px;
  //   height:35px;
  //   position: absolute;
  //   left:-8px;
  //   top:8px;
    
  // }

  </style>
  