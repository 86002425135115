//该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
Vue.use(Vuex)
//准备actions——用于相应组件中的动作
const actions = {




  //与用户有关的函数
  async getUserInfoBySearchText(context, text) {
    const token = localStorage.getItem('token')
    try {
      const data = { searchText: text };
      const response = await axios.get('https://api2.itaskid.com' + '/my/userinfobysearchtext', {
        headers: {
          Authorization: token,
          // 'Content-Type': 'application/json'
        },
        params: data
      });
      if (response.data.status == 0) {
        return response.data.data

      } else {
        alert('该用户不存在');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.')
    }
  },


  //通过用户的头像url，在前端生成一个显示img的url
  // async showPic(context, imgname) {
  //   const token = localStorage.getItem('token');
  //   const data = { url: imgname };
  //   try {
  //     const response = await axios({
  //       method: 'get',
  //       responseType: 'arraybuffer',
  //       url: 'https://api2.itaskid.com' + '/my/showavatar',
  //       headers: {
  //         Authorization: token,
  //       },
  //       params: data,
  //     });
  //     const contentType = response.headers['content-type'];
  //     const blob = new Blob([response.data], { type: contentType });
  //     const blobUrl = window.URL.createObjectURL(blob);
  //     return blobUrl;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },


  async showPic(context, imgname) {
    if (imgname == null || imgname == 'null' || imgname == '') {
      return undefined
    } else {
      const token = localStorage.getItem('token');
      const data = { url: imgname };
      try {
        const response = await axios({
          method: 'get',
          responseType: 'arraybuffer',
          url: 'https://api2.itaskid.com' + '/my/showavatar',
          headers: {
            Authorization: token,
          },
          params: data,
        });
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const blobUrl = window.URL.createObjectURL(blob);
        return blobUrl;
      } catch (error) {
        console.log(error);
      }
    }
  },



  //将国际标准时间转换成中国标准时间
  convertUTCtoCST(context, utcDate) {
    const cstDate = new Date(utcDate);
    cstDate.setHours(cstDate.getHours() + 8); // 中国标准时间比国际标准时间快8个小时
    return cstDate;
  },




  async getMyProjectAclList(context, username) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getmyprojectacllist', {
        headers: {
          Authorization: token
        },
        params: { username: username }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        window.location.href = "https://www.itaskid.com" + "/login";
        return
      }
    } catch (error) {
      console.log(error);
    }
  },

  updateProjectAcl(context, projectAcl) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/updateprojectacl',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'

      },
      data: projectAcl
    })
      .then(response => {
        // console.log(response.data)
        console.log('修改项目关系成功！')
      })
      .catch(error => {
        console.log(error);
      });
  },


  updateProjNameAndGroupByIdfp(context, idfp, projectName, projectGroup) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updateprojnameandgroupbyidfp',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: { idfp: idfp, name: projectName, groupname: projectGroup }
    })
      .then(response => {
        // console.log(response.data)
        console.log('修改项目成功！')
      })
      .catch(error => {
        console.log(error);
      });
  },

  //通过用户名获取用户的昵称
  async getNicknameByUsername(context, username) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getnicknamebyusername', {
        headers: {
          Authorization: token
        },
        params: { username: username }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据s！');
      }
    } catch (error) {
      console.log(error);
    }
  },

  //通过idfp获取所有参与者的项目关系
  async getProjectAclListByIdfp(context, idfp) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectacllistbyidfp', {
        headers: {
          Authorization: token
        },
        params: { project_idfp: idfp }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getUserInfoByUsername(context, username) {
    const token = localStorage.getItem('token')
    try {
      const data = { username: username };
      const response = await axios.get('https://api2.itaskid.com' + '/my/userinfobyusername', {
        headers: {
          Authorization: token,
          // 'Content-Type': 'application/json'
        },
        params: data
      });
      if (response.data.status == 0) {
        return response.data.data
      } else {
        alert('该用户不存在');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.')
    }
  },



  async getMyProjectListByIdfp(context, idfp) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectinfobyidfp', {
        headers: {
          Authorization: token
        },
        params: { idfp: idfp }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },


  async getTaskAclsByTaskIdft(context, idft) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskaclsbytaskidft', {
        headers: {
          Authorization: token
        },
        params: { task_idft: idft }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },



  //向服务器添加项目权限
  addProjectAclToSql(context, newProjectAcl) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/addprojectacl',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newProjectAcl
    })
      .then((response) => {

        console.log('添加项目关系成功')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },



  //修改服务器中的任务关系
  updateTaskAclOfSql(context, newTaskacl) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updatetaskaclbyidftacl',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newTaskacl
    })
      .then((response) => {
        console.log('修改任务关系成功!')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  //根据任务关系的username和task_idft修改服务器中的任务关系
  updateTaskAclByUsernameAndIdft(context, newTaskacl) {

    const token = localStorage.getItem('token')

    if (newTaskacl.permit_level == 'parter') {
      axios({
        method: 'delete',
        url: 'https://api2.itaskid.com' + '/my/deletetaskaclbyidftandusername',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTaskacl
      })
        .then((response) => {
          console.log('删除任务关系成功!')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    } else {
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/updatetaskaclbyusernameandidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTaskacl
      })
        .then((response) => {
          console.log('修改任务关系成功!')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    }

  },




  //根据idft删除任务关系
  deleteTaskAclbyIdft(context, idft) {
    const token = localStorage.getItem('token')
    axios({
      method: 'delete',
      url: 'https://api2.itaskid.com' + '/my/deletetaskaclbyidft',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: { task_idft: idft }
    })
      .then((response) => {
        console.log('删除任务关系成功！')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
        console.log('删除任务关系失败！')
      })
  },





  //更新任务信息
  updateTaskOfSql(context, task) {
    task.starttime = context.dispatch('convertDate', task.starttime)
    task.endtime = context.dispatch('convertDate', task.endtime)
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/tasklist',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: task
    })
      .then((response) => {
        console.log('修改任务成功')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  async getTaskSummitMsgs(context, task) {
    try {
      const token = localStorage.getItem('token');
      const data = { task_idft: task.idft };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/gettasksummitmsgsbyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        // alert('账号未认证，无法获取数据qia！');
        // window.location.href = "https://www.itaskid.com" + "/login";
        return []
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },


  async getTaskFilesByIdft(context, task_idft) {
    try {
      const token = localStorage.getItem('token');
      const data = { task_idft: task_idft };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        window.location.href = "https://www.itaskid.com" + "/login";
        return
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },




  formatTimestamp(context, timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = (now - date) / (1000 * 60 * 60 * 24); // 计算时间差，单位为天

    if (diff < 1) {
      // 如果是今天，则只显示时间
      return date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    } else if (diff < 2) {
      // 如果是昨天，则显示成‘昨天 08:25’
      return `昨天 ${date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`;
    } else if (diff < 7) {
      // 如果是本周，但又不是今天和昨天，则显示成‘周三 08:25’
      const weekdays = ['日', '一', '二', '三', '四', '五', '六'];
      return `周${weekdays[date.getDay()]} ${date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`;
    } else {
      // 如果不是本周，则显示成‘2023年5月20日 08:25’
      return date.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit' });
    }
  },

  //向服务器添加任务信息
  addTaskMessageToSql(context, newTaskMessage) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/addtaskmessage',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newTaskMessage
    })
      .then((response) => {

        console.log('添加任务信息成功！')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },


  //获取我的任务的处理函数 
  async getMyTaskAclList(context, username) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getmytaskacllist', {
        headers: {
          Authorization: token
        },
        params: { username: username }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },



  //根据idft获取任务信息 getTaskByIdft
  async getTaskinfoByIdft(context, idft) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskinfobyidft', {
        headers: {
          Authorization: token
        },
        params: { idft: idft }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },

  //根据idff获取文件信息
  async getFileinfoByIdff(context, idff) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getpubfilebyidff', {
        headers: {
          Authorization: token
        },
        params: { idff: idff }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error);
    }
  },


  //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
  getMyPubFiles(context, username, pubfiles) {
    var myPubFiles = []
    pubfiles.forEach((file) => {
      var checkers = []
      if (!(file.checker == '')) {
        checkers = file.checker.split(';')
      }
      if (!(file.approver == '')) {
        checkers.push(file.approver)
      }
      if (file.filestatus == '已发布' || checkers.includes(username) || file.publisher == username) {
        myPubFiles.push(file)
      }
    })
    return myPubFiles
  },


  //修改服务器中的文件信息
  updatePubfileByIdff(context, newPubfile) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updatepubfilesbyidff',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newPubfile
    })
      .then((response) => {
        console.log('修改文件成功!')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  //修改服务器中的文件信息  updatesharefilesummarybyidfsf
  updateSharefileByIdfsf(context, newSharefile) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updatesharefilesbyidfsf',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newSharefile
    })
      .then((response) => {
        console.log('修改文件成功!')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  //修改服务器中的文件信息  updatesharefilesummarybyidfsf
  updateSharefileSummaryByIdfsf(context, newSharefile) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updatesharefilesummarybyidfsf',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newSharefile
    })
      .then((response) => {
        console.log('修改文件成功!')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  async getPubfilesByProject(context, project) {
    try {
      const token = localStorage.getItem('token');
      const data = { project: project };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/pubfilesbyproject',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },


  async getSharefilesByProject(context, project) {
    try {
      const token = localStorage.getItem('token');
      const data = { project: project };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/sharefilesbyproject',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },




  async getPubfileFoldersByProject(context, project) {
    try {
      const token = localStorage.getItem('token');
      const data = { project: project };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/getpubfilefoldersbyproject',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  async getPubfilesOfNoFolderByProject(context, project) {
    try {
      const token = localStorage.getItem('token');
      const data = { project: project };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/pubfilesbyprojectofnofolder',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  async getSharefilesOfNoFolderByProject(context, project) {
    try {
      const token = localStorage.getItem('token');
      const data = { project: project };
      const response = await axios({
        method: 'get',
        url: 'https://api2.itaskid.com' + '/my/sharefilesbyprojectofnofolder',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        window.location.href = "https://www.itaskid.com" + "/login";
        return
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  //把标准时间转换成普通的字符串时间
  convertDate(context, targetDate) {
    const date = new Date(targetDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate

  },

  showTimePub(context, task) {
    const today = new Date()
    const thisYear = today.getFullYear()
    const taskDate = new Date(task.starttime)
    var taskYear = taskDate.getFullYear()
    var taskMonth = taskDate.getMonth() + 1
    var taskDay = taskDate.getDate()
    // const todayOfStr = this.convertDate(new Date())
    const todayOfStr = context.dispatch('convertDate', new Date())
    var taskDateOfStr = context.dispatch('convertDate', task.starttime)
    // var taskDateOfStr = this.convertDate(task.starttime)
    var lastDate
    if (todayOfStr == taskDateOfStr) {
      lastDate = '今天'
    } else if (taskYear == thisYear) {
      lastDate = `${taskMonth}月${taskDay}日`
    } else if (taskDateOfStr == '2999/12/31') {
      lastDate = '未定义'
    } else {
      lastDate = `${taskYear}-${taskMonth}-${taskDay}`
    }
    return lastDate
  },

  updateTaskOrderidOfSql(context, task) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/tasklist/updateorderid',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: task
    })
      .then((response) => {

        console.log('修改任务orderid成功!')
      })
      .catch(error => {
        console.log(error?.response?.data?.message || 'An error occurredxxx.')
      })
  },


  //以下是有关资源清单相关的函数

  //根据idff获取文件信息
  async getResListBySearchStr(context, searchText) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/getreslistbysearchstr', {
        headers: {
          Authorization: token
        },
        params: { reslist_name: searchText }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        window.location.href = "https://www.itaskid.com" + "/login";
        return
      }
    } catch (error) {
      console.log(error);
    }
  },

  //根据idfp获取项目所有任务
  async getTasksByIdfp(context, idfp) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbyidfp', {
        headers: {
          Authorization: token
        },
        params: { project_idfp: idfp }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },

  //根据idfp获取项目所有任务
  async getTasksByIdfp(context, idfp) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbyidfp', {
        headers: {
          Authorization: token
        },
        params: { project_idfp: idfp }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },


  async getTaskResorcePlanByIdft(context, idft) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskresorceplanbyidft', {
        headers: {
          Authorization: token
        },
        params: { resp_idft: idft }
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },

  //根据项目关系获取项目所有任务，并把项目关系的等级继承给获取的任务
  async getTasksByPojectAcl(context, projectAcl) {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbyidfp', {
        headers: {
          Authorization: token
        },
        params: { project_idfp: projectAcl.project_idfp }
      });
      if (Array.isArray(response.data)) {
        const tasksByProjectAcl = response.data
        tasksByProjectAcl.forEach((task) => {
          task.permit_level = projectAcl.permit_level
        })
        return tasksByProjectAcl;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },

  //根据项目关系获取项目所有任务，并把项目关系的等级继承给获取的任务
  async getTasksByTaskProjects(context, projectAcl) {

    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbyidfpfromtaskprojects', {
        headers: {
          Authorization: token
        },
        params: { project_idfp: projectAcl.project_idfp }
      });

      if (Array.isArray(response.data)) {
        const myTasks = response.data
        myTasks.forEach((task) => {
          task.permit_level = projectAcl.permit_level
        })
        return myTasks;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },


  async getTasksByIdfpFromTasklist(context, projectAcl) {

    const token = localStorage.getItem('token')
    try {
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettasksbyidfpfromtasklist', {
        headers: {
          Authorization: token
        },
        params: { project: projectAcl.project_idfp }
      });
      console.log('response0723', response)
      if (Array.isArray(response.data)) {
        const myTasks = response.data
        myTasks.forEach((task) => {
          task.permit_level = projectAcl.permit_level
        })
        return myTasks;
      } else {
        // alert('账号未认证L，无法获取数据！');
        return []
      }
    } catch (error) {
      console.log(error);
      return []
    }
  },

  getFormatFromMIME(context, mimeType) {
    const mimeToFormatMap = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
      'text/plain': 'txt',
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'image/svg+xml': 'svg',
      'application/autocad': 'dwg',
      // 其他 MIME 类型对应的文件格式简写
    };

    return mimeToFormatMap[mimeType] || 'unknown'; // 如果找不到对应的简写，返回'unknown'
  },

  //向服务器添加任务权限
  async addTaskAclToSql(context, newTasktAcl) {
    const token = localStorage.getItem('token')

    try {
      const response = await axios({
        method: 'post',
        url: 'https://api2.itaskid.com' + '/my/addtaskacl',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTasktAcl
      })

      console.log('添加任务关系成功')
      return response.data  // 返回添加成功后的任务关系数据
    } catch (error) {
      alert(error?.response?.data?.message || 'An error occurred.')
    }
  },



  //向服务器添加文件用户关系数据
  addFileUserAclSql(context, newFileUserAcl) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/addfileuseracl',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newFileUserAcl
    })
      .then((response) => {

        console.log('添加文件用户关系成功')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  //向服务器添加项目,并同时添加任务权限
  async addProjectToSql(context, newProject) {
    try {
      const token = localStorage.getItem('token')
      const response = await axios({
        method: 'post',
        url: 'https://api2.itaskid.com' + '/my/addproject',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newProject
      });

      return response.data;
    } catch (error) {
      alert(error?.response?.data?.message || 'An error occurred.');
    }
  },


  async getTaskContents(context, task) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api2.itaskid.com' + '/my/taskcontents', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: { task_idft: task.idft }
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },


  //以下是与任务聊天记录有关的函数
  async getMsgsByReceiver(context, receiver) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api2.itaskid.com' + '/my/getmsgsbyreceiver', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: { receiver: receiver }
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  //以下是与任务聊天记录有关的函数
  async getMyAssitantMsgs(context, receiver) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api2.itaskid.com' + '/my/getmyassitantmsgs', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: { receiver: receiver, sender: receiver }
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  downloadPubfileFromServer(context, url, downloadUrl) {
    const token = localStorage.getItem('token')
    const data = { url: url }
    axios({
      method: 'get',
      url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
      headers: {
        Authorization: token,
      },
      params: data,
      responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
        const link = document.createElement('a') // 创建一个 <a> 元素
        link.href = downloadUrl // 设置下载链接
        link.setAttribute('download', url) // 设置下载文件的文件名
        document.body.appendChild(link) // 将 <a> 元素添加到页面中
        link.click() // 模拟点击下载
        document.body.removeChild(link) // 下载完成后移除 <a> 元素
        window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      })
  },

  //以下是与任务聊天记录有关的函数
  async getTaskMsgsByReceiver(context, receiver) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api2.itaskid.com' + '/my/gettaskmsgsbyreceiver', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: { receiver: receiver }
      });

      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return []
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },

  //通过文件名判断是不是图片
  isImgFile(context, filename) {
    if (filename === '') {
      return false
    } else {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
      return imageExtensions.test(filename)
    }
  },


  // 添加某个聊天记录
  addChatmsg(context, newMsg) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/chatmsgs',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newMsg
    })
      .then((response) => {
        console.log('数据库新增的聊天记录！')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  mergeArrays(context, A, B) {
    if (!A) {
      A = [];
    }
    if (!B) {
      B = [];
    }

    const mapA = new Map();
    A.forEach(item => {
      mapA.set(item.idft, item);
    });

    const result = A.slice();

    B.forEach(item => {
      if (!mapA.has(item.idft)) {
        result.push(item);
      }
    });

    return result;
  },


  //向服务器添加任务的异步函数
  async addResorcesPlanToSql(context, newResPlan) {
    try {
      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')
      const idfresp = uuidv4()
      newResPlan.idfresp = (newResPlan.idfresp == undefined) ? idfresp : newResPlan.idfresp
      await axios({
        method: 'post',
        url: 'https://api2.itaskid.com' + '/my/addresplan',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newResPlan
      })
        .then((response) => {
          console.log('添加资源计划成功!', response)
        })
    } catch (error) {
      // console.log('error', error)
      alert(error?.response?.data?.message || 'An error occurred.')
    }
  },


  //根据文件idff获取相应的文件程序信息记录
  // async getResPlanlistByTask(context, task) {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const data = { resp_idft: task.idft };
  //     const response = await axios.get('https://api2.itaskid.com' + '/my/getresplanlistbytask', {
  //       headers: {
  //         Authorization: token,
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       },
  //       params: data
  //     });
  //     if (Array.isArray(response.data)) {
  //       return response.data;
  //     } else {
  //       alert('账号未认证，无法获取数据！');
  //     }
  //   } catch (error) {
  //     console.log(error?.response?.data?.message || 'An error occurred.');
  //   }
  // },


  async getResPlanlistByTask(context, task) {
    try {
      const token = localStorage.getItem('token');
      const data = { resp_idft: task.idft };
      const response = await axios.get('https://api2.itaskid.com/my/getresplanlistbytask', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });
      console.log('response.data', response.data)
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        return []; // 返回一个空数组表示数据为空
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
      return []; // 返回一个空数组表示发生错误
    }
  },

  updateSelectedTaskResPlan(context, resPlan) {
    const token = localStorage.getItem('token')
    axios({
      method: 'put',
      url: 'https://api2.itaskid.com' + '/my/updateresplan',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'

      },
      data: resPlan
    })
      .then(response => {
        console.log('修改任务资源计划成功！')
      })
      .catch(error => {
        console.log(error);
      });
  },

  //根据idft删除任务关系
  deleteTaskResPlanByIdfresp(context, idfresp) {
    const token = localStorage.getItem('token')
    axios({
      method: 'delete',
      url: 'https://api2.itaskid.com' + '/my/deletetaskresplanbyidfresp',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: { idfresp: idfresp }
    })
      .then((response) => {
        console.log('删除任务资源计划成功！')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
        console.log('删除任务资源计划失败！')
      })
  },



  //向服务器添加任务的异步函数
  async addTaskToSql(context, newTask) {
    try {
      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')
      const idft = uuidv4()
      newTask.idft = (newTask.idft == undefined) ? idft : newTask.idft
      await axios({
        method: 'post',
        url: 'https://api2.itaskid.com' + '/my/tasklist',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTask
      })
        .then((response) => {
          console.log('添加任务成功')
        })
    } catch (error) {
      alert(error?.response?.data?.message || 'An error occurred.')
    }
  },

  //向添加任务对应的内容详情
  addTaskContentToSql(context, newContent) {
    const token = localStorage.getItem('token')
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/taskcontents',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newContent
    })
      .then((response) => {

        console.log('添加任务内容成功')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },


  //下载聊天记录中文件的处理函数
  downloadTaskDetailFile(context, url) {
    const token = localStorage.getItem('token')
    const data = { url: url }
    axios({
      method: 'get',
      url: 'https://api2.itaskid.com' + '/my/downloadmsgfile',
      headers: {
        Authorization: token,
        // 'Access-Control-Allow-Origin': '*',
      },
      params: data,
      responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
        const link = document.createElement('a') // 创建一个 <a> 元素
        link.href = downloadUrl // 设置下载链接
        link.setAttribute('download', url) // 设置下载文件的文件名
        document.body.appendChild(link) // 将 <a> 元素添加到页面中
        link.click() // 模拟点击下载
        document.body.removeChild(link) // 下载完成后移除 <a> 元素
        window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      })
  },


  //与各种信息记录有关的任务
  // 添加某个任务
  addChatmsg(context, newMsg) {
    const token = localStorage.getItem('token')
    // const idft=uuidv4()
    // newTask.idft=(newTask.idft==undefined)?idft:newTask.idft
    // newTask.starttime=this.convertDate(newTask.starttime)
    // newTask.endtime=this.convertDate(newTask.endtime)
    axios({
      method: 'post',
      url: 'https://api2.itaskid.com' + '/my/chatmsgs',
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: newMsg
    })
      .then((response) => {

        // console.log('数据库新增的聊天记录')
      })
      .catch(error => {
        alert(error?.response?.data?.message || 'An error occurred.')
      })
  },

  //根据文件idff获取相应的文件程序信息记录
  async getChatmsgsByReceiverAndAboutwhat(context, receiver, aboutwhat) {
    try {
      const token = localStorage.getItem('token');
      const data = { receiver: receiver, aboutwhat: aboutwhat };
      const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: data
      });
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        alert('账号未认证，无法获取数据！');
        window.location.href = "https://www.itaskid.com" + "/login";
        return
      }
    } catch (error) {
      console.log(error?.response?.data?.message || 'An error occurred.');
    }
  },




}
//准备mutaions——用于操作数据（state）
const mutations = {
  //以下是TaskProject的方法
  ADDROOTNODE(state, value) {
    const newNode = {
      id: ++state.id,
      name: '新根节点',
      project: value,
      expanded: true,
      children: []
    }
    state.treeData.push(newNode)
  },
  //以下是根据登录的账号，更新state中用户名和id的方法
  updateUser(state, user) {
    state.user = user
  },
  switchMenuMask(state) {
    state.showMenuMask = !state.showMenuMask
  },

  //用户项目编辑的函数
  updateSelectProjName(state, value) {
    state.selectProjName = value.name
    state.selectProjGroup = value.groupname
    state.selectProjIdfp = value.idfp
    state.selectedProj = value
  },

  updateMySqlProjects(state, value) {
    state.mySqlProjects = state.value
  },
  updateMyPubFiles(state, value) {
    state.myPubFiles = value
  }


}
//准备state——用于存储数据
const state = {
  user: { username: '' },
  idfg: 4,

  showMenuMask: false,
  id: 8,

  //用户项目编辑的数据
  selectProjName: '',
  selectProjGroup: '',
  selectProjIdfp: '',
  selectedProj: '',
  mySqlProjects: [],
  myPubFiles: [],
}



//创建store，并对外暴露
export default new Vuex.Store({
  actions,
  mutations,
  state,
})