
<template>
  <div style="text-align: left;">
    <div class="pubfileheader-container" style="float: left; ">
      <div style="margin-top:10px;">
        <h4 style="display: inline-block;">&nbsp;&nbsp;发布文档</h4>
        <button style="float: right; height:30px; width: 58px;margin-right: 5px" @click="showPubFileMenu('all')">发起+</button>
        <button style="float: right; height:30px; width: 58px;margin-right: 5px; position:relative;" @click="showPubFileApproving" >
          待办
          <label for="" class="badget" :style="{backgroundColor: showCheckingNum() > 0 ? 'rgb(64, 158, 255)' : ''}">{{ showCheckingNum() }}</label>
        </button>
        <button style="float: right; height:30px; width: 58px;margin-right: 5px" @click="showPubFileApproved">已审批</button>
        <button style="float: right; height:30px; width: 58px;margin-right: 5px" @click="showPubFileOnly">已发布</button>
        <button style="float: right; height:30px; width: 50px;margin-right: 5px" @click="showAllPubFile">全部</button>
        <!-- 弹出菜单的代码 -->
        <PubFileMenu  v-if="isShowPubFileMenu" @closeFilePubMenu="closeFilePubMenu" @pubNewFile="pubNewFile" :myProjectlist="myProjectlist"
          :allMyPubFileFolders="allMyPubFileFolders" :selectedParentFolder="selectedParentFolder">
        </PubFileMenu>
        <!-- 弹出菜单的代码 -->
      </div>
      <div>
        <table style="margin-left: 10px; margin-right: 10px;">
          <tr class="tableheader">
            <!-- <td style="width:25px;"></td> -->
            <td style="width: 275px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;text-align:left;position: relative;">
              <i class="el-icon-search" style="position: absolute;left:8px;top:8px;"></i>
              <input type="text" placeholder="文件名称"  class="search-input" cospan="2" style="width:250px;" v-model="searchFilesText"
                @change="getSearchFiles()">
              <i class="el-icon-circle-close" style="position: absolute;left:230px;top:8px;" v-if="searchFilesText"
                @click="cancelSearchFiles()">
              </i>
            </td>
            <td style="min-width: 120px;">发起时间</td>
            <td style="width:50px;">状态</td>
            <td colspan="2">操作</td>
          </tr>
        </table>
      </div>
      <div style="height: calc(100vh - 200px;); overflow: auto;">
        <ApprFileMenu v-if="ifShowApprFileMenu" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closeFileAprovelMenu="closeFileAprovelMenu" @handleFileProcessMsg="handleFileProcessMsg" 
          @approveLocalPubfileByIdff="approveLocalPubfileByIdff">

        </ApprFileMenu>
        <ModifyFileMenu v-if="ifShowModifyFileMenu" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closeFileModifyMenu="closeFileModifyMenu" @handleFileProcessMsg="handleFileProcessMsg" 
          @modifyPubFile="modifyLocalPubfileByIdff">
        </ModifyFileMenu>
        <PubFileToUsers v-if="ifShowPubFileToUsers" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closePubFileToUsers="closePubFileToUsers">

        </PubFileToUsers>
        <div v-if="(viewOption=='approved')||(viewOption=='checking')||(viewOption=='published')">

        </div>
        <div  v-show="ifShowSearchFiles">
          <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
            <tr v-for="(file1,index) in searchFiles" :key="file1.idff" >
              <td style="width:25px;">
                 <i class="el-icon-folder-opened" v-if="file1.isfolder&&(file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                 <i class="el-icon-folder" v-if="file1.isfolder&&(!file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                 <i class="el-icon-document"  v-if="!file1.isfolder"></i>
              </td>
              <td @click="gotoFileDetail(file1,index)"  style="width: 250px;" class="filenametitle"
                :class="{ pubfileclicked: selectedPubfileIdff==file1.idff }">
                <div style="display:inline-block;width:250px;">
                  {{ file1.filename }}
                </div>
              </td>
              <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file1.pubtime) }}</td> 
              <td  class="filestatusstyle">
                <label for="" v-if="!file1.isfolder">{{ file1.filestatus }}</label>
              </td>
              <td style="width:20px;">
                <button style="width: 20px; background: none; border: none;padding: 0;" 
                  v-if="file1.filestatus=='已发布'&&!file1.isfolder">
                  <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                </button>
                <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file1.isfolder" @click="showPubFileMenu(file1.idff,file1)">
                  <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                  </i>
                </button>
                <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file1)"  
                  @click="approvelFile(file1)">
                  <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                </button>
              </td>
              <td>
                <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadPubfile(file1)" >
                  <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                </button>
              </td>
            </tr>          
          </table>
        </div>
        <div v-show="!ifShowSearchFiles">
          <div v-for="(file1,index) in myPubFiles1()" :key="file1.idff" >
            <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
              <tr>
                <td style="width:25px;">
                   <i class="el-icon-folder-opened" v-if="file1.isfolder&&(file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-folder" v-if="file1.isfolder&&(!file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-document"  v-if="!file1.isfolder"></i>
                </td>
                <td @click="gotoFileDetail(file1,index)"  style="width: 250px;" class="filenametitle"
                  :class="{ pubfileclicked: selectedPubfileIdff==file1.idff }">
                  <div style="display:inline-block;width:250px;">
                    {{ file1.filename }}
                  </div>
                </td>
                <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file1.pubtime) }}</td> 
                <td  class="filestatusstyle">
                  <label for="" v-if="!file1.isfolder">{{ file1.filestatus }}</label>
                </td>
                <td style="width:20px;">
                  <button style="width: 20px; background: none; border: none;padding: 0;" 
                    v-if="file1.filestatus=='已发布'&&!file1.isfolder">
                    <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                  </button>
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file1.isfolder" @click="showPubFileMenu(file1.idff,file1)">
                    <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                    </i>
                  </button>
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file1)"  
                    @click="approvelFile(file1)">
                    <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowDeleteButton(file1)"  
                    @click="deleteFile(file1)">
                    <i class="el-icon-circle-close" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
                <td>
                  <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                    @click.stop="downloadPubfile(file1)"  :class="{ 'disabled-button': file1.isfolder === 1 }">
                    <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
              </tr>          
            </table>
            <div v-for="(file2,index) in myPubFilesOfLevelN(2,file1)" :key="file2.idff"  v-show="file1.file_expanded">
              <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable" v-if="file1.file_expanded">
                <tr>
                  <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                  <td style="width:25px;position:relative;">
                    <label for="" style="position:absolute;left:6px;top:-5px;">
                      <i class="el-icon-folder-opened" v-if="file2.isfolder&&file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                    </label>
                  </td>
                  <td @click="gotoFileDetail(file2,index)"  style="width: 250px;" class="filenametitle"
                    :class="{ pubfileclicked: selectedPubfileIdff==file2.idff }">
                    <div style="padding-left:6px;">
                      {{ file2.filename }}
                    </div>
                  </td>
                  <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file2.pubtime) }}</td> 
                  <td  class="filestatusstyle">
                    <label for="" v-if="!file2.isfolder">{{ file2.filestatus }}</label>
                  </td>
                  <td style="width:20px;">
                    <button style="width: 20px; background: none; border: none;padding: 0;" 
                      v-if="file2.filestatus=='已发布'&&!file2.isfolder">
                      <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                    </button>
                    <button style="width: 20px; background: none; border: none;padding: 0;" 
                      v-if="file2.isfolder" @click="showPubFileMenu(file2.idff,file2)">
                      <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                      </i>
                    </button>
                    <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file2)"  
                      @click="approvelFile(file2)">
                      <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                    <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowDeleteButton(file2)"  
                      @click="deleteFile(file2)">
                      <i class="el-icon-circle-close" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                  <td>
                    <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                     @click.stop="downloadPubfile(file2)"  :class="{ 'disabled-button': file2.isfolder === 1 }">
                      <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                </tr>          
              </table>
              <div v-for="(file3,index) in myPubFilesOfLevelN(3,file2)" :key="file3.idff"  v-show="file2.file_expanded">
                <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                  <tr>
                    <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:12px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file3.isfolder&&file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-document"  v-if="!file3.isfolder"></i>
                      </label>
                    </td>
                    <td @click="gotoFileDetail(file3,index)"  style="width: 250px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPubfileIdff==file3.idff }">
                      <!-- <label for="" @click="changeFileExpanded(file1)">
                        <i class="el-icon-folder-opened" v-if="file2.isfolder&&file1.file_expanded"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                      </label> -->
                      <div style="padding-left:12px;">
                        {{ file3.filename }}
                      </div>
                    </td>
                    <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file3.pubtime) }}</td> 
                    <td  class="filestatusstyle">
                      <label for="" v-if="!file3.isfolder">{{ file3.filestatus }}</label>
                    </td>
                    <td style="width:20px;">
                      <button style="width: 20px; background: none; border: none;padding: 0;" 
                        v-if="file3.filestatus=='已发布'&&!file3.isfolder">
                        <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file3.isfolder"
                         @click="showPubFileMenu(file3.idff,file3)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file3)"  
                        @click="approvelFile(file3)">
                        <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowDeleteButton(file3)"  
                        @click="deleteFile(file3)">
                        <i class="el-icon-circle-close" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                       @click.stop="downloadPubfile(file3)"  :class="{ 'disabled-button': file3.isfolder === 1 }">
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                  </tr>          
                </table>
                <div v-for="(file4,index) in myPubFilesOfLevelN(4,file3)" :key="file4.idff"  v-show="file3.file_expanded">
                  <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                    <tr>
                    <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:18px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file4.isfolder&&file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-document"  v-if="!file4.isfolder"></i>
                      </label>  
                    </td>
                    <td @click="gotoFileDetail(file4,index)"  style="width: 250px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPubfileIdff==file4.idff }">
                    
                      <!-- <label for="" @click="changeFileExpanded(file1)">
                        <i class="el-icon-folder-opened" v-if="file2.isfolder&&file1.file_expanded"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                      </label> -->
                      <div style="padding-left:18px;">
                        {{ file4.filename }}
                      </div>
                    </td>
                    <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file4.pubtime) }}</td> 
                    <td  class="filestatusstyle">
                      <label for="" v-if="!file4.isfolder">{{ file4.filestatus }}</label>
                    </td>
                    <td style="width:20px;">
                      <button style="width: 20px; background: none; border: none;padding: 0;" 
                        v-if="file4.filestatus=='已发布'&&!file4.isfolder">
                        <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file4.isfolder"
                         @click="showPubFileMenu(file4.idff,file4)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file4)"  
                        @click="approvelFile(file4)">
                        <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowDeleteButton(file4)"  
                        @click="deleteFile(file4)">
                        <i class="el-icon-circle-close" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                        @click.stop="downloadPubfile(file4)"  :class="{ 'disabled-button': file4.isfolder === 1 }">
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    </tr>          
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
          <tr v-for="(file,index) in myPubFiles" :key="file.idff">
            <td style="width:50px;">{{ index+1 }}</td>
            <td  class="filestatusstyle">
              {{ file.filestatus }}
            </td>
            <td @click="gotoFileDetail(file,index)"  style="width: 300px;" class="filenametitle"
              :class="{ pubfileclicked: index==selectedPubfileIndex }">
              {{ file.filename }}
            </td>
            <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file.pubtime) }}</td> 
            <td style="width:20px;">
              <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file.filestatus=='已发布'">
                <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
              </button>
              <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file)"  
                @click="approvelFile(file)">
                <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
              </button>
            </td>
            <td>
              <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadPubfile(file)" >
                <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
              </button>
            </td>
          </tr>          
        </table> -->
      </div>
    </div>
    <div class="pubfiledetail-container" style="width: calc(100% - 528px);">
      <router-view :selectedFile="selectedFile" :fileProcessMsgs="fileProcessMsgs"  :fileChatMsgs="fileChatMsgs" @sendFilesToFile="sendFilesToFile"
        :selectedProjectAcls="selectedProjectAcls" @sendFileMessage="sendFileMessage" @sendFileMessageOfFile="sendFileMsgOfFile">
      </router-view>
    </div>
  </div>
</template>

<script>
import PubFileMenu from '../menu/file/PubFileMenu.vue'
import ApprFileMenu from '../menu/file/ApprFileMenu.vue'
import PubFileToUsers from '../menu/file/PubFileToUsers.vue'
import ModifyFileMenu from '../menu/file/ModifyFileMenu.vue'
import { mapState , mapMutations,mapActions} from 'vuex'
import  axios from 'axios'
import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'FileList',
  components: {
    PubFileMenu,
    ApprFileMenu,
    PubFileToUsers,
    ModifyFileMenu
  },
  props: ['myProjectlist1'],
  data() {
    return {
      altNikname:'',
      searchFiles:[],
      searchFilesText:'',
      userinfo:null,
      viewOption:'all',
      publisher: '',
      // fileDetails: '',
      pubfiles: [],
      myPubFiles:[],
      allMyPubFiles:[],
      allMyPubFileFolders:[],
      filename: '',
      filetype: '',
      filesize: '',
      filedownloadtimes:0,
      filesummary:'',
      filepublisher:'',
      filepubtime: '',
      fileProject: '',
      fileFolder: '',
      checkingNum:1,
      pubfile:{idff:'', project:'',fold:'',filename:'',publisher:'王洪文的',approver:'',pubtime:'', size: 0, downloadtimes:0,type:'',
               summary:'',downloadtimes:0, },
      isShowPubFileMenu: false,

      //与选中的文件相关的数据
      selectedFile:null,
      selectedParentFolder:null,
      selectedProjectIdfp:'',

      ifShowApprFileMenu:false,
      ifShowModifyFileMenu: false,
      ifShowPubFileToUsers:false,
      approvingFile:null,
      pubProjectParters:[],
      myProjectlist:[],

      //有关文件审批流程的信息记录
      fileProcessMsgs:[],
      selectedPubfileIndex:[-1,-1,-1,-1,-1,-1],
      selectedPubfileIdff:'',


      //有关文件聊天记录的数据
      fileChatMsgs:[],
      selectedProjectAcls:[],
      showIcon:true,

      //测试一组文件夹数组
      testArray:[
        {idff:1,name:'enen1',parent:'rootfile'},
        {idff:2,name:'enen2',parent:9},
        {idff:3,name:'enen3',parent:1},
        {idff:4,name:'enen4',parent:3},
        {idff:5,name:'enen5',parent:'rootfile'},
        {idff:6,name:'enen6',parent:4},
        {idff:7,name:'enen7',parent:1},
        {idff:8,name:'enen8',parent:6},
        {idff:9,name:'enen9',parent:'rootfile'},
      ],
      ifShowSearchFiles:false,

    }
  },
  computed:{
    ...mapState({
      mySqlProjects: 'mySqlProjects',
    }),

    ifshowDeleteButton(){
      return(file)=>{
        const username=localStorage.getItem('username')
        if(file.filestatus=='被驳回'&&file.publisher==username){
          return true
        } else {
          return false
        }
      }
    },

    ifshowApproveButton(){
      return(file)=>{
        const username=localStorage.getItem('username')

        if(file.filestatus=='待修改'){
          if(file.publisher==username){
            return true
          } else {
            return false
          }
        } else if (file.filestatus=='被驳回'){
          return false
        } else {

          const checkers=file.checker.split(';')
          const checkeder=file.checked.split(';')
  
          const approver=file.approver
          const approveder=file.approved
          
          if(checkers.includes(username)&&(!checkeder.includes(username))){
            return true
          } else if(approver==username){
            const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
            if(allCheckersInCheckeder&&file.approver!==file.approved){
              return true
            } else {
              return false
            }
          } else if(file.filestatus=='已审批'&&file.publisher==username){
            return true
          }
          else {
            return false
          }

        }
      }
    },


    myPubFiles1(){
      return()=>{
        var myPubFiles1=this.myPubFiles.filter(file=>file.file_level==1)
        myPubFiles1=myPubFiles1.sort((a, b)=>new Date(b.pubtime) - new Date(a.pubtime))

        return myPubFiles1
      }
    },

    myPubFilesOfLevelN(){
      return (n,file)=>{
        var filesData=this.myPubFiles.filter(item=>item.file_level==n&&item.file_parent==file.idff)
        filesData=filesData.sort((a, b)=>new Date(b.pubtime) - new Date(a.pubtime))
        return filesData
      }
    },







    datetimeConvert(){
      // return(datetime)=> {
      //   const date = new Date(datetime);
      //   const year = date.getFullYear();
      //   const month = String(date.getMonth() + 1).padStart(2,'0');
      //   const day = String(date.getDate()).padStart(2, '0');
      //   const hours = String(date.getHours() + 8).padStart(2, '0'); // 加上8小时，转换为中国标准时间
      //   const minutes = String(date.getMinutes()).padStart(2, '0');
      //   const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      //   return formattedTime
      // }

      return (datetime) => {
        // 创建Date对象，假设输入的是UTC时间
        const date = new Date(datetime);
        
        // 将UTC时间转换为中国标准时间（UTC+8）
        // 注意：getTime和setTime都是以毫秒为单位
        // date.setTime(date.getTime() + 8 * 60 * 60 * 1000); // 加上8小时的毫秒数
        
        // 接下来，使用转换后的时间进行格式化
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 已经转换为中国标准时间，不需要再加8
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        // 构建并返回格式化的字符串
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedTime;
      }
    },

    showCheckingNum(){
      return()=>{
        var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
        var files=this.allMyPubFiles.filter(file=>(file.filestatus!=='已审批')&&(file.isfolder==0)&&(file.filestatus!=='被驳回'))
        
        const username=localStorage.getItem('username')
        var myApprovingFiles=files.filter((file)=>{
          
          if(file.filestatus=='待修改'){
            
            if(file.publisher==username){
              return file
            } else {
              

            }
          } else {
            var checkers=file.checker.split(';')
              var checkeder=file.checked.split(';')
              var approver=file.approver
              var approved=file.approved
              if(checkers.includes(username)&&!checkeder.includes(username)){
                return file
              } else if(approver==username&&approved==''){
                const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
                if(allCheckersInCheckeder){
                  return file
                }
              } else {
              }
          }



        })

        const num=myApprovingFiles.length

        // var checkingNum=0
        // var checkinger=[]
        // var checkeder=[]
        
        // const username=localStorage.getItem('username')
        // const myPubFiles=this.allMyPubFiles.filter(file=>file.isfolder==0)
        // myPubFiles.forEach((file)=>{
        //   const approver=file.approver
        //   const approveder=file.approved
        //   if(!(file.checker=='')){
        //     checkinger=file.checker.split(';')
        //   }
        //   // if(!(file.approver=='')){
        //   //   checkinger.push(file.approver)
        //   // }
        //   if(!(file.checked=='')){
        //     checkeder=file.checked.split(';')
        //   }
        //   // if(!(file.approved=='')){
        //   //   checkeder.push(file.approved)
        //   // }
        //   if(checkinger.includes(username)&&!(checkeder.includes(username))) {
        //     checkingNum++
        //   } else if(approver==username){
        //     const allCheckersInCheckeder=checkinger.every(checker=>checkeder.includes(checker))
        //     if(allCheckersInCheckeder){
        //       checkingNum++
        //     }
        //   } else {
            
        //   }
        // })
        if(num>99){
          return '99+'
        } else if(num>0) {
          return num
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateMySqlProjects:'updateMySqlProjects',
    }),

    ...mapActions({
      downloadFileFromServer:'downloadFileFromServer',
      getFileinfoByIdff:'getFileinfoByIdff',
      addFileUserAclSql: 'addFileUserAclSql',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      showPic:'showPic',
      getPubfilesByProject:'getPubfilesByProject',
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      addChatmsg:'addChatmsg',
      updatePubfileByIdff:'updatePubfileByIdff',
      // getChatmsgsByReceiverAndAboutwhat:'getChatmsgsByReceiverAndAboutwhat',
    }),    

    cancelSearchFiles(){
      this.searchFilesText=''
      this.ifShowSearchFiles=false
    },
    getSearchFiles(){
      if(this.searchFilesText!==''){
        this.searchFiles=this.myPubFiles.filter(file=>file.filename.includes(this.searchFilesText)&&file.isfolder==0)
        this.ifShowSearchFiles=true
      }else {
        this.ifShowSearchFiles=false
      }
    },

    showCheckingNum1(){
  
        var checkingNum=0
        var checkinger=[]
        var checkeder=[]
        const username=localStorage.getItem('username')
        const myPubFiles=this.allMyPubFiles.filter(file=>file.isfolder==0)
        myPubFiles.forEach((file)=>{
          if(this.ifshowApproveButton(file)){
            checkingNum++
          }
        })
        myPubFiles.forEach((file)=>{
          if(!(file.checker=='')){
            checkinger=file.checker.split(';')
          }
          if(!(file.approver=='')){
            checkinger.push(file.approver)
          }
          if(!(file.checked=='')){
            checkeder=file.checked.split(';')
          }
          if(!(file.approved=='')){
            checkeder.push(file.approved)
          }
          if(checkinger.includes(username)&&!(checkeder.includes(username))) {
            checkingNum++
          }
        })
        if(checkingNum>99){
          return '99+'
        } else if(checkingNum>0) {
          return checkingNum
        } else {
          return ''
        }
    
    },


    approveLocalPubfileByIdff(newFileMsg,newPubFile){
      this.allMyPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.myPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.fileProcessMsgs.push(newFileMsg)
      this.ifShowApprFileMenu=false
    },

    modifyLocalPubfileByIdff(newFileMsg,newPubFile){
      this.allMyPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.myPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.fileProcessMsgs.push(newFileMsg)
      this.ifShowModifyFileMenu=false
    },


    changeFileExpanded(file){
      file.file_expanded=file.file_expanded?0:1
    },


    getFoldersOfFile(file,folderArray){
      const filefolders=folderArray.filter(item=>item.idff==file.file_parent)
      if(filefolders.length>0){

        if(!this.myPubFiles.includes(filefolders[0])){
          this.myPubFiles.push(filefolders[0])
        }
        
        this.getFoldersOfFile(filefolders[0],folderArray)
      }
    },

    startAnimation() {
      setInterval(() => {
        this.showIcon = !this.showIcon;
      }, 800); // 切换图标的显示状态，实现时隐时现的效果
    },

    downloadPubfile(file){
      console.log('file',file)
      const newFile=file
      newFile.downloadtimes=file.downloadtimes+1
      this.updatePubfileByIdff(newFile)
      const urlObject = new URL(file.url);
      const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      this.downloadPubfileFromServer(fileName,'downloadpubfile')
    },

    //把标准时间转换成普通的字符串时间
    convertDate(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
      
    },

    deleteFile(file){

      const confirmed = confirm('确认删除发布文件?');
        if (confirmed) {
          this.myPubFiles=this.myPubFiles.filter(item=>item.idff!=file.idff)
          this.deletePubFileOfSql(file);
        }  // No need for an else branch; if the user cancels, do nothing

    },

    deletePubFileOfSql(file){
      const token = localStorage.getItem('token')
      axios({
        method: 'delete',
        url: 'https://api2.itaskid.com' + '/my/deletepubfilebyidff',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: { idff: file.idff }
      })
        .then((response) => {
          this.deletePubFileOfSqlx(file.url)
          this.deleteFileMsgsByReceiver(file.idff)
          
          // console.log('删除我的文件成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          // console.log('删除我的文件失败！')
        })
    },

    //这个是删除文件的路径
    deletePubFileOfSqlx(url) {
        const token = localStorage.getItem('token');
        axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/deletepubfilebyurl',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: { url:url }
        })
        .then(response => {
          console.log('文件删除成功！');
        })
        .catch(error => {
          console.error('文件删除失败:', error);
        });
    },


    deleteFileMsgsByReceiver(receiver){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletefilemsgsbyreceiver',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {receiver:receiver}
        })
        .then((response)=>{
          console.log(response.data)
          console.log('删除文件信息成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除文件信息失败！')
        })
    }, 


    approvelFile(file){
      const username=localStorage.getItem('username')
      this.approvingFile=file
      if(file.filestatus=='待修改'){
        
        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })

        this.ifShowModifyFileMenu=true
        this.switchMenuMask()

      } else {
        if((file.filestatus==='待审批'||file.filestatus==='待审核')){

        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })

        this.ifShowApprFileMenu=true
        this.switchMenuMask()
      }else if(file.filestatus==='已审批'&&file.publisher==username) {

        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })
        this.ifShowPubFileToUsers=true
        this.switchMenuMask()
      } else {
        alert('文件由发起人发布！')
      }

      }


    },

    handleFileProcessMsg(fileMsg){
      this.fileProcessMsgs.push(fileMsg)
    },

    closePubFileToUsers(){
      this.ifShowPubFileToUsers=false
    },

    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile(item, targetArray) {
      
      var itemChildren = targetArray.filter(child => child.file_parent === item.idff);  //找到所有的子文件
      if (itemChildren.length > 0) {
        // 递归调用findChildrenArrayOfFile，传递剩余的部分数组
        itemChildren.forEach((child) => {
          this.findChildrenArrayOfFile(child, targetArray);
        });
        item.children = itemChildren;
      } else {
        item.children = [];
      }
    },



    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile1(targetArray) {
      var result = [];
      targetArray.forEach(item => {
        if (item.file_parent === 'rootfile') {
          this.findChildrenArrayOfFile(item, targetArray);
          result.push(item);
        }
      });
      return result;
      // if(targetArray.length>0){
      //   var n=targetArray.length
      //   for(var i=0; i<n;i++){
      //     this.findChildrenArrayOfFile[i]
      //   }

      //   const lefttargetArray=targetArray.filter(item=>item.parent=='rootfile')
      //   return lefttargetArray
      // }
    },

    gotoFileDetail(file,index){
      this.selectedPubfileIdff=file.idff
      this.fileProcessMsgs=[]
      this.fileChatMsgs=[]
      this.selectedPubfileIndex=[-1,-1,-1,-1,-1,-1]
      this.selectedPubfileIndex[file.file_level-1]=index
      this.getChatmsgsByReceiverAndAboutwhat(file.idff,'fileprocess')
        .then((result)=>{
          this.fileProcessMsgs=result
        })
      this.getChatmsgsByReceiverAndAboutwhat(file.idff,'filechat')
        .then((result)=>{
          this.fileChatMsgs=(result==undefined)?[]:result
        })
      
      this.getProjectPartersFromTask(file.project)
        .then((result)=>{
          this.selectedProjectAcls=result
          
        })
      this.selectedFile=file
      this.$router.push({
        name: 'filedetail',
        params: {
          idff:file.idff,
          name: file.filename,
          idfp:this.selectedProjectIdfp
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },

    async showPicOfMsg(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 

    async processFileMsgs(messages) {
      const processedMessages = await Promise.all(messages.map(async (msg) => {
        if (msg.type=='img') {
          const imgUrl = await this.showPicOfMsg(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
        return msg;
      }));
      return processedMessages;
    },
 

    async getProjectPartersFromTask(projectIdfp) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(projectIdfp);
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    //根据文件idff获取相应的文件程序信息记录
    async getChatmsgsByReceiverAndAboutwhat(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        // this.fileChatMsgs=result.data
        // return this.fileChatMsgs
        if (Array.isArray(response.data)) {
          // this.fileChatMsgs=result.data
          const processedData = await this.processFileMsgs(response.data);
          return processedData;
          // return response.data;
        } else {
          // alert('账号未认证，无法获取数据sdf！');
          consloe.log('无相应聊天数据！')
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    showPubFileMenu(fileIdff,folder){
      if(fileIdff=='all'){
        this.selectedParentFolder=null
      } else {
        
        this.selectedParentFolder=folder
      }
      this.isShowPubFileMenu=!this.isShowPubFileMenu
      this.switchMenuMask()
    },
    showPubFileOnly(){
      var publishedFiles=this.allMyPubFiles.filter(file=>file.isfolder==0&&file.filestatus=='已发布')
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)

      this.myPubFiles=publishedFiles
      
      publishedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },

    showAllPubFile(){
      this.myPubFiles=this.allMyPubFiles
    },
    showPubFileApproved(){
      var files=this.allMyPubFiles.filter(file=>file.isfolder==0)
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)


      const username=localStorage.getItem('username')
      var myApprovedFiles=files.filter((file)=>{
        var checkers=[]
        if(!(file.checked=='')){
          checkers=file.checked.split(';')
        }
        if(!(file.approved=='')){
          checkers.push(file.approved)
        }
        if(checkers.includes(username)){
          return file
        }
      })

      this.myPubFiles=myApprovedFiles

      myApprovedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })

    },



    
    // showCheckingNum(){
    //     var checkingNum=0
    //     var checkinger=[]
    //     var checkeder=[]
    //     const username=localStorage.getItem('username')
    //     this.allMyPubFiles.forEach((file)=>{
    //       if(!(file.checker=='')){
    //         checkinger=file.checker.split(';')
    //       }
    //       if(!(file.approver=='')){
    //         checkinger.push(file.approver)
    //       }
    //       if(!(file.checked=='')){
    //         checkeder=file.checked.split(';')
    //       }
    //       if(!(file.approved=='')){
    //         checkeder.push(file.approved)
    //       }
    //       if(checkinger.includes(username)&&!(checkeder.includes(username))) {
    //         checkingNum++
    //       }
    //     })
    //     this.checkingNum=checkingNum
    //     if(checkingNum>99){
    //       return '99+'
    //     } else if(checkingNum>0) {
    //       return checkingNum
    //     } else {
    //       return ''
    //   }
    // },
    showPubFileApproving(){
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
      var files=this.allMyPubFiles.filter(file=>(file.filestatus!=='已审批')&&file.isfolder==0)



      const username=localStorage.getItem('username')
      var myApprovingFiles=files.filter((file)=>{

        if(file.filestatus=='待修改'){
          if(file.publisher==username){
            return file
          }
        } else {

          var checkers=file.checker.split(';')
          var checkeder=file.checked.split(';')
          var approver=file.approver
          var approved=file.approved
          if(checkers.includes(username)&&!checkeder.includes(username)){
            return file
          } else if(approver==username&&approved==''){
            const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
              if(allCheckersInCheckeder){
                return file
              }
          } else {
  
          }

        }





      })

      this.myPubFiles=myApprovingFiles
      

      myApprovingFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },
    closeFilePubMenu(){  
      this.isShowPubFileMenu=!this.isShowPubFileMenu
    },
    closeFileAprovelMenu(){
      this.ifShowApprFileMenu=!this.ifShowApprFileMenu
    },

    closeFileModifyMenu(){
      this.ifShowModifyFileMenu=false
    },


    pubNewFile(newPubFile,newMsg){
      
      this.pubfiles.push(newPubFile)
      const username=localStorage.getItem('username')
      this.getMyPubFiles(username)
      this.addPubfile(newPubFile)
        .then((result)=>{
          const pubfile=result
          const checkers=pubfile.checker.split(';')
          const approver=pubfile.approver

          const newAssitantMsg={
            sender:'file|approve',
            message:'老板，有以下文件需要您审批。',
            timestamp:new Date(),
            file_name: pubfile.filename,
            type:'text',
            aboutwhat:'assistantchat',
            fileidff:pubfile.idff
          }

          if(pubfile.filestatus=='已发布'){

          } else if(checkers.length>0){
   
            checkers.forEach((checker)=>{
              newAssitantMsg.receiver=checker
              this.addChatmsg(newAssitantMsg)
            })
          } else if(checkers.length==0&&approver!=''){
            newAssitantMsg.receiver=approver
            this.addChatmsg(newAssitantMsg)
          }
        })
      const fileMsg={
        receiver:newPubFile.idff,
        sender: `publisher|${username}`,
        timestamp: new Date(),
        file_name: newPubFile.filename,
        message:newMsg,
        type:'text',
        img_name:'',
        file_size:0,
        aboutwhat:'fileprocess'
      }


      this.addChatmsg(fileMsg)


      
    },


    async addPubfile(newPubfile) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/pubfiles',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newPubfile
        });
    
        console.log('新增发布文件成功！');
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    getPubfilesByUser(username){
      const token = localStorage.getItem('token')
      const data={publisher:username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/pubfilesbyuser',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.pubfiles=response.data
          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

      //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
      getMyPubFiles(username){
        var myPubFiles=[]
        this.pubfiles.forEach((file)=>{
          var checkers=[]
          if(!(file.checker=='')){
            checkers=file.checker.split(';')
          }
          if(!(file.approver=='')){
            checkers.push(file.approver)
          }
          if(file.filestatus=='已发布'||checkers.includes(username)||file.publisher==username){
            myPubFiles.push(file)
          }
        })
        this.myPubFiles=myPubFiles
        return myPubFiles
      },



    downloadPubfileFromServer(url,downloadUrl) {
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          link.setAttribute('download', url) // 设置下载文件的文件名
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    async uploadFiles(files) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const encodedFileName = encodeURIComponent(files[i].name);
        formData.append('files', files[i], encodedFileName);
      }
    
      try {
        const response = await axios.post('https://api2.itaskid.com' + '/my/uploadmsgfiles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        // console.log(response.data);
        return response.data;
        // 处理上传成功的逻辑
      } catch (error) {
        console.error(error);
        throw error;
        // 处理上传失败的逻辑
      }
    },

    //发送文件/图片相关的函数
    isImgFile(filename) {
      if(filename==='') {
        return false
      } else {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
        return imageExtensions.test(filename)
      }
    },


    async sendFilesToFile(dropFiles) {
      try {
        const files = await this.uploadFiles(dropFiles);
        const username=localStorage.getItem('username')
        const promises = files.map(async (file) => {
          const isImg=this.isImgFile(file.filename)
          let fileName=file.filename
          let firstDashIndex =fileName.indexOf("-")
          let leftFilename = fileName.substring(firstDashIndex + 1);

          let newMsg={
            sender: username,
            savebysender: 1,
            savebyreceiver: 1,            
            // message: `文件：${leftFilename}`,
            // file_name: file.filename,
            type: '',
            aboutwhat:'filechat',
            receiver:this.selectedFile.idff,
            // img_name: '',
            file_size: file.size,
            imgUrl: '', // 添加 imgUrl 属性
            timestamp: new Date()            
          }
          if(isImg) {
            newMsg.type='img'
            newMsg.message=`图片：${leftFilename}`
            newMsg.img_name=file.filename
            newMsg.file_name=file.filename
            // this.$emit('sendFileMessageOfFile',newMsg)
            this.sendFileMsgOfFile(newMsg)


          }else {
            newMsg.type='file'     
            newMsg.message=`文件：${leftFilename}`
            newMsg.file_name=file.filename
            newMsg.img_name=''
            // this.$emit('sendFileMessageOfFile',newMsg)
            this.sendFileMsgOfFile(newMsg)
          }
        })
        await Promise.all(promises)
      } catch (error) {
        console.error(error);
        // 处理上传失败的逻辑
      }
    },

    async sendFileMsgOfFile(newMsg){
      newMsg.receiver=this.selectedFile.idff
      newMsg.nickname=this.altNikname
      // newMsg.receiver_nickname=receiver_nickname
      if(newMsg.type=='img'){
        const imgUrl=await this.showPicOfMsg(newMsg.file_name, 'showmsgimg')
        newMsg.imgUrl=imgUrl
      }
      this.addChatmsg(newMsg)
        .then((result)=>{
          // newMsg.id=result.id
          this.socket.emit('message', newMsg, (response) => {
            console.log('Server response:', response)
          })
        })
    },

    //关于文件聊天记录的函数
    sendFileMessage(fileMsg){
      const username=localStorage.getItem('username')
      const now=new Date()
      // 发送消息给socket.io服务器
      const newFileMessage={
        message: fileMsg,
        receiver: this.selectedFile.idff,
        fileidff:this.selectedFile.idff,
        sender: username,
        nickname:this.userinfo.nickname,
        file_name:this.selectedFile.filename,
        img_name:'',
        type: 'text',
        timestamp: now,
        file_size: 0,
        aboutwhat:'filechat'
      }
      
      this.addChatmsg(newFileMessage)
      this.socket.emit('message',newFileMessage,(response)=>{
        console.log('Server response:',response)
      })
    },
    async sendFileMsgOfFile(newMsg){
      newMsg.receiver=this.selectedPubfileIdff
      newMsg.nickname=this.altNikname
      // newMsg.receiver_nickname=receiver_nickname
      if(newMsg.type=='img'){
        const imgUrl=await this.showPicOfMsg(newMsg.file_name, 'showmsgimg')
        newMsg.imgUrl=imgUrl
      }
      console.log('newMsg.type',newMsg)
      this.addChatmsg(newMsg)
        .then((result)=>{
          // newMsg.id=result.id
          console.log('result',result)
          this.socket.emit('message', newMsg, (response) => {
            console.log('Server response:', response)
          })
        })
    },
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        const idfp = params.idfp;
        this.selectedProjectIdfp=idfp
        const idff = params.idff

        const username=localStorage.getItem('username')
        if(idfp=='all'){

          var pubfiles = [];
          var promises = this.myProjectlist1.map(async (project) => {
            const result=await this.getPubfilesByProject(project.idfp)
            if(result&&result.length>0){
              pubfiles=pubfiles.concat(result)
            }
          });
          
          Promise.all(promises)
            .then(() => {
              this.pubfiles = pubfiles;
              this.myPubFiles=this.getMyPubFiles(username)
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
          
        }else {
          this.getPubfilesByProject(idfp)
            .then((result)=>{
              this.pubfiles=result
              this.getMyPubFiles(username)

            })
        }
      }
    },

    searchFilesText: function(newVal,oldVal){
      if(newVal.replace(/\s/g, '') === ''){
        this.cancelSearchFiles()
      }
    },
  },

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },

  created(){
    const username=localStorage.getItem('username')
    const key=`user|${username}`
    const userinfoStr=localStorage.getItem(key)
    this.userinfo=JSON.parse(userinfoStr)

    const nickname = this.userinfo.nickname;
    const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    this.altNikname = matchResult ? matchResult[0] : '';


  },

  mounted(){
    const username=localStorage.getItem('username')
    const usernogroup=`${username}nogroup`
    let projectAcllist=[]
    this.getMyProjectAclList(username)
      .then((result)=>{
        projectAcllist=result
        if(projectAcllist&&projectAcllist.length>0) {
          const promises=projectAcllist.map(async (projectacl)=>{
            const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
            projectacl.name=project[0].name
            projectacl.groupname=project[0].groupname
            projectacl.status=project[0].status
            projectacl.is_delete=project[0].is_delete
            projectacl.createdTime=project[0].createdTime
            projectacl.creater=project[0].creater
            projectacl.permit_type=project[0].permit_type
            projectacl.idfp=project[0].idfp
          })
          return Promise.all(promises)
        }
      })
        .then(()=>{
          this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
          var pubfiles = [];
          var promises = this.myProjectlist.map(async (project) => {
            const result=await this.getPubfilesByProject(project.idfp)
            if(result.length>0){
              pubfiles=pubfiles.concat(result)
            }
          });
          Promise.all(promises)
            .then(() => {
              this.pubfiles = pubfiles;
              this.allMyPubFiles=this.getMyPubFiles(username)
              this.showCheckingNum1()
              const MyPubFileFolders=this.allMyPubFiles.filter(file=>file.isfolder==1)

              this.allMyPubFileFolders=MyPubFileFolders
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
        })

    
    // 创建socket.io客户端实例并连接服务器
    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
    });
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      this.fileChatMsgs.push(message)
    });
    
  },
}
</script>

<style lang="less" scoped>
  h4 {
    height: 40px;
    margin: 0;
  }
  .tableheader {
    height: 30px;
    td {
      input {
        width: 95%;
      }
    }
    
  }

  .pubfileheader-container {
    display: inline-block;
    text-align: left;
    height: calc(95ch - 250px);
    width: 525px;
    border-right: 1px solid gray;
  }

  .pubfiledetail-container {
    display: inline-block;
    text-align: left;
  }

  .filenametitle {
    word-wrap: break-word; 
  }

  .filecontenttable {
    border-collapse: collapse;
    td {
      border-bottom: 0.5px solid rgb(205, 205, 205);
    }
  }

  .item {
    padding:0;
    float:right;
    height:30px;
    margin-right:20px;
    border:1px solid gray;
    
    .el-button{
      padding:0;
      height:30px;
      background-color: rgb(239, 239, 239);
    }

    .el-button:hover {
      background-color: rgb(229, 229, 229);
    }
  }

  .badget {
    position:absolute;
    font-size: 12px;
    padding-top:1px;
    width:22px;
    height:14px;
    border-radius: 6px;
    left:40px;
    top:-8px;
    // background-color: rgb(245, 108, 108);
  }

  .filestatusstyle {
    width:50px;
  }

  .waitcheck:hover {
    color:rgb(82, 167, 254);
  }

  // .waitcheck:hover {
  //   color:red;
  // }

  .pubfileclicked{
    color: rgb(71, 114, 250);
  }

  @keyframes blinkAnimation {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
  }
  
  
  .blink {
    animation: blinkAnimation 1.5s infinite;
  }

  .search-input {
    border:none;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    height: 30px; 
    width: 200px; 
    padding:0px;
    text-indent: 25px;
  }

.search-input:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
  

}

.search-input::placeholder {
  font-size: 16px; /* 或者你希望的任何尺寸 */
  // color: #999; /* 你也可以在这里自定义颜色 */
}

.disabled-button {
  opacity: 0.5; /* 设置按钮半透明 */
  cursor: not-allowed; /* 禁用鼠标指针 */
  color: gray;
}


</style>