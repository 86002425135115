<template>
  <div class="menu-container" v-draggable>
    <div class="menu">
      <h3 style="text-align: center; margin-bottom: 20px;">共享项目"{{ projectName }}"</h3>

      <div class="shareProjectEdit-container">
        <div class="shareProject-left" style="position:relative;">
          <div>
            <input type="text" v-model="searchText" placeholder="用户名/手机号/邮箱地址" style="height: 30px; width: 230px;
              text-indent: 25px;" @change="gotoFriend">
            <i class="el-icon-search"
              style="margin-left: -230px; margin-top: 7px;position: absolute;">
            </i>
            <i class="el-icon-circle-close" v-show="searchText" @click="clearInput" 
              style="margin-left: -25px; margin-top: 8px; position: absolute;">
            </i>
            <i class="el-icon-user-solid" v-show="searchText==''" @click="clearInput" 
              style="margin-left: -25px; margin-top: 8px; position: absolute; ">
            </i>
          </div>
          <div v-if="showSearchUserInfo" class="searchUser-container" style="position: absolute; background: rgba(250,250,250,1);">
            <img :src="searchUser.avatarUrl" alt="Avatar" :style="{ width: '35px', height: '35px', borderRadius: '50%' }"
             v-if="searchUser.avatarUrl!==undefined"> 
            <div v-if="searchUser.avatarUrl==undefined" class="friendmsg-altavatar">
             {{ showAltNickname(searchUser.nickname) }}
            </div>
            <label>{{ searchUser.username }}</label>
            <button @click="selectShareUser(searchUser)">
              <i class="el-icon-bottom"></i>
            </button>
          </div>
          <div class="selectedShareUserslist" style="height: 350px;width:250px;">
            <div style="margin-top:5px;">
              <label class="editprojectacl">
                已选择({{ searchUsers.length }})
              </label>
            </div>
            <div v-for="(searchUser,index) in searchUsers" :key="index">
              <div style="display:flex; margin-bottom: 5px;">
                <input type="checkbox" v-model="searchUser.isChecked" @change="handleIsChecked(searchUser)">
                <!-- <img :src="searchUser.avatarUrl" alt="Avatar" :style="{ width: '35px', height: '35px', borderRadius: '50%' }">  -->
                <img :src="searchUser.avatarUrl" alt="Avatar" :style="{ width: '35px', height: '35px', borderRadius: '50%' }"
                  v-if="searchUser.avatarUrl!==undefined"> 
                <div v-if="searchUser.avatarUrl==undefined" class="friendmsg-altavatar">
                 {{ showAltNickname(searchUser.nickname) }}
                </div>
                <label>{{ searchUser.username }}</label>
                <select id="project-type" v-model="searchUser.permit_level">
                  <option value="parter">参与者</option>
                  <option value="manager">管理员</option>
                  <option value="viewer">可查看</option>
                  <option value="charger">负责人</option>
                </select>
              </div>
            </div>
          </div>



          <div class="menu-buttons">
            <label class="editprojectacl" style="margin-left: 0px;width: 60px; text-align:left;">
              <input type="checkbox"  class="editprojectacl" @change="handleSearchersSelected" v-model="allSearchersSelected">全选
            </label>
            <button type="button" @click="shareProject" :disabled="saveOrNot" style="margin-left: 80px; width: 80px;">邀请</button>
          </div>
        </div>
        <div class="shareProject-right">



          <div class="selectedShareUserslist" style="height: 385px;width:250px;">
            <label  class="editprojectacl">
              已有成员({{ selectedUsers.length }})
            </label>
            <label class="editprojectacl">
              全选<input type="checkbox" name="gender" v-model="allPartersSelected" @change="handleSelectedAllParter">
            </label>
            <div v-for="(selectedUser,index) in selectedUsers" :key="index">
              <div style="display:flex; margin-bottom: 5px;">
                <label style="width:30px;">
                  <input type="checkbox"  v-model="selectedUser.isChecked" @change="handleSelectedParter(selectedUser)">
                </label>
                <!-- <img :src="selectedUser.avatarUrl" alt="Avatar" :style="{ width: '35px', height: '35px', borderRadius: '50%' }"> -->
                <img :src="selectedUser.avatarUrl" alt="Avatar" :style="{ width: '35px', height: '35px', borderRadius: '50%' }"
                  v-if="selectedUser.avatarUrl!==undefined"> 
                <div v-if="selectedUser.avatarUrl==undefined" class="friendmsg-altavatar">
                 {{ showAltNickname(selectedUser.nickname) }}
                </div> 
                <label>{{ selectedUser.nickname }}</label>
                <select  v-model="selectedUser.permit_level" style="margin-left:3px;"  @change="handlePermitLevel(selectedUser)">
                  <option value="parter">参与者</option>
                  <option value="manager">管理员</option>
                  <option value="viewer">可查看</option>
                  <option value="charger">负责人</option>
                </select>
              </div>
            </div>
          </div>
          <button type="button" @click="deleteProjectAcl" style="margin-left: 10px; width: 80px;">移出项目</button>
          <button type="button" @click="resetForm" style="margin-left: 75px; width: 80px;">关闭</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations} from 'vuex'
import axios from 'axios'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "ShareProjectMenu",
    props: ['myProjects','selectedProJidfpByContext','selectedUsers'],
    data() {
        return {
            projectName: "",
            projectType: "type1",
            projectFolder: '',
            username:'',
            saveOrNot:true,
            //以下是编辑项目的数据
            selectProjGroup:'',
            searchText:'',
            searchUser:null,
            showSearchUserInfo:false,
            searchUsers:[],
            projectAcl:'parter',
            allPartersSelected:false,
            allSearchersSelected:false,
            selectedParter:[],
        };
    },
    // props:['selectProjName'],
    computed: {
      showAltNickname(){
        return(nickname)=>{
          const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
          const altNikname = matchResult ? matchResult[0] : ''
          return altNikname
        }
      },
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjects.length;i++) {
          if(!myfolderobj[this.myProjects[i].groupname]) {
            myfolderobj[this.myProjects[i].groupname]=i+1
            const folder={id: Id, name: this.myProjects[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },

      // saveOrNot: function(){
      //   if(this.projectName) {
      //     return false
      //   }else
      //   return true
      // }
      saveOk: function(){
        if(this.projectName&&this.projectFolder) {
          return true
        }else
        return false
      }

      
 
    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },

      'searchUsers': {
        handler: function(newUsers, oldUsers) {
          newUsers.forEach((newUser, index) => {
            if (newUser.permit_level !== oldUsers[index].permit_level) {
              console.log('对象索引:', index, '的 permit_level 发生变化');
              // 执行其他操作
            }
          });
        },
        deep: true  // 深度监测对象的变化
      },
    },
    prop: ["menuVisible"],
    methods: {
      ...mapMutations({
          switchMenuMask: "switchMenuMask"
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },
      shareProject() {
        const newProjectAcls=this.searchUsers
        this.$emit('shareProjectToUser',newProjectAcls)
      },
      deleteProjectAcl() {
          const selectedUsers=this.selectedUsers
          this.selectedUsers.forEach((item)=>{
            if(item.isChecked){
              const idfpacl=item.idfpacl
              this.$emit('deleteSelectedProjectAclById',idfpacl)
            }
          })
      },
      resetForm(){
        this.$emit('closeShareProjectMenu')
      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      clearInput(){
        this.searchText=''
        this.showSearchUserInfo=false
      },

      selectShareUser(searchUser){
        const newProjectAcl=searchUser
        newProjectAcl.project_idfp=this.selectedProJidfpByContext
        newProjectAcl.permit_level='parter'
        newProjectAcl.isChecked=true
        this.searchUsers.push(newProjectAcl)
        this.showSearchUserInfo=false
        this.searchText=''
        // this.searchUsers.push(searchUser)
        // this.searchText=''
        // this.showSearchUserInfo=false

      },

      handleSelectedAllParter(){
        if(this.allPartersSelected) {
          this.selectedUsers.forEach((item)=>{
            item.isChecked=true
          }) 
            
          } else {
            this.selectedUsers.forEach((item)=>{
            item.isChecked=false
          }) 
          }
        },

        handleSearchersSelected(){
          
        if(this.allSearchersSelected) {
          this.searchUsers.forEach((item)=>{
            item.isChecked=true
          }) 
          console.log(this.searchUsers)
          } else {
            this.searchUsers.forEach((item)=>{
              item.isChecked=false
            })
          }


          // else {
          //   this.selectedUsers.forEach((item)=>{
          //   item.isChecked=false
          // }) 
          // }
      },

      handleIsChecked(searchUser){
        console.log('enha',searchUser)
      },

      handlePermitLevel(selectedUser){
        this.$emit('upadatePrjAclPermitLevel',selectedUser)
      },

      handleSelectedParter(selectedUser){
        console.log('selectedUser',this.selectedUsers)
      },
      

      gotoFriend(event) {
        let num=0
        this.selectedUsers.forEach((item)=>{
          if(item.username==this.searchText) {
            num+=1
          } else {
            return
          }
        })
        if(num==0) {
          let searchUser;
          this.getUserInfoBySearchText(this.searchText)
            .then(result => {
              searchUser = result;
              return this.showPic(searchUser.avatar);
            })
            .then(avatarUrl => {
              searchUser.avatarUrl = avatarUrl;
              this.searchUser = searchUser;
              this.showSearchUserInfo=true
            })
            .catch(error => {
              console.log(error?.response?.data?.message || 'An error occurred.');
            });
        } else {
          alert('已是项目成员！')
        }

  
        
        // this.switchMenuMask()
        // this.event={...event}  
        // const element=this.$refs.searchtext
        // const { top, right } = this.getTopRightPosition(event, element);
        // this.parentNode ={clientY:top,clientX:right+20}
        // eventBus.$on('closeContexMenu', () => {
        //   this.showFriendInfoMenu=false
        // })
      },

      async getUserInfoBySearchText(text) {
        const token=localStorage.getItem('token')
        try {
          const data = {searchText:text};
          const response = await axios.get('https://api2.itaskid.com' + '/my//userinfobysearchtext', {
            headers: {
              Authorization: token,
              // 'Content-Type': 'application/json'
            },
            params: data
          });
          if(response.data.status==0){
            return response.data.data
          }else {
            alert('该用户不存在');
          }
        } catch (error) {
          console.log(error?.response?.data?.message || 'An error occurred.')
        }
      },

      async showPic(imgname) {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api2.itaskid.com' + '/my/showavatar',
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      },

    },
    mounted(){
      console.log('s',this.searchUsers)


      //编辑项目菜单生成后，根据选择的项目自动填写该项目的name和groupname属性

    


      this.projectName=this.$store.state.selectProjName
      this.selectProjGroup=this.$store.state.selectProjGroup
      

      this.getUsername();
      const token = localStorage.getItem('token')
      // axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
      //   headers: {
      //     Authorization: token
      //   },
      // })
      //   .then((response)=>{
      //     if (Array.isArray(response.data)){
      //       // this.myProjectlist=response.data.filter(item => item.charger==this.username&&item.idfp>3)
      //       //得到数据后再获取用户名
      //       this.projectFolder = this.folders.find(folder => folder.name === this.selectProjGroup)
      //     }else
      //     alert('账号未认证，无法获取数据！')
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })

    },

    created(){
      this.projectFolder=this.folders.find(folder=>folder.name==this.$store.state.selectProjGroup)
    },

    components: { Row }
}
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 550px;
  height: 500px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  // input #project-name {
  //   width: 410px;
  //   height: 30px;
  //   padding: 0;
  //   margin: 0px 20px 0 20px;
  // }
  // input {
  //   width: 405px;
  //   height: 30px;
  //   padding: 0;
  // }
  .form-group {
  width: 450px;
  margin: 10px 0;
  height: 30px;
  padding-left: 20px;
  }
  .menu-buttons {
    margin-right: 20px;
    text-align: left;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.shareProjectEdit-container {
  display: flex;
  height: 420px;
  margin: 0px 20px;
  
  .shareProject-left {
    flex:1;
    border-right: 0.5px rgb(235, 235, 235) solid;
    
    

    i:hover {
      cursor: default;
    }

    div.searchUser-container {
      display: flex;
      border: gray solid 1px;
      margin: 5px 0 0 0;
      width: 230px;
      height: 45px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 5px;

      img {
        margin:5px 0 5px 5px;
      }
      label {
        // margin: 8px 0 0 6px;
        width:130px;
        height: 45px;
        line-height: 45px;
        margin-left: 5px;
      }

      button {
        border:none;
        background: rgba(250,250,250,1);
        height: 45px;
        width: 50px;
        line-height: 45px;
        float:right;
        margin-right:10px;
        padding:0;
      }
      button:hover {
        color: rgb(71, 114, 250);
      }
      // border:none;

    }
  }
  .shareProject-right {
    flex:1;


  }

  .selectedShareUserslist {
    color:rgb(143, 145, 147);
    overflow: auto;
    
    img {
      margin:5px 0 5px 5px;
    }
    label {
      // margin: 8px 0 0 6px;
      height: 45px;
      width: 95px;
      line-height: 45px;
      margin-left: 5px;
    }
    select {
      width: 75px;
      height: 25px;
      line-height: 25px;
      margin-top: 10px;
      border:none;
      background: none;
      // color:rgb(143, 145, 147);
      color: rgb(71, 114, 250);
    }
    select:hover {
      background: rgb(239, 241, 246);
      border-radius: 10px;
      border:none;
      
    }
    option {
      background: rgb(239, 241, 246);
      border-radius: 10px;
      border:none;
      color:rgb(143, 145, 147);
    }
    option:hover {
      background: rgb(239, 241, 246);
      border-radius: 10px;
      border:none;
    }
  }

}

.editprojectacl {
  font-size: 14px;
}
label.editprojectacl {
  margin-left:10px;
}
button.editprojectacl {
  width: 80px;
  margin: 7px 0 0 180px;
  background: rgb(56, 205, 127);
  border-radius: 5px;
  border:none;
  color:rgb(255,255,255)

}

input.editprojectacl {
  font-size: 14px; 
  border-radius: 5px;
}

.friendmsg-altavatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(144, 82, 245);
  color:rgb(255, 255, 251);
  font-size: 12px;
  line-height: 35px;
  text-align: center;
  display: inline;
  margin: 5px 0px 0px 5px;
}
  
</style>