<template>
  <div class="page flex-center">
    <h3 class="title">用户管理</h3>
    <div>xx</div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState , mapMutations, mapActions} from 'vuex'
export default {
  name: 'UsersManagement',
  data() {
    return {
     
      };
    },
    methods: {
      ...mapActions({
        addProjectAclToSql:'addProjectAclToSql',
        addProjectToSql:'addProjectToSql'
      }),


      
    },

  watch: {

  },
}
</script>

<style lang="less" scoped>
  .flex-center {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
  }
  .toggle-password {
    font-size: 16px;
    cursor: pointer;
    color: #c0c4cc;
  }
  .toggle-password.is-active {
    color: #409eff;
}
</style>